import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {comparePerformanceAssets} from '../homeComponents/projections/components/ProjectionsConfig'
import {ComparePerformanceObjModel} from '../homeComponents/comparePerformance/core/_model'
import {usePastPerformanceContext} from '../../context/PastPerformanceContext'
import {TYPES_OF_ROR} from '../../../constants/AppConstants'
import {
  calculateCustomROI,
  generateComparePerformanceChartData,
} from '../helper/calculator/ComparePerformanceCalculator'
import {useComparePerformanceContext} from '../../context/ComparePerformanceContext'

export const useComparePerformanceState = () => {
  // first render detector
  const isFirstRender = useRef(true)

  // past performance context
  const {pastInvestments} = usePastPerformanceContext()

  // compare performance context
  const {
    chartData,
    setChartData,
    isCustomAssetUpdated,
    setIsCustomAssetUpdated,
    setIsFormSubmitted,
  } = useComparePerformanceContext()
  // find last date of prev month
  const lastDateOfPrevMonth = moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY')
  // start date
  const startDate = moment(lastDateOfPrevMonth).subtract(1, 'years').format('MM-DD-YYYY')

  // investments name state
  const [investmentNamesObj, setInvestmentNamesObj] = useState([])

  // define the validation schema using Yup
  const ComparePerformanceSchema = Yup.object().shape({
    start_date: Yup.string().required('Start date is required field'),
    start_value: Yup.number().required('Start value is required field'),
    end_date: Yup.string().required('End date is required field'),
    end_value: Yup.number().required('End value is required field'),
    investments_to_compare: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number().required(),
          label: Yup.string().trim().required(),
        })
      )
      .required('Required field')
      .max(5, 'Cannot have more than 5 investments to compare')
      .min(1, 'Select at least 1 investment to compare'),
    // custom investments
    custom_investments: Yup.array().of(
      Yup.object().shape({
        custom_id: Yup.number().required(),
        custom_start_date: Yup.string().required('Custom start date is required'),
        custom_start_value: Yup.number().required('Custom start value is required'),
        custom_end_date: Yup.string().required('Custom end date is required'),
        custom_end_value: Yup.number().required('Custom end value is required'),
        custom_name: Yup.string().trim().required('Custom name is required'),
      })
    ),
  })

  // define the initial form values
  const initialValues: ComparePerformanceObjModel = {
    start_date: '',
    start_value: 0,
    end_date: '',
    end_value: 0,
    investments_to_compare: [],
    custom_investments: [],
  }

  // formik handle submit
  const handleSubmit = async (values: any) => {
    // console.log('*-*-*-values: ', values)
    setIsFormSubmitted(true)
    calculatePastInvestmentValues()
  }

  // formik
  const formik = useFormik<ComparePerformanceObjModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ComparePerformanceSchema,
    onSubmit: handleSubmit,
  })

  // setting state investments names obj
  useEffect(() => {
    // extract name from past investments array
    const fetchedInvestmentNames = pastInvestments.map((investment: any) => investment.name)
    // create options object for investment names drop down
    const options = fetchedInvestmentNames.map((name: string, _index: number) => ({
      value: _index,
      label: name,
    }))

    setInvestmentNamesObj(options)
  }, [pastInvestments])

  // re-rendering chart after updating custom asset
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    setIsCustomAssetUpdated(false)
    calculatePastInvestmentValues()
  }, [isCustomAssetUpdated])

  // investment helper functions
  // calculate past date
  const calculatePastDate = (noOfYears: number, formattedAPIUpdateDate: string): string => {
    // subtract N years and set to the last day of the month
    let oldDate = moment.utc(formattedAPIUpdateDate).subtract(noOfYears, 'years').endOf('month')

    // subtract 1 month fro date if the input date is 31
    if (moment(formik.values.start_date).date() === 31) {
      oldDate = oldDate.subtract(1, 'months')
    }
    // Format the date as a string (YYYY-MM) and return
    const formattedOldDate = oldDate.format('YYYY-MM')

    console.log(formattedOldDate)
    return formattedOldDate
  }

  // filter investment values
  const filterInvestmentValues = (
    parsedInvestmentsData: Record<string, any>,
    formattedOldDate: string,
    formattedAPIUpdatedDate: string
  ): {oldInvestmentValue: number | undefined; currentInvestmentValue: number | undefined} => {
    let oldInvestmentValue: number | undefined, currentInvestmentValue: number | undefined

    Object.keys(parsedInvestmentsData).forEach((date) => {
      // Fetch old value
      if (date.startsWith(formattedOldDate)) {
        oldInvestmentValue = parsedInvestmentsData[date].value
      }
      // Fetch current value
      if (date === formattedAPIUpdatedDate) {
        currentInvestmentValue = parsedInvestmentsData[date].value
      }
    })

    return {oldInvestmentValue, currentInvestmentValue}
  }

  // calculate total ROI
  const calculateTotalROI = (
    oldValue: number | undefined,
    currentValue: number | undefined
  ): number => {
    if (oldValue !== undefined && currentValue !== undefined) {
      return ((currentValue - oldValue) / oldValue) * 100
    } else {
      return NaN
    }
  }

  // calculate annualized ROR
  const calculateAnnualizedROR = (
    oldValue: number | undefined,
    currentValue: number | undefined,
    years: number
  ): number => {
    if (oldValue !== undefined && currentValue !== undefined && years !== 0) {
      const annualizedROR = Math.pow(currentValue / oldValue, 1 / years) - 1
      return +(annualizedROR * 100).toFixed(2)
    } else {
      return NaN
    }
  }

  // calculating no. of years
  const calculateNoOfYears = () => {
    // format start date
    const startDate = moment.utc(formik.values.start_date).format('YYYY-MM-DD')
    // format end date
    const endDate = moment.utc(formik.values.end_date).format('YYYY-MM-DD')

    // calculate the difference in years
    return moment
      .utc(endDate, 'YYYY-MM-DD')
      .diff(moment.utc(startDate, 'YYYY-MM-DD'), 'years', true)
  }

  // calculate past investment values
  const calculatePastInvestmentValues = () => {
    // format start date
    const startDate = moment.utc(formik.values.start_date).format('YYYY-MM-DD')
    // format end date
    const endDate = moment.utc(formik.values.end_date).format('YYYY-MM-DD')

    // calculating custom RoI
    let customRoI = calculateCustomROI(formik.values.end_value, formik.values.start_value)

    // calculate the difference in years
    let noOfYears = calculateNoOfYears()

    // mapping investments into results obj
    const calculatedInvestmentData = pastInvestments.map((investment: any, _index: number) => {
      const {name, api_updated_at} = investment

      // format api_updated_at
      const formattedAPIUpdateDate = moment.utc(api_updated_at).format('YYYY-MM-DD')

      // calculate old date
      const formattedOldDate = calculatePastDate(noOfYears, formattedAPIUpdateDate)

      const parsedInvestmentsData = JSON.parse(investment.investments_data)
      // filter investment values
      const {oldInvestmentValue, currentInvestmentValue} = filterInvestmentValues(
        parsedInvestmentsData,
        formattedOldDate,
        formattedAPIUpdateDate
      )

      // calculating totalROI
      const totalROI = calculateTotalROI(oldInvestmentValue, currentInvestmentValue)
      // calculating annualizedROI
      const annualizedROR = calculateAnnualizedROR(
        oldInvestmentValue,
        currentInvestmentValue,
        noOfYears
      )

      return {
        investment_name: name,
        totalROI,
        annualizedROR,
        pastData: {
          endDate,
          endValue: currentInvestmentValue,
          startDate,
          startValue: oldInvestmentValue,
        },
      }
    })

    if (comparePerformanceAssets.length <= 1) {
      // updating past assets value
      calculatedInvestmentData.forEach((pastData: any, _index: number) => {
        //push each entry into comparePerformanceAssets
        comparePerformanceAssets.push({
          ...pastData, // spread the original data
          id: _index + 1,
          label: pastData?.investment_name,
          dropdownLabel: `(${pastData?.totalROI}%) ${pastData?.investment_name}`,
          customLabel: `${pastData?.totalROI}%`,
          color: '#9A258E',
          value: pastData?.totalROI,
          type: TYPES_OF_ROR.TYPE_SIMPLE,
          assetChartType: 'projections',
          annualizedROR: pastData?.annualizedROR,
          pastData: pastData?.pastData,
        })
      })

      // adding custom asset in comparePerformanceAssets
      comparePerformanceAssets.push({
        id: comparePerformanceAssets.length + 1,
        label: 'My investment',
        dropdownLabel: `(${customRoI}%) My investment`,
        customLabel: `${customRoI}%`,
        color: '#9A258E',
        value: customRoI,
        type: TYPES_OF_ROR.TYPE_SIMPLE,
        assetChartType: 'projections',
        assetBarType: 'defaultCustomInvestment',
        annualizedROR: 0,
        pastData: {
          endDate: '',
          endValue: 0,
          startDate: '',
          startValue: 0,
        },
      })
    } else {
      // Update the index based on its label
      calculatedInvestmentData.forEach((newData: any) => {
        const existingAssetIndex = comparePerformanceAssets.findIndex(
          (asset) => asset.label === newData.investment_name
        )

        if (existingAssetIndex !== -1) {
          comparePerformanceAssets[existingAssetIndex] = {
            ...comparePerformanceAssets[existingAssetIndex],
            id: comparePerformanceAssets[existingAssetIndex].id,
            label: newData?.investment_name,
            dropdownLabel: `(${newData?.totalROI}%) ${newData?.investment_name}`,
            customLabel: `${newData?.totalROI}%`,
            color: '#9A258E',
            value: newData?.totalROI,
            type: TYPES_OF_ROR.TYPE_SIMPLE,
            assetChartType: 'projections',
            annualizedROR: newData?.annualizedROR,
            pastData: newData?.pastData,
          }
        }
      })
      // updating custom asset in comparePerformanceAssets
      const defaultCustomAssetIndex = comparePerformanceAssets.findIndex(
        (asset) => asset.assetBarType === 'defaultCustomInvestment'
      )
      comparePerformanceAssets[defaultCustomAssetIndex] = {
        ...comparePerformanceAssets[defaultCustomAssetIndex],
        id: comparePerformanceAssets[defaultCustomAssetIndex].id,
        label: 'My investment',
        dropdownLabel: `(${customRoI}%) My investment`,
        customLabel: `${customRoI}%`,
        color: '#9A258E',
        value: customRoI,
        type: TYPES_OF_ROR.TYPE_SIMPLE,
        assetChartType: 'projections',
        annualizedROR: 0,
        pastData: {
          endDate: '',
          endValue: 0,
          startDate: '',
          startValue: 0,
        },
      }
    }

    const _chartData = generateComparePerformanceChartData(
      +formik.values.start_value,
      +formik.values.end_value,
      noOfYears,
      // comparePerformanceAssets.filter((asset) => formik.values.investments_to_compare.includes(asset.label)),
      // filter assets
      comparePerformanceAssets.filter((asset) => {
        // Check if the asset label is included in formik.values.investments_to_compare
        const isIncluded = formik.values.investments_to_compare.some(
          (investment) => investment.label === asset.label
        )

        // if the asset label is 'ETH' and noOfYears is greater than 2, exclude it
        if (asset.label === 'ETH' && noOfYears > 2) {
          return false
        }
        // adding custom asset
        if (
          asset.assetBarType === 'defaultCustomInvestment' ||
          asset.assetBarType === 'customInvestment'
        ) {
          return true
        }

        return isIncluded
      }),
      0, // 0 contributions
      1, // yearly contributions frequency
      formik.values.custom_investments
    )
    setChartData(_chartData)
  }

  // handle input key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  // custom investments
  const handleAddInvestment = () => {
    const lastIndexId = comparePerformanceAssets[comparePerformanceAssets.length - 1].id

    const newInvestment = {
      custom_id: lastIndexId + 1,
      custom_start_date: '',
      custom_start_value: 0,
      custom_end_date: '',
      custom_end_value: 0,
      custom_name: '',
    }
    formik.setFieldValue('custom_investments', [...formik.values.custom_investments, newInvestment])

    // adding custom asset in comparePerformanceAssets
    comparePerformanceAssets.push({
      id: lastIndexId + 1,
      label: '',
      dropdownLabel: '',
      customLabel: '',
      color: '#9A258E',
      value: 0,
      type: TYPES_OF_ROR.TYPE_SIMPLE,
      assetChartType: 'projections',
      assetBarType: 'customInvestment',
      annualizedROR: 0,
      pastData: {
        endDate: '',
        endValue: 0,
        startDate: '',
        startValue: 0,
      },
    })
  }

  const handleRemoveInvestment = (index: number) => {
    const updatedInvestments = [...formik.values.custom_investments]
    // updatedInvestments.splice(index, 1)
    formik.setFieldValue('custom_investments', updatedInvestments)

    const customInvestment = updatedInvestments?.find(
      (investment) => investment?.custom_id === updatedInvestments[index]?.custom_id
    )

    let customInvestmentIndex = comparePerformanceAssets.findIndex(
      (asset) => asset.id === customInvestment?.custom_id
    )

    if (customInvestmentIndex !== -1) {
      comparePerformanceAssets.splice(customInvestmentIndex, 1)
    }

    updatedInvestments.splice(index, 1)
  }

  const handleInvestmentChange = (index: number, field: string, value: any) => {
    const updatedInvestments = [...formik.values.custom_investments]
    updatedInvestments[index] = {...updatedInvestments[index], [field]: value}
    formik.setFieldValue('custom_investments', updatedInvestments)

    const customInvestment = updatedInvestments?.find(
      (investment) => investment?.custom_id === updatedInvestments[index]?.custom_id
    )

    let customInvestmentIndex = comparePerformanceAssets.findIndex(
      (asset) => asset.id === customInvestment?.custom_id
    )

    let customInvestmentRoI = calculateCustomROI(
      customInvestment?.custom_end_value ?? 0,
      customInvestment?.custom_start_value ?? 0
    )

    comparePerformanceAssets[customInvestmentIndex] = {
      ...comparePerformanceAssets[customInvestmentIndex],
      id: comparePerformanceAssets[customInvestmentIndex].id,
      label: customInvestment?.custom_name ?? 'My Investment',
      dropdownLabel: `(${customInvestmentRoI}%) ${customInvestment?.custom_name}`,
      customLabel: `${customInvestmentRoI}%`,
      color: '#9A258E',
      value: customInvestmentRoI,
      type: TYPES_OF_ROR.TYPE_SIMPLE,
      assetChartType: 'projections',
      annualizedROR: 0,
      pastData: {
        endDate: '',
        endValue: 0,
        startDate: '',
        startValue: 0,
      },
    }
  }
  // console.log(comparePerformanceAssets)

  return {
    formik,
    chartData,
    calculateNoOfYears,
    handleKeyPress,
    investmentNamesObj,
    handleAddInvestment,
    handleRemoveInvestment,
    handleInvestmentChange,
  }
}
