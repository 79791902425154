import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'

interface CustomTooltipProps extends TooltipProps<number, string> {
  isToolTips: boolean // Add the dragged prop
  compareScenarioChartData: any
}

const IBLOCLineChartTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  isToolTips,
  compareScenarioChartData,
}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  return (
    <>
      {isToolTips && (
        <div className='custom-tooltip ibloc-line-chart-tooltip-container'>
          <table className='table'>
            <thead>
              <span className='label-bold'>Total</span>
              <tr>
                <th></th>
                {data?.Scenario1_TCVDelta && <th className='label-bold'>Scenario 1</th>}
                {data?.Scenario2_TCVDelta && <th className='label-bold'>Scenario 2</th>}
                {data?.Scenario3_TCVDelta && <th className='label-bold'>Scenario 3</th>}
                {data?.Scenario4_TCVDelta && <th className='label-bold'>Scenario 4</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='label-bold paragraph-x-small mb-0'>Total Cash Value:</td>
                {data?.Scenario1_TotalCashValue && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {toUSD(data?.Scenario1_TotalCashValue)}
                  </td>
                )}
                {data?.Scenario2_TotalCashValue && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {toUSD(data?.Scenario2_TotalCashValue)}
                  </td>
                )}
                {data?.Scenario3_TotalCashValue && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {toUSD(data?.Scenario3_TotalCashValue)}
                  </td>
                )}
                {data?.Scenario4_TotalCashValue && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {toUSD(data?.Scenario4_TotalCashValue)}
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0'>Cash Balance</td>
                {data?.Scenario1_CashBalance !== undefined && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data.Scenario1_CashBalance >= 0
                      ? `+${toUSD(data.Scenario1_CashBalance)}`
                      : `-${toUSD(Math.abs(data.Scenario1_CashBalance))}`}
                  </td>
                )}
                {data?.Scenario2_CashBalance !== undefined && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data.Scenario2_CashBalance >= 0
                      ? `+${toUSD(data.Scenario2_CashBalance)}`
                      : `-${toUSD(Math.abs(data.Scenario2_CashBalance))}`}
                  </td>
                )}
                {data?.Scenario3_CashBalance !== undefined && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data.Scenario3_CashBalance >= 0
                      ? `+${toUSD(data.Scenario3_CashBalance)}`
                      : `-${toUSD(Math.abs(data.Scenario3_CashBalance))}`}
                  </td>
                )}
                {data?.Scenario4_CashBalance !== undefined && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data.Scenario4_CashBalance >= 0
                      ? `+${toUSD(data.Scenario4_CashBalance)}`
                      : `-${toUSD(Math.abs(data.Scenario4_CashBalance))}`}
                  </td>
                )}
              </tr>

              <tr>
                <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                  CV Growth
                </td>
                {data?.Scenario1_CvGrowthYoY && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario1_CvGrowthYoY?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario2_CvGrowthYoY && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario2_CvGrowthYoY?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario3_CvGrowthYoY && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario3_CvGrowthYoY?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario4_CvGrowthYoY && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario4_CvGrowthYoY?.toFixed(2)}%
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                  Cash Balance Growth
                </td>
                {data?.Scenario1_CashBalanceGrowth && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario1_CashBalanceGrowth?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario2_CashBalanceGrowth && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario2_CashBalanceGrowth?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario3_CashBalanceGrowth && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario3_CashBalanceGrowth?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario4_CashBalanceGrowth && (
                  <td className='label-bold paragraph-x-small label-initial-deposit mb-0'>
                    {data?.Scenario4_CashBalanceGrowth?.toFixed(2)}%
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0 label-re-invest'>Cash In</td>
                {data?.Scenario1_CashIn !== undefined && data?.Scenario1_CashIn !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {data.Scenario1_CashIn >= 0
                      ? `+${toUSD(data.Scenario1_CashIn)}`
                      : `-${toUSD(Math.abs(data.Scenario1_CashIn))}`}
                  </td>
                )}
                {data?.Scenario2_CashIn !== undefined && data?.Scenario2_CashIn !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {data.Scenario2_CashIn >= 0
                      ? `+${toUSD(data.Scenario2_CashIn)}`
                      : `-${toUSD(Math.abs(data.Scenario2_CashIn))}`}
                  </td>
                )}
                {data?.Scenario3_CashIn !== undefined && data?.Scenario3_CashIn !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {data.Scenario3_CashIn >= 0
                      ? `+${toUSD(data.Scenario3_CashIn)}`
                      : `-${toUSD(Math.abs(data.Scenario3_CashIn))}`}
                  </td>
                )}
                {data?.Scenario4_CashIn !== undefined && data?.Scenario4_CashIn !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {data.Scenario4_CashIn >= 0
                      ? `+${toUSD(data.Scenario4_CashIn)}`
                      : `-${toUSD(Math.abs(data.Scenario4_CashIn))}`}
                  </td>
                )}
              </tr>

              <tr>
                <td className='label-bold paragraph-x-small mb-0 label-borrow'>Cash Out</td>
                {data?.Scenario1_CashOut && (
                  <td className='label-bold paragraph-x-small mb-0 label-borrow'>
                    -{toUSD(data?.Scenario1_CashOut)}
                  </td>
                )}
                {data?.Scenario2_CashOut && (
                  <td className='label-bold paragraph-x-small mb-0 label-borrow'>
                    -{toUSD(data?.Scenario2_CashOut)}
                  </td>
                )}
                {data?.Scenario3_CashOut && (
                  <td className='label-bold paragraph-x-small mb-0 label-borrow'>
                    -{toUSD(data?.Scenario3_CashOut)}
                  </td>
                )}
                {data?.Scenario4_CashOut && (
                  <td className='label-bold paragraph-x-small mb-0 label-borrow'>
                    -{toUSD(data?.Scenario4_CashOut)}
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0'>IBLOC Balance</td>
                {data?.Scenario1_IBLOCBalance && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    +{toUSD(data?.Scenario1_IBLOCBalance)}
                  </td>
                )}
                {data?.Scenario2_IBLOCBalance && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    +{toUSD(data?.Scenario2_IBLOCBalance)}
                  </td>
                )}
                {data?.Scenario3_IBLOCBalance && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    +{toUSD(data?.Scenario3_IBLOCBalance)}
                  </td>
                )}
                {data?.Scenario4_IBLOCBalance && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    +{toUSD(data?.Scenario4_IBLOCBalance)}
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0 label-gain'>Cash on Cash ROI</td>
                {data?.Scenario1_CashOnCashROI && (
                  <td className='label-bold paragraph-x-small mb-0 label-gain'>
                    {isFinite(data.Scenario1_CashOnCashROI) &&
                    !Number.isNaN(data.Scenario1_CashOnCashROI)
                      ? `${data.Scenario1_CashOnCashROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario2_CashOnCashROI && (
                  <td className='label-bold paragraph-x-small mb-0 label-gain'>
                    {isFinite(data.Scenario2_CashOnCashROI) &&
                    !Number.isNaN(data.Scenario2_CashOnCashROI)
                      ? `${data.Scenario2_CashOnCashROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario3_CashOnCashROI && (
                  <td className='label-bold paragraph-x-small mb-0 label-gain'>
                    {isFinite(data.Scenario3_CashOnCashROI) &&
                    !Number.isNaN(data.Scenario3_CashOnCashROI)
                      ? `${data.Scenario3_CashOnCashROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario4_CashOnCashROI && (
                  <td className='label-bold paragraph-x-small mb-0 label-gain'>
                    {isFinite(data.Scenario4_CashOnCashROI) &&
                    !Number.isNaN(data.Scenario4_CashOnCashROI)
                      ? `${data.Scenario4_CashOnCashROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0 label-re-invest'>Opp. Cost ROI</td>
                {data?.Scenario1_TCOOROI !== undefined && data?.Scenario1_TCOOROI !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {isFinite(data.Scenario1_TCOOROI)
                      ? `${data.Scenario1_TCOOROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario2_TCOOROI !== undefined && data?.Scenario2_TCOOROI !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {isFinite(data.Scenario2_TCOOROI)
                      ? `${data.Scenario2_TCOOROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario3_TCOOROI !== undefined && data?.Scenario3_TCOOROI !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {isFinite(data.Scenario3_TCOOROI)
                      ? `${data.Scenario3_TCOOROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
                {data?.Scenario4_TCOOROI !== undefined && data?.Scenario4_TCOOROI !== null && (
                  <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                    {isFinite(data.Scenario4_TCOOROI)
                      ? `${data.Scenario4_TCOOROI.toFixed(2)}%`
                      : '0%'}
                  </td>
                )}
              </tr>
              <tr>
                <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                  Total Cash Opportunity Cost
                </td>
                {data?.Scenario1_TotalCashOpportunity !== undefined &&
                  data?.Scenario1_TotalCashOpportunity !== null && (
                    <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                      {data.Scenario1_TotalCashOpportunity >= 0
                        ? `+${toUSD(data.Scenario1_TotalCashOpportunity)}`
                        : `-${toUSD(Math.abs(data.Scenario1_TotalCashOpportunity))}`}
                    </td>
                  )}
                {data?.Scenario2_TotalCashOpportunity !== undefined &&
                  data?.Scenario2_TotalCashOpportunity !== null && (
                    <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                      {data.Scenario2_TotalCashOpportunity >= 0
                        ? `+${toUSD(data.Scenario2_TotalCashOpportunity)}`
                        : `-${toUSD(Math.abs(data.Scenario2_TotalCashOpportunity))}`}
                    </td>
                  )}
                {data?.Scenario3_TotalCashOpportunity !== undefined &&
                  data?.Scenario3_TotalCashOpportunity !== null && (
                    <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                      {data.Scenario3_TotalCashOpportunity >= 0
                        ? `+${toUSD(data.Scenario3_TotalCashOpportunity)}`
                        : `-${toUSD(Math.abs(data.Scenario3_TotalCashOpportunity))}`}
                    </td>
                  )}
                {data?.Scenario4_TotalCashOpportunity !== undefined &&
                  data?.Scenario4_TotalCashOpportunity !== null && (
                    <td className='label-bold paragraph-x-small mb-0 label-re-invest'>
                      {data.Scenario4_TotalCashOpportunity >= 0
                        ? `+${toUSD(data.Scenario4_TotalCashOpportunity)}`
                        : `-${toUSD(Math.abs(data.Scenario4_TotalCashOpportunity))}`}
                    </td>
                  )}
              </tr>

              <tr>
                <td className='label-bold paragraph-x-small mb-0'>APR this year</td>
                {data?.Scenario1_APR && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data?.Scenario1_APR?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario2_APR && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data?.Scenario2_APR?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario3_APR && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data?.Scenario3_APR?.toFixed(2)}%
                  </td>
                )}
                {data?.Scenario4_APR && (
                  <td className='label-bold paragraph-x-small mb-0'>
                    {data?.Scenario4_APR?.toFixed(2)}%
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
  return null

  //   return null // Return null if tooltip should not be shown
}

export default IBLOCLineChartTooltip
