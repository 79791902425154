import {useState, useRef, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {
  CompoundingGrowth,
  PastPerformanceCompoundingGrowth,
  InvestmentChartProps,
  InvestmentFormModel,
} from '../../../helper/calculator/_model'
import {pastPerformanceAssets} from '../../projections/components/ProjectionsConfig'
import {generateChartData} from '../../../helper/calculator/InvestmentCalculator'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {useProjectionsState} from '../../../utils/commonUtils'
import {usePastPerformanceContext} from '../../../../context/PastPerformanceContext'
import {fetchPastInvestments} from '../../../advisor/core/_request'
import moment from 'moment'

export function usePastPerformanceState() {
  // receiver context
  const {receiverFormDataObj} = useReceiverFormContext()
  // past performance context
  const {
    PPYearsAgo,
    isPastDataCalculated,
    setIsPastDataCalculated,
    pastInvestments,
    setPastInvestments,
  } = usePastPerformanceContext()
  // projection formik values
  const {formik} = useProjectionsState()
  // extracting useparams
  const {advisor_url} = useParams()

  // past performance state hooks
  const [pastChartData, setPastChartData] = useState<InvestmentChartProps[]>([])
  const [pastChartDataOldValues, setPastChartDataOldValues] = useState<InvestmentChartProps[]>([])
  const [pastPerformanceAssetsOldState, setPastPerformanceAssetsOldState] = useState<
    PastPerformanceCompoundingGrowth[]
  >([])

  const isFirstRender = useRef(true)

  // for past chart data
  useEffect(() => {
    const _pastChartData = advisor_url && getPastChartData(formik.values, pastPerformanceAssets)
    const _pastChartData_OldValues =
      advisor_url && getPastChartData(formik.values, pastPerformanceAssetsOldState)
    // setPastChartData(_pastChartData)
    setPastChartData((prev) => {
      return _pastChartData // Update state based on pastChartData_OldValues
    })
    setPastChartDataOldValues((prev) => {
      return _pastChartData_OldValues
    })
  }, [receiverFormDataObj, isPastDataCalculated])

  // for past performance API
  useEffect(() => {
    handleFetchPastInvestments()
  }, [])

  // for calculating past investment values
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    calculatePastInvestmentValues()
    setIsPastDataCalculated(!isPastDataCalculated)
  }, [pastInvestments, PPYearsAgo])

  // investment helper functions
  // calculate past date
  const calculatePastDate = (noOfYears: number, formattedAPIUpdateDate: string): string => {
    // subtract N years and set to the last day of the month
    const oldDate = moment.utc(formattedAPIUpdateDate).subtract(noOfYears, 'years').endOf('month')

    // Format the date as a string (YYYY-MM) and return
    const formattedOldDate = oldDate.format('YYYY-MM')

    return formattedOldDate
  }

  // filter investment values
  const filterInvestmentValues = (
    parsedInvestmentsData: Record<string, any>,
    formattedOldDate: string,
    formattedAPIUpdatedDate: string
  ): {oldInvestmentValue: number | undefined; currentInvestmentValue: number | undefined} => {
    let oldInvestmentValue: number | undefined, currentInvestmentValue: number | undefined

    Object.keys(parsedInvestmentsData).forEach((date) => {
      // Fetch old value
      if (date.startsWith(formattedOldDate)) {
        oldInvestmentValue = parsedInvestmentsData[date].value
      }
      // Fetch current value
      if (date === formattedAPIUpdatedDate) {
        currentInvestmentValue = parsedInvestmentsData[date].value
      }
    })

    return {oldInvestmentValue, currentInvestmentValue}
  }

  // calculate total ROI
  const calculateTotalROI = (
    oldValue: number | undefined,
    currentValue: number | undefined
  ): number => {
    if (oldValue !== undefined && currentValue !== undefined) {
      return +(((currentValue - oldValue) / oldValue) * 100).toFixed(2)
    } else {
      return NaN
    }
  }

  // calculate annualized ROR
  const calculateAnnualizedROR = (
    oldValue: number | undefined,
    currentValue: number | undefined,
    years: number
  ): number => {
    if (oldValue !== undefined && currentValue !== undefined && years !== 0) {
      const annualizedROR = Math.pow(currentValue / oldValue, 1 / years) - 1
      return +(annualizedROR * 100).toFixed(2)
    } else {
      return NaN
    }
  }

  // calculating no. of years
  const monthsToYears = (noOfMonths: number) => {
    return noOfMonths / 12
  }

  // calculate past investment values
  const calculatePastInvestmentValues = () => {
    // temporary array for string updated assets
    const updatedAssets = pastPerformanceAssets

    // calculating no. of years
    const noOfYears = monthsToYears(PPYearsAgo)

    // mapping investments into results obj
    const calculatedInvestmentData = pastInvestments.map((investment: any) => {
      const {name, api_updated_at} = investment

      // format api_updated_at
      const formattedAPIUpdateDate = moment.utc(api_updated_at).format('YYYY-MM-DD')

      // calculate old date
      const formattedOldDate = calculatePastDate(noOfYears, formattedAPIUpdateDate)

      const parsedInvestmentsData = JSON.parse(investment.investments_data)
      // filter investment values
      const {oldInvestmentValue, currentInvestmentValue} = filterInvestmentValues(
        parsedInvestmentsData,
        formattedOldDate,
        formattedAPIUpdateDate
      )

      // calculating totalROI
      const totalROI = calculateTotalROI(oldInvestmentValue, currentInvestmentValue)
      // calculating annualizedROI
      const annualizedROR = calculateAnnualizedROR(
        oldInvestmentValue,
        currentInvestmentValue,
        noOfYears
      )

      return {
        investment_name: name,
        totalROI,
        annualizedROR,
      }
    })

    // updating past assets value
    calculatedInvestmentData.map((pastData: any) => {
      const index = updatedAssets.findIndex((data) => data.assetSymbol === pastData.investment_name)
      if (index !== -1) {
        updatedAssets[index] = {
          ...updatedAssets[index],
          value: pastData.totalROI,
          customLabel: `${pastData.totalROI}%`,
          dropdownLabel: `(${pastData.totalROI}%) ${pastData.investment_name}`,
          annualizedROR: pastData.annualizedROR,
        }
      }
    })

    // Update the state with the modified array
    setPastPerformanceAssetsOldState(updatedAssets)
    setIsPastDataCalculated(!isPastDataCalculated)
  }

  // fetch past investments from API
  const handleFetchPastInvestments = async () => {
    try {
      const response = await fetchPastInvestments()
      setPastInvestments(response.data)
    } catch (error: any) {
      console.log('An error occurred during the request.')
    }
  }

  // handle compounding growth
  const handleCompoundingGrowth = (data: any) => {
    return data.filter((item: any) => {
      // Keep all items except ARQ
      return item.type === TYPES_OF_ROR.TYPE_SIMPLE
    })
  }

  // get past chart data
  const getPastChartData = (
    data: InvestmentFormModel,
    pastPerformanceAssets: CompoundingGrowth[]
  ) => {
    const _pastChartData = generateChartData(
      data.initialDeposit,
      data.contributions,
      data.contributionFrequency,
      PPYearsAgo,
      pastPerformanceAssets,
      // assets,
      data.ARQ_Flip_Gain,
      data.ARQ_rental_income,
      data.ARQ_rental_contribution,
      data.ARQ_appreciation,
      data.ARQ_property_worth,
      data.management_fee,
      data.management_fee > 0 ? true : false,
      data.rollOver,
      data.customer_actual,
      data.newStartingAmount,
      data.ARQ_Property_End_Value
    )
    const filteredData = _pastChartData.map((entry) => ({
      ...entry,
      contributions: entry.type === TYPES_OF_ROR.TYPE_SIMPLE ? entry.contributions : 0,
      ARQ_Flip_Gain:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE || entry.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
          ? 0
          : entry.ARQ_Flip_Gain,
      ARQ_rental_income:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE || entry.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
          ? 0
          : entry.ARQ_rental_income,
      ARQ_appreciation:
        entry.type === TYPES_OF_ROR.TYPE_AFTER_EXIT || entry.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
          ? entry.ARQ_appreciation
          : 0,
    }))

    const barsToBeFilterOnIds = receiverFormDataObj.past_selected_bars

    const arrangedData = filteredData.filter((data) => barsToBeFilterOnIds.includes(data.id))

    // setPastChartData(advisor_url ? arrangedData : filteredData)
    return advisor_url ? arrangedData : handleCompoundingGrowth(filteredData)
  }

  return {
    pastChartData,
    setPastChartData,
    pastChartDataOldValues,
    setPastChartDataOldValues,
    pastPerformanceAssetsOldState,
    setPastPerformanceAssetsOldState,
    getPastChartData,
  }
}
