import {useState} from 'react'

const PropertyDetailCard: React.FC = () => {
  const notesDummyData: any = [
    {
      id: 1,
      note: 'Rent last month lower because of maint.',
    },
    {
      id: 2,
      note: 'Performance is on track',
    },
    {
      id: 3,
      note: 'Next meeting with Aniq in June',
    },
  ]

  const [notesArray, setNotesArray] = useState<any>(notesDummyData)

  return (
    <>
      <div className='d-flex justify-content-center align-items-center mt-5'>
        <div className='greeting-card mt-10 mb-15'>
          <span className='greeting-emoji' role='img' aria-label='sun'>
            ☀️
          </span>
          <span className='greeting-text'>
            My ARQ Properties
            <br />
            Friday 5/17
          </span>
        </div>
      </div>
      <div className='row m-0'>
        <div className='col-md-5'>
          <div className='card bg-transparent p-8 pt-0'>
            <div className='detail-section'>
              <p className='mb-1'>1412 W S. Houston St.</p>
              <p className='value-change positive'>$45,282 ⬆️55%</p>
              <div className='additional-details'>
                <hr className='' />
                <p className='mb-0 paragraph-small'>
                  <span className='span-txt'>Started:</span>2 years
                </p>
                <hr className='' />
                <p className='mb-0 paragraph-small'>
                  <span className='span-txt'>Money In:</span>$32,000
                </p>
                <hr className='' />
                <p className='mb-0 paragraph-small'>
                  <span className='span-txt'>Total Rent:</span>$21,000
                </p>
                <hr className='' />
                <p className='mb-0 paragraph-small'>
                  <span className='span-txt'>Total Returns:</span>45%
                </p>
                <hr className='' />
                <p className='mb-0 paragraph-small'>
                  <span className='span-txt'>Share:</span>14%
                </p>
                <hr className='mb-0' />
              </div>
            </div>
            <div className='image-section'>{/* <img src={imageUrl} alt='Property' /> */}</div>
          </div>
        </div>
        <div className='col-md-7'>
          <img
            src='https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg'
            alt='Property'
            className='w-100 h-100'
            style={{borderRadius: '4px'}}
          />
        </div>
      </div>

      {/* notes */}
      <div data-aos='zoom-in-up' data-aos-duration='500' data-aos-delay='200'>
        <div className='p-8'>
          <ul>
            {notesArray.map((item: any, index: any) => (
              <li className='arq-li-txt' key={index}>
                {item.note}
              </li>
            ))}
          </ul>
          <p className='add-more-button ms-5'>Add More</p>
        </div>
      </div>
    </>
  )
}

export default PropertyDetailCard
