import {SuggestedFeatureModal} from './_model'
export const SuggestedFeatures: SuggestedFeatureModal[] = [
  {
    id: 0,
    name: 'Mortgage Calculator',
    description: '',
  },
  {
    id: 1,
    name: 'Whole Life vs Stock',
    description: '',
  },
  {
    id: 2,
    name: 'Car Lease vs Buy',
    description: '',
  },
  {
    id: 3,
    name: 'Buy or Rent',
    description: '',
  },
  {
    id: 4,
    name: 'Mortgage Or Invest',
    description: '',
  },
  {
    id: 5,
    name: 'Margin Loans',
    description: '',
  },
  {
    id: 6,
    name: 'Cash or Finance',
    description: '',
  },
  {
    id: 7,
    name: 'Credit Card Debt',
    description: '',
  },
  {
    id: 8,
    name: `"Things Add Up"`,
    description: '',
  },
]
