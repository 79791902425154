import React, {useState} from 'react'
import useScreenWidth from '../../hooks/useScreenWidth'

interface TimeFrameSelectorProps {
  onTimeFrameSelected: (index: number, value: string) => void
  currentChartName: string
}

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  onTimeFrameSelected,
  currentChartName,
}) => {
  const [activeTimeFrame, setActiveTimeFrame] = useState<string>(currentChartName)
  const timeFrames = ['QTD', 'YTD', 'LTD', 'Future'] // Define the initial time frames without 'ALL'
  const screenWidth = useScreenWidth()
  const handleTimeFrameClick = (timeFrame: string, index: number) => {
    setActiveTimeFrame(timeFrame)
    onTimeFrameSelected(index, timeFrame)
  }

  return (
    <div className='time-frame-selector-container'>
      <div className={`time-frame-buttons ${screenWidth < 546 ? '' : 'fit-center'}`}>
        {timeFrames.map((timeFrame, index) => (
          <button
            key={timeFrame}
            onClick={() => handleTimeFrameClick(timeFrame, index)}
            className={activeTimeFrame === timeFrame ? 'active' : ''}
          >
            {timeFrame}
          </button>
        ))}
      </div>
      <div className={`all-button ${screenWidth < 546 ? 'time-frame-buttons' : ''}`}>
        <button
          onClick={() => handleTimeFrameClick('ALL', timeFrames.length)}
          className={activeTimeFrame === 'ALL' ? 'active' : ''}
        >
          ALL
        </button>
      </div>
    </div>
  )
}

export default TimeFrameSelector
