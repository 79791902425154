import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'
const SBLOCTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
return data.deposit ? (
  <div className='custom-tooltip sbloc-tooltip-container'>
    <span className='label-bold'>Total</span>

    {/* value */}
    <div className='d-flex justify-content-between align-items-center mb-8 mt-3'>
      <p className='label-bold paragraph-x-small mb-0'>Value:</p>
      <p className='label-bold paragraph-x-small mb-0'>{toUSD(data.total_value)}</p>
    </div>

    {/* deposits */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>Deposits</p>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
        +{toUSD(data.deposit)}
      </p>
    </div>
    {/* gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-gain'>Gains</p>
      <p className='label-bold paragraph-x-small mb-0 label-gain'>
        +{toUSD(data.cummulativeReturnsWithoutStartingAmount)}
      </p>
    </div>
    {/* re-investment asset */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Re-investment Asset</p>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
        +{toUSD(data.reInvested_asset)}
      </p>
    </div>
    {/* re-investment gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Re-investment Gains</p>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
        +{toUSD(data.reInvestGain)}
      </p>
    </div>
    {/* borrowed */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Borrowed</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>
        -{toUSD(data.borrowed_amount)}
      </p>
    </div>
    {/* interest */}
    <div className='d-flex justify-content-between align-items-center mb-14'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Interest</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>
        -{toUSD(data.total_interest)}
      </p>
    </div>

    {/* ROI */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0'>ROI:</p>
      <p className='label-bold paragraph-x-small mb-0'>
        {parseFloat(data.return_total).toFixed(2)}%
      </p>
    </div>
    {/* avg approx yearly return */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0'>Approx. avg. yearly return</p>
      <p className='label-bold paragraph-x-small mb-0'>
        {parseFloat(data.approx_average_return_per_year).toFixed(2)}%
      </p>
    </div>
    {/* cashflow */}
    <div className='d-flex justify-content-between align-items-center mb-8'>
      <p className='label-bold paragraph-x-small mb-0'>Cashflow</p>
      <p className='label-bold paragraph-x-small mb-0'>{toUSD(data.total_cash)}</p>
    </div>

    <span className='label-bold'>This Year - {data.year}</span>

    {/* deposits */}
    <div className='d-flex justify-content-between align-items-center mt-6'>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>Deposits</p>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
        +{toUSD(data.depositsThisYear)}
      </p>
    </div>
    {/* gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-gain'>Gains</p>
      <p className='label-bold paragraph-x-small mb-0 label-gain'>+{toUSD(data.gainThisYear)}</p>
    </div>
    {/* re-investment gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Re-investment Gains</p>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
        +{toUSD(data.reInvestGainThisYear)}
      </p>
    </div>
    {/* borrowed */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Borrowed</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>
        -{toUSD(data.borrowedAmountThisYear)}
      </p>
    </div>
    {/* interest */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Interest</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>
        -{toUSD(data.interestThisYear)}
      </p>
    </div>
  </div>
) : null

}

export default SBLOCTooltip
