import {useEffect} from 'react'
import {ChartExperiment} from '../user/modules/ABTest/_ABTestConfig'

const ExpirationTimer: React.FC = () => {
  useEffect(() => {
    // Retrieves a random join waitlist version
    const getRandomJoinWaitlistVersion = (): string => {
      const probabilityA = 1 // 90% probability for variantA

      // Generate a random number between 0 and 1 (inclusive of 0, exclusive of 1)
      const randomNumber = Math.random()

      if (randomNumber < probabilityA) {
        return ChartExperiment.variantA // Return variantA with 90% probability
      } else {
        return ChartExperiment.variantB // Return variantB with 10% probability
      }
    }

    // Resets the expiration timestamp and updates the chart version
    const resetExpiration = (): void => {
      const expirationTimestamp = Date.now() + ChartExperiment.expirationDuration
      localStorage.setItem(ChartExperiment.expirationKey, expirationTimestamp.toString())
      localStorage.setItem(ChartExperiment.chartVersionKey, getRandomJoinWaitlistVersion())
      //   console.log('PUSHTELL-AB_Projection_Charts removed from local storage')
      //   console.log('Expiration Timestamp (reset):', expirationTimestamp)
    }

    // Checks if the expiration timestamp has passed, and resets it if necessary
    const checkExpiration = (): void => {
      const storedExpiration = localStorage.getItem(ChartExperiment.expirationKey)
      const currentTimestamp = Date.now()

      if (!storedExpiration || parseInt(storedExpiration, 10) < currentTimestamp) {
        resetExpiration()
      } else {
        // console.log('Expiration Timestamp (from local storage):', storedExpiration)
      }
    }

    // Sets the initial expiration timestamp if it doesn't exist or has already passed
    const createExpiration = (): void => {
      const storedExpiration = localStorage.getItem(ChartExperiment.expirationKey)
      const currentTimestamp = Date.now()
      const expirationTimestamp = currentTimestamp + ChartExperiment.expirationDuration

      if (!storedExpiration || parseInt(storedExpiration, 10) < currentTimestamp) {
        localStorage.setItem(ChartExperiment.expirationKey, expirationTimestamp.toString())
      }
    }

    // Set the initial expiration timestamp
    createExpiration()

    // Check expiration periodically using an interval
    const interval = setInterval(checkExpiration, ChartExperiment.expirationDuration)

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval)
    }
  }, [])

  return null
}

export default ExpirationTimer
