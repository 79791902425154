import {useState, useEffect} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {
  MyPerformanceData,
  CustomInvestmentArray,
  InvestmentMyPerformanceChartProps,
  MyPerformanceAssetsCompoundingGrowth,
} from '../../../helper/calculator/_model'
import {myPerformanceAssets} from '../../projections/components/ProjectionsConfig'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {
  calculateDate,
  generateMyPerformanceChartData,
  calculateAnnualizedExpectedROR,
  generateDataForYearToDateChart,
  calculateRentalIncomeLastQuarter,
} from '../../../helper/calculator/MyPerformanceCalculator'
import {
  calculatePropertyWorthPercentages_New,
  calculateAfterManagementFeePercentages,
} from '../../../helper/calculator/CommonCalculatorFunctions'
import moment from 'moment'
import {fetchQSPPastInvestments, sendMyPerformanceEmail} from '../core/_request'
import {ChartDataForLastQuarterChart} from '../../../helper/calculator/_model'
import {usePastPerformanceContext} from '../../../../context/PastPerformanceContext'
import {toUSDWithoutRoundOff} from '../../../helper/charts.helper'
export function useMyPerformanceDataState() {
  // extracting useparams
  const {advisor_url} = useParams()
  const location = useLocation()

  const myPerformancePageURL = `${process.env.REACT_APP_URL}${location.pathname}${location.search}`

  // destructuring usePastPerformanceContext hook

  const {
    myPerformanceDataArray,
    setCalculatedPastInvestments,
    isToggledtoShowAfterExitQuarters,
    includeAppreciationToggleForLTD,
    includeAppreciationToggleForProjections,
    oppurtunityCostToggleForLTD,
    oppurtunityCostToggleForProjections,
  } = usePastPerformanceContext()

  const [chartData, setChartData] = useState<InvestmentMyPerformanceChartProps[][]>([])
  const [chartDataforLastQuarterChart, setChartDataforLastQuarterChart] = useState<
    ChartDataForLastQuarterChart[]
  >([])
  const [QSPPastAssets, setQSPPastAssets] =
    useState<MyPerformanceAssetsCompoundingGrowth[]>(myPerformanceAssets)
  const [QSPAssets, setQSPAssets] =
    useState<MyPerformanceAssetsCompoundingGrowth[]>(myPerformanceAssets)
  const [QSPDataLoading, setQSPDataLoading] = useState(false)
  const [isQSPPastDataFetched, setIsQSPPastDataFetched] = useState(false)
  const [LTDCustomInvestmentsArray, setLTDCustomInvestmentsArray] = useState<
    CustomInvestmentArray[]
  >([])
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const isARQ_homeINURL = location.pathname.includes('arq/home')

  // updating assets array for arq/home page
  if (isARQ_homeINURL && QSPAssets[QSPAssets.length - 1].label === 'Custom') {
    // extracting last index of assets array(assuming that last index is always a custom bar)
    const customAssetIndex = QSPAssets.length - 1
    // Update the value for the (Rate of Return) object with the label "Custom"
    QSPAssets[customAssetIndex].label = 'Google'
    QSPAssets[customAssetIndex].dropdownLabel = `(25%) Google`
    QSPAssets[customAssetIndex].customLabel = `25%`
    QSPAssets[customAssetIndex].value = 25
  }

  const [chartDataForYearToDateChart, setChartDataForYearToDateChart] = useState<
    InvestmentMyPerformanceChartProps[]
  >([])
  useEffect(() => {
    updateChartData(myPerformanceDataArray)
    updateQTDChartData()
    generateDataForYTDChart()
  }, [myPerformanceDataArray])

  //use effect when the toggle value in YTD chart Update
  useEffect(() => {
    generateDataForYTDChart()
  }, [isToggledtoShowAfterExitQuarters])

  useEffect(() => {
    updateChartData(myPerformanceDataArray)
  }, [
    isQSPPastDataFetched,
    includeAppreciationToggleForLTD,
    includeAppreciationToggleForProjections,
    oppurtunityCostToggleForLTD,
    oppurtunityCostToggleForProjections,
  ])

  // fetch past asset values
  useEffect(() => {
    handleFetchPastInvestments()
  }, [])

  const formatDateHelper = (dateToformat: string) => {
    return moment(dateToformat).format('YYYY-MM-DD')
  }

  // fetching past investments from DB
  const handleFetchPastInvestments = async () => {
    try {
      setQSPDataLoading(true)
      const response = await fetchQSPPastInvestments(
        formatDateHelper(myPerformanceDataArray.customer_start_date),
        formatDateHelper(myPerformanceDataArray.quarter_end_date)
      )
      setCalculatedPastInvestments(response.data)

      const updatedAssets = [...QSPPastAssets]

      response.data.map((pastData: any) => {
        const index = QSPPastAssets.findIndex((data) => data.label === pastData.investment_name)
        if (index !== -1 && QSPPastAssets[index].id > 6) {
          updatedAssets[index] = {
            ...updatedAssets[index],
            value: pastData.annualizedROR,
            customLabel: `${pastData.totalROI}%`,
            annualizedROR: pastData.annualizedROR,
          }
        }
      })
      setQSPPastAssets(updatedAssets)
      setQSPDataLoading(false)
      setIsQSPPastDataFetched(true)
    } catch (error) {
      setQSPDataLoading(false)
      console.log('An error occured while fetching data from API')
    }
  }

  // format date in months format e.g. May 02 2023
  const formatDateInMonthHelper = (dateToformat: string) => {
    return moment(dateToformat).format('MMMM DD YYYY')
  }

  // find time interval
  const getTimeInterval = (startDate: string, endDate: string) => {
    const start = moment(startDate)
    const end = moment(endDate)

    // Calculate difference in years, months, and days
    let yearsDiff = end.diff(start, 'years', true)
    let monthsDiff = end.diff(start, 'months', true)
    let daysDiff = end.diff(start, 'days', true)

    // Determine the largest unit with a difference of at least 1
    if (yearsDiff >= 1) {
      return Math.round(yearsDiff) + ' years'
    } else if (monthsDiff >= 1) {
      return Math.round(monthsDiff) + ' months'
    } else {
      return Math.round(daysDiff) + ' days'
    }
  }

  // const compare quarters
  const compareQuarters = (prevQuarterValue: number, thisQuarterValue: number) => {
    const ratio = prevQuarterValue / thisQuarterValue
    let text = ''

    if (ratio < 0.8) {
      text = `this was ${toUSDWithoutRoundOff(
        Math.abs(thisQuarterValue - prevQuarterValue)
      )} lower. `
    } else if (ratio >= 0.8 && ratio < 0.95) {
      text = `this was just ${toUSDWithoutRoundOff(
        Math.abs(thisQuarterValue - prevQuarterValue)
      )} lower. `
    } else if (ratio >= 0.95 && ratio <= 1) {
      text = `this quarter was about the same. `
    } else if (ratio > 1 && ratio <= 1.1) {
      text = `this quarter grew by ${toUSDWithoutRoundOff(
        Math.abs(prevQuarterValue - thisQuarterValue)
      )}. `
    } else if (ratio > 1.1) {
      text = `this was greater by ${toUSDWithoutRoundOff(
        Math.abs(prevQuarterValue - thisQuarterValue)
      )}. `
    }

    return text
  }

  // algo to find custom investment to show
  const findCustomInvestmentToShow = (ARQBeforeExitROI: number, ARQAfterExitROI: number) => {
    const THRESHOLD = 2 // 2% threshold

    // store LTDCustomInvestmentsArray into an dummy array
    let customInvestments = [...LTDCustomInvestmentsArray]

    // insert the ARQ Before Exit ROI and sort
    customInvestments.push({
      id: customInvestments.length,
      custom_investment_name: 'ARQ Before Exit',
      custom_investment_value: ARQBeforeExitROI,
    })
    customInvestments.sort((a, b) => a.custom_investment_value - b.custom_investment_value)

    // find the index of the ARQ Before Exit ROI
    const arqBeforeIndex = customInvestments.findIndex(
      (investment) => investment.custom_investment_value === ARQBeforeExitROI
    )

    if (arqBeforeIndex > 0) {
      return {
        customInvestmentText: `For comparison, we did better than popular stocks like the ${
          customInvestments[arqBeforeIndex - 1].custom_investment_name
        } index fund – which in that same time grew ${
          customInvestments[arqBeforeIndex - 1].custom_investment_value
        }% per year. `,
        ARQReturnsText: 'lifetime cashflow',
      }
    }

    // if no value to the left of ARQ Before Exit, try ARQ After Exit
    // re initialize customInvestments array
    customInvestments = [...LTDCustomInvestmentsArray]
    customInvestments.push({
      id: customInvestments.length,
      custom_investment_name: 'ARQ After Exit',
      custom_investment_value: ARQAfterExitROI,
    })
    customInvestments.sort((a, b) => a.custom_investment_value - b.custom_investment_value)

    const arqAfterIndex = customInvestments.findIndex(
      (investment) => investment.custom_investment_value === ARQAfterExitROI
    )
    if (arqAfterIndex > 0) {
      return {
        customInvestmentText: `For comparison, we did better than popular stocks like the ${
          customInvestments[arqAfterIndex - 1].custom_investment_name
        } index fund – which in that same time grew ${
          customInvestments[arqAfterIndex - 1].custom_investment_value
        }% per year. `,
        ARQReturnsText: 'total returns',
      }
    }

    // find ARQ After Exit index
    const arqIndex = customInvestments.findIndex(
      (investment) => investment.custom_investment_name === 'ARQ After Exit'
    )
    let investmentRightOfArqAfter = customInvestments
      .slice(arqIndex + 1)
      .find(
        (investment) =>
          (Math.abs(investment.custom_investment_value - ARQAfterExitROI) / ARQAfterExitROI) *
            100 <=
          THRESHOLD
      )
    if (investmentRightOfArqAfter) {
      return {
        customInvestmentText: `For comparison, this is comparable to popular stocks like the ${
          customInvestments[arqIndex - 1].custom_investment_name
        } index fund – which in that same time grew ${
          customInvestments[arqIndex - 1].custom_investment_value
        }% per year. `,
        ARQReturnsText: 'total returns',
      }
    }

    // if no suitable investment is found
    return null
  }

  // send my performance email
  const handleSendMyPerformanceEmail = async (myPerformanceImage: string) => {
    const QTDActualBarIndex = 1
    const customInvestmentToShow = findCustomInvestmentToShow(
      chartData[2]?.[1]?.totalROR || 0,
      chartData[2]?.[1]?.totalROR || 0
    )
    try {
      await sendMyPerformanceEmail(
        myPerformanceDataArray.investment_name, // investment name
        myPerformanceDataArray.customer_name, // customer name
        formatDateInMonthHelper(myPerformanceDataArray.quarter_start_date), // quarter start date
        formatDateInMonthHelper(myPerformanceDataArray.quarter_end_date), // quarter end date
        getTimeInterval(
          myPerformanceDataArray.quarter_start_date,
          myPerformanceDataArray.quarter_end_date
        ), // time interval
        toUSDWithoutRoundOff(
          chartDataForYearToDateChart[chartDataForYearToDateChart.length - 1]?.rentalIncome || 0
        ), // YTD actual rental income
        toUSDWithoutRoundOff(
          chartDataForYearToDateChart[chartDataForYearToDateChart.length - 2]?.rentalIncome || 0
        ), // YTD previous quarter rental income
        compareQuarters(
          chartDataForYearToDateChart[chartDataForYearToDateChart.length - 1]?.rentalIncome || 0,
          chartDataForYearToDateChart[chartDataForYearToDateChart.length - 2]?.rentalIncome || 0
        ), // quarter difference
        +chartDataforLastQuarterChart[QTDActualBarIndex]?.ROR?.toFixed(2), // QTD annualized ROR
        myPerformanceDataArray.expected_ROR, // expected ROR
        moment(myPerformanceDataArray.quarter_end_date).format('YYYY'), // final quarter year
        chartData[2]?.[1]?.cashOnCash !== null ? +chartData[2]?.[1]?.cashOnCash?.toFixed(2) : 0, // LTD cash on cash -> after exit
        chartData[2]?.[1]?.annualROR !== null ? +chartData[2]?.[1]?.annualROR?.toFixed(2) : 0, // LTD cash on cash -> after exit
        toUSDWithoutRoundOff(myPerformanceDataArray.starting_amount), // starting amount
        customInvestmentToShow?.customInvestmentText,
        customInvestmentToShow?.ARQReturnsText,
        myPerformanceImage,
        myPerformancePageURL // my performance page URL
      )
      setIsSendingEmail(false)
    } catch (error) {
      console.log('An error occured while sending email')
      setIsSendingEmail(false)
    }
  }

  // Get the current year
  const currentYear = moment().year()
  // Calculate January 1st of the current year
  const januaryFirst = moment([currentYear, 0, 1])
  // Format the result as "Tue, 09 May 2023 19:00:00 GMT"
  const formattedDate = januaryFirst.format('ddd, DD MMM YYYY HH:mm:ss [GMT]')

  // calculating no. of years based on values
  // for QTD(quarter-start-date)
  const quarterStartDate = calculateDate(
    myPerformanceDataArray.quarter_start_date,
    myPerformanceDataArray.quarter_end_date
  )
  // for YTD(year-to-date)
  const yearToDate = calculateDate(formattedDate, myPerformanceDataArray.quarter_end_date) // formattedDate = 1st Jan of current year

  //for LTD(lifetime-to-date)
  const lifetimeToDate = calculateDate(
    myPerformanceDataArray.customer_start_date,
    myPerformanceDataArray.quarter_end_date
  )

  let currentDate = moment().format('MM-DD-YY')
  const timeFrameForProjectionsChart = moment()
    .add(myPerformanceDataArray?.years_to_grow, 'years')
    .format('MM-DD-YY')

  // handle compounding growth
  const handleCompoundingGrowth = (data: any) => {
    return data.filter((item: any) => {
      // Keep all items except ARQ
      return item.type === TYPES_OF_ROR.TYPE_SIMPLE
    })
  }

  // get chart data function
  function getChartData(
    data: MyPerformanceData,
    noOfYears: number,
    URLchartDataArray: number[],
    rentalIncomeThisQuarter: number,
    chartKey: number | null // it defines the time interval of chart
  ) {
    const _chartData = generateMyPerformanceChartData(
      data.starting_amount,
      data.contributions,
      data.contributions_frequency,
      noOfYears,
      chartKey === 2
        ? QSPPastAssets.filter((asset) => URLchartDataArray.includes(asset.id))
        : QSPAssets.filter((asset) => URLchartDataArray.includes(asset.id)),
      // assets,
      data.ARQ_Flip_Gain,
      rentalIncomeThisQuarter,
      data.ARQ_rental_frequency,
      data.ARQ_appreciation,
      data.ARQ_property_worth,
      data.management_fee,
      myPerformanceDataArray.is_management_fee_applied,
      data.actual_equity,
      myPerformanceDataArray.expected_ROR,
      myPerformanceDataArray.original_ROR,
      myPerformanceDataArray.rollover,
      myPerformanceDataArray.customer_actual,
      myPerformanceDataArray.new_starting_amount,
      myPerformanceDataArray.ARQ_Property_End_Value,
      moment(myPerformanceDataArray.quarter_payout_date, 'ddd, DD MMM YYYY HH:mm:ss z').format(
        'MM-DD-YY'
      ), // paid-out-on
      chartKey, // it defines the time interval of chart
      includeAppreciationToggleForLTD,
      includeAppreciationToggleForProjections,
      oppurtunityCostToggleForLTD,
      oppurtunityCostToggleForProjections,
      data.actual_annualized_ytd_ror_phase1And2,
      data.actual_rental_income_LTD,
      data.ARQ_rental_frequency,
      data.years_to_grow,
      data.customer_start_date,
      data.quarter_end_date
    )
    const filteredData = _chartData.map((entry) => ({
      ...entry,
      contributions: entry.type === TYPES_OF_ROR.TYPE_SIMPLE ? entry.contributions : 0,
      ARQ_Flip_Gain:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE || entry.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
          ? 0
          : entry.ARQ_Flip_Gain,
      ARQ_rental_income:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE || entry.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
          ? 0
          : entry.ARQ_rental_income,
      ARQ_appreciation:
        entry.type === TYPES_OF_ROR.TYPE_AFTER_EXIT || entry.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
          ? entry.ARQ_appreciation
          : 0,
      timeFrame:
        chartKey === 2
          ? `Time Frame: from ${moment(myPerformanceDataArray.customer_start_date).format(
              'MM-DD-YY'
            )} to ${moment(myPerformanceDataArray.quarter_end_date).format('MM-DD-YY')} (${(
              lifetimeToDate * 12
            ).toFixed(2)} months).`
          : `Time Frame: from ${currentDate} to ${timeFrameForProjectionsChart} (${(
              myPerformanceDataArray?.years_to_grow * 12
            ).toFixed(2)} months).`,
    }))

    const barsToBeFilterOnIds = URLchartDataArray
    const arrangedData = barsToBeFilterOnIds.map((index) => filteredData[index])
    return advisor_url
      ? arrangedData
      : isARQ_homeINURL
      ? filteredData
      : handleCompoundingGrowth(filteredData)
  }

  // update QTD(last quarter) data
  // ?ltd chart
  const updateQTDChartData = () => {
    //
    let annualized_expected_RoR = calculateAnnualizedExpectedROR(
      myPerformanceDataArray.expected_ROR,
      quarterStartDate
    )
    const months = Math.round(quarterStartDate * 12)
    let _timeFrame = `Time Frame: from ${moment(myPerformanceDataArray.quarter_start_date).format(
      'MM-DD-YY'
    )} to ${moment(myPerformanceDataArray.quarter_end_date).format('MM-DD-YY')} (${months} months).`

    const Equity =
      myPerformanceDataArray.actual_equity && myPerformanceDataArray.actual_equity !== 0
        ? myPerformanceDataArray.actual_equity / 100
        : calculatePropertyWorthPercentages_New(
            myPerformanceDataArray.ARQ_Flip_Gain,
            myPerformanceDataArray.ARQ_property_worth,
            myPerformanceDataArray.starting_amount,
            myPerformanceDataArray.rollover,
            myPerformanceDataArray.new_starting_amount
          )

    const rentalIncomeWithPropertyWorthPercentage =
      myPerformanceDataArray.ARQ_rental_income * Equity
    let initialDeposit =
      (myPerformanceDataArray.rollover
        ? myPerformanceDataArray.starting_amount
        : myPerformanceDataArray.new_starting_amount) - myPerformanceDataArray.ARQ_Flip_Gain
    // calculating CoC
    let annualized_actual_RoR =
      (myPerformanceDataArray.actual_rental_income_last_quarter / initialDeposit) * Equity
    let actualBarObj = calculateRentalIncomeLastQuarter(
      myPerformanceDataArray.starting_amount,
      myPerformanceDataArray.actual_rental_income_last_quarter,
      myPerformanceDataArray.actual_equity,
      myPerformanceDataArray.is_management_fee_applied,
      myPerformanceDataArray.management_fee
    )
    let _chartDataforFirstChart = [
      {
        ROR: myPerformanceDataArray.expected_ROR,
        annualized_ROR: annualized_expected_RoR * 100,
        dataValue: myPerformanceDataArray.rollover
          ? annualized_expected_RoR * initialDeposit
          : annualized_expected_RoR * initialDeposit,
        dataKey: 'Expected',
        timeFrame: _timeFrame,
      },
      {
        ROR: myPerformanceDataArray.actual_annualized_ytd_ror_phase1And2
          ? myPerformanceDataArray.actual_annualized_ytd_ror_phase1And2
          : myPerformanceDataArray.actual_annualized_ytd_ror,
        annualized_ROR: actualBarObj.cashOnCash,
        dataValue: actualBarObj.rentalIncomeLastQuarter,
        dataKey: 'Actual',
        timeFrame: _timeFrame,
      },
    ]
    setChartDataforLastQuarterChart(_chartDataforFirstChart)
  }

  // update chart data
  const chartDataArray: any = []
  const updateChartData = (values: MyPerformanceData) => {
    const URLchartDataArray = [
      myPerformanceDataArray.chart1,
      myPerformanceDataArray.chart2,
      myPerformanceDataArray.chart3,
      myPerformanceDataArray.chart4,
    ]
    const rentalIncomeIntervals = [
      myPerformanceDataArray.actual_rental_income_last_quarter,
      myPerformanceDataArray.actual_rental_income_YTD,
      myPerformanceDataArray.actual_rental_income_LTD,
      myPerformanceDataArray.ARQ_rental_income,
    ]

    const calculatedNoOfYears = [
      quarterStartDate, //QTD
      yearToDate, // YTD
      lifetimeToDate, //LTD
      myPerformanceDataArray.years_to_grow, //years-to-grow for projections chart
    ]

    // Call getChartData function four times
    for (let i = 0; i < 4; i++) {
      const _chartData = getChartData(
        values,
        calculatedNoOfYears[i],
        URLchartDataArray[i],
        rentalIncomeIntervals[i],
        i // chartKey -> it defines the time interval of chart
      ) // Adjust your arguments as needed

      chartDataArray.push(_chartData)

      // Set the chartData state with the chartDataArray
      setChartData(chartDataArray)
    }
  }

  //function to generate the YTD chart Data
  const generateDataForYTDChart = () => {
    const chartDataForYearToDateChart = generateDataForYearToDateChart(
      myPerformanceDataArray,
      chartDataArray[1] ? chartDataArray[1] : chartData[1],
      isToggledtoShowAfterExitQuarters
    )
    setChartDataForYearToDateChart(chartDataForYearToDateChart)
  }
  return {
    chartData,
    QSPAssets,
    setQSPAssets,
    QSPPastAssets,
    setQSPPastAssets,
    QSPDataLoading,
    isSendingEmail,
    setIsSendingEmail,
    setLTDCustomInvestmentsArray,
    chartDataforLastQuarterChart,
    chartDataForYearToDateChart,
    handleSendMyPerformanceEmail,
  }
}
