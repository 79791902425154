import {useState} from 'react'
import {COMPARE_PERFORMANCE_SECTION, sectionIds} from '../../../../constants/AppConstants'
import ComparePerformanceInputs from './components/ComparePerformanceInputs'
import {useComparePerformanceContext} from '../../../context/ComparePerformanceContext'
import ComparePerformanceModal from '../../../../../_metronic/layout/components/modal/ComparePerformanceModal'
import PastPerformanceChart from '../PastPerformance/components/PastPerformanceChart'
import FeedbackSection from '../feedbackSection/FeedbackSection'
const ComparePerformanceSection = () => {
  // modal state
  const [showModal, setShowModal] = useState(false)
  // destructure compare performance context
  const {chartData, isFormSubmitted} = useComparePerformanceContext()
  return (
    <>
      <section id={sectionIds[18]} className='pt-10 px-7 px-md-10 px-lg-20'>
        <h1 className='my-2 mb-6 text-center'> {COMPARE_PERFORMANCE_SECTION.HEADING} </h1>
        <p className='text-center'> {COMPARE_PERFORMANCE_SECTION.SUB_HEADING} </p>
        <ComparePerformanceInputs />
        {/* projections chart */}
        {isFormSubmitted ? (
          <div className='mt-5'>
            <PastPerformanceChart
              data={chartData}
              oldData={[]} // for past performance only
              handleCustomBar={() => console.log('handle custom bar')}
              handleReply={() => console.log('handle reply')}
            />
          </div>
        ) : null}
        <ComparePerformanceModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title={COMPARE_PERFORMANCE_SECTION.MODAL_TITLE}
        />
        <FeedbackSection sectionName='Compare Performance Section' />
      </section>
    </>
  )
}

export default ComparePerformanceSection
