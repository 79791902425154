import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import {toAbsoluteUrl} from '../../../../helpers'
import {HeaderUserMenu} from '../../../../partials/layout/header-menus/user/HeaderUserMenu'
import {useAuth} from '../../../../../app/modules/auth'
import {Link, useLocation} from 'react-router-dom'
import {NAVBAR_CONSTANTS} from '../../../../../app/constants/AppConstants'
import {Nav} from 'react-bootstrap'
import {getUserByEmail} from '../../../../../app/user/modules/profile/components/settings/_request'
import pubSub from '../../../../../app/user/modules/profile/EventEmitter/PubSub'
const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
interface NavbarProps {
  navLinks: NavLink[]
}
interface NavLink {
  path: string
  label: string
  scrollToId?: string // Optional, used for internal links that require scrolling
}
const NavbarComponent: React.FC<NavbarProps> = ({navLinks}) => {
  const {currentUser} = useAuth()
  const [localUser, setLocalUser] = useState<any>()
  const location = useLocation()
  const pathsToCheck = ['arq-mobile']
  const isArqDemo = pathsToCheck.some((path) => location.pathname.includes(path))
  const handleNavLinkClick = (scrollToId?: string) => {
    if (scrollToId) {
      const section = document.getElementById(scrollToId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'})
      }
      return false // Prevent default link behavior if we're doing a scroll
    }
    return true // Allow default link behavior
  }
  useEffect(() => {
    const unsubscribe = pubSub.subscribe('profileUpdated', (data: any) => {
      console.log('Profile updated!', data.user)
      setLocalUser(data.user)
    })

    return () => unsubscribe() // Cleanup subscription on unmount
  }, [])
  return (
    <Navbar bg='white' expand='lg' className='w-100'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Brand href='/'>
          <img alt='logo-image' className='chloee-logo' src={NAVBAR_CONSTANTS.IMAGES.LOGO} />
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            {navLinks.map((link, index) => (
              <Nav.Link
                key={index}
                className='nav-link nav-text'
                href={link.path}
                onClick={() => handleNavLinkClick(link.scrollToId)}
              >
                {link.label}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        {!isArqDemo && (
          <Navbar.Collapse id='basic-navbar-nav'>
            <div className='ms-auto'>
              {currentUser ? (
                <div className={clsx('app-navbar-item', itemClass)}>
                  <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                  >
                    <img
                      src={`${process.env.REACT_APP_ADVISOR_LOGO_PATH}${currentUser.profile_image}`}
                      alt=''
                    />
                  </div>
                  <HeaderUserMenu />
                </div>
              ) : (
                <div className='d-flex align-items-center'>
                  <div className={clsx('app-navbar-item', itemClass)}>
                    {/* Placeholder for other components if needed */}
                  </div>
                  {/* Navigate to auth/login */}
                  <Link to='/auth/login' className='secondary-btn ms-3'>
                    {NAVBAR_CONSTANTS.NAVLINK4}
                  </Link>
                </div>
              )}
            </div>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  )
}

export {NavbarComponent}
