import axios from 'axios'
const ADD_TO_INTEREST_LIST = 'interestlist/add-user'
const UPDATE_INTEREST_LIST = 'interestlist/update-user'

// Adding to interestList
export function addToInterestList(email: string, queryString: string | null) {
  return axios.post(ADD_TO_INTEREST_LIST, {
    email,
    queryString,
  })
}

// Updating interestList
export function updateInterestList(rowId: number, userDesc: string[]) {
  return axios.put(UPDATE_INTEREST_LIST, {
    rowId,
    userDesc,
  })
}
