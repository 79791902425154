import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useLocation} from 'react-router-dom'
import {IDataRow} from './IBLOCConfigs'
import useFocus from '../../../hooks/useFocus'
import {useState, useEffect} from 'react'
import {gapi} from 'gapi-script'
import {toast} from 'react-toastify'

interface IBLOCScenarioModalProps {
  show: boolean
  onHide: () => void
  setScenarioData: (data: any) => void
  onSubmit: (values: any) => void
  localStorageKey: string
  isEditableModal: boolean
  scenarioIdToEdit: number | undefined
}
interface IScenario {
  id: number
  value: any
  label: string
}

// form schema
const cardSchema = Yup.object().shape({
  scenarioLabel: Yup.string().required('Title is required'),
  isImportData: Yup.boolean(),
  googleSheetId: Yup.string().when('isImportData', {
    is: true,
    then: Yup.string().required('Sheet id is required'),
    otherwise: Yup.string().notRequired(),
  }),
  sheetRange: Yup.string().when('isImportData', {
    is: true,
    then: Yup.string().required('Range is required'),
    otherwise: Yup.string().notRequired(),
  }),
})

const getInitialValues = () => ({
  scenarioLabel: '',
  isImportData: false,
  googleSheetId: '',
  sheetRange: '',
})

const IBLOCScenarioModal: React.FC<IBLOCScenarioModalProps> = (props) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const initialValues = getInitialValues()

  const location = useLocation()
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )

  // const [isImportData, setIsImportData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {inputRef, focusInput} = useFocus()
  useEffect(() => {
    focusInput()
  }, [focusInput])

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLOUD_CLIENT_ID
  const API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY
  // const SPREADSHEET_ID = 11JuwxijcSaBYJK_IHSan5oAokC94uMEOABQLxsogbzc
  // const RANGE = 'WLIBLOC!A15:N' // the specific range you want to extract

  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
          scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
        })
        .then(() => {
          //
        })
    }

    gapi.load('client:auth2', initClient)
  }, [])

  const parseCurrency = (value: string): number => parseFloat(value.replace(/[\$,]/g, ''))

  const mapRowToData = (item: any): IDataRow => ({
    Year: parseInt(item[0]),
    Age: parseInt(item[1]),
    BaseContractPremium: parseCurrency(item[2]),
    FPRContractPremium: parseCurrency(item[3]),
    APPUAPremium: parseCurrency(item[4]),
    TotalPremium: parseCurrency(item[5]),
    TotalCashValue: parseCurrency(item[6]),
    IncreaseInTotalCashValue: parseCurrency(item[7]),
    TotalDeathBenefit: parseCurrency(item[8]),
    Dividend: parseCurrency(item[9]),
    DividendTotalCashValue: parseCurrency(item[10]),
    DividendIncreaseInTotalCashValue: parseCurrency(item[11]),
    DeathBenefitWithoutDividends: parseCurrency(item[12]),
    DividendTotalDeathBenefit: parseCurrency(item[13]),
    // Set default values or calculate values for the additional columns
    CashIn: 0, // Placeholder for calculation
    APR: 0, // Placeholder for calculation
    CashOut: 0, // Placeholder for calculation
    IBLOCBalance: 0, // Placeholder for calculation
    IBLOCBalanceforBar: 0, // Placeholder for calculation
    TotalCashValueCalculated: 0, // Placeholder for calculation
    CashBalance: 0, // Placeholder for calculation
  })

  const fetchSheetData = async () => {
    formik.setFieldValue('isImportData', true)
    setIsLoading(true)
    try {
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: formik.values?.googleSheetId,
        range: formik.values?.sheetRange,
      })
      const values = response.result.values || []
      const structuredData: IDataRow[] = values.slice(1).map(mapRowToData)

      handleAddNewScenario(structuredData)
      formik.handleSubmit()
      setIsLoading(false)

      console.log(values) // Log the entire data
    } catch (error) {
      notifyError('Error fetching sheet data')
      console.error('Error fetching sheet data:', error)
      setIsLoading(false)
    }
  }

  //    process data and store updated data in local storage
  const handlePasteAddScenario = async () => {
    // Attempt to read clipboard data
    if (navigator.clipboard) {
      try {
        const text = await navigator.clipboard.readText()

        processData(text)
      } catch (error) {
        console.error('Failed to read clipboard contents:', error)
        // Handle permissions or other errors gracefully
      }
    } else {
      console.error('Clipboard API not available.')
    }
  }

  const handleAddNewScenario = (newData: any) => {
    // Retrieve the existing scenarios from local storage and parse them
    const existingScenariosString = localStorage.getItem(props.localStorageKey)
    const existingScenarios: IScenario[] = existingScenariosString
      ? JSON.parse(existingScenariosString)
      : []

    if (!props?.isEditableModal) {
      const newEntryId = existingScenarios.length + 1

      const updatedData = {
        id: newEntryId,
        value: newData,
        label: formik.values.scenarioLabel,
      }

      // Add the new scenario to the existing scenarios array
      existingScenarios.push(updatedData)

      // Save the updated scenarios array back to local storage
      localStorage.setItem(props.localStorageKey, JSON.stringify(existingScenarios))
    } else {
      const updatedScenarios = existingScenarios.map((scenario) => {
        if (scenario.id === props?.scenarioIdToEdit) {
          return {...scenario, value: newData, label: formik.values.scenarioLabel}
        }
        return scenario
      })
      localStorage.setItem(props.localStorageKey, JSON.stringify(updatedScenarios))
    }
    props.setScenarioData(localStorage.getItem(props.localStorageKey))
  }

  const processData = (text: string): void => {
    const rows = text
      .trim()
      .split(/\r?\n/)
      .filter((row) => row.length)

    // Remove all spaces from headers
    const headersFromPaste = rows[0].split(/\t|,/).map((header) => header.replace(/\s+/g, ''))

    const toNumber = (value: string): number => {
      // Convert to float by default
      const num = parseFloat(value)
      return isNaN(num) ? 0 : num // Return 0 if the value is not a valid number
    }

    const convertValue = (header: string, value: string): number => {
      return toNumber(value) // Convert all values to numbers
    }

    const newData: Partial<IDataRow>[] = rows.slice(1).map((row) => {
      const values = row.split(/\t/).map((value) => value.replace(/[^0-9.]/g, ''))
      return headersFromPaste.reduce((obj: any, header, index) => {
        obj[header] = convertValue(header, values[index] || '')
        return obj
      }, {} as Partial<IDataRow>)
    })

    handleAddNewScenario(newData)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: cardSchema,
    onSubmit: async (values) => {
      if (!values?.isImportData) {
        handlePasteAddScenario()
      }
      props.onHide()
      formik.resetForm({})
    },
  })

  useEffect(() => {
    // reset form
    formik.setFieldValue('isImportData', false)
  }, [props?.show])

  return (
    <>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
          <Modal.Header closeButton>
            <div className='d-flex align-items-center'>
              <Modal.Title
                id='contained-modal-title-vcenter'
                className='heading sub-heading-x-small me-3'
              >
                {props?.isEditableModal ? 'Edit scenario' : 'Add scenario'}
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* scenario label */}
            <div className='input-container d-flex justify-content-center align-items-center'>
              <p className='m-0 currency-symbol d-none'>$</p>
              <input
                id='scenarioLabel'
                ref={formik.values.isImportData ? null : inputRef}
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Enter scenario name'
                {...formik.getFieldProps('scenarioLabel')}
                maxLength={18}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault() // Prevent the default action to avoid form submission if inside form
                    formik.handleSubmit()
                  }
                }}
              />
            </div>
            {formik.touched.scenarioLabel && formik.errors.scenarioLabel ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.scenarioLabel}</div>
              </div>
            ) : null}

            {formik.values.isImportData ? (
              <>
                {/* Google sheet id */}
                <div className='input-container d-flex justify-content-center align-items-center mt-3'>
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <input
                    id='googleSheetId'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter google sheet id'
                    {...formik.getFieldProps('googleSheetId')}
                  />
                </div>
                {formik.touched.googleSheetId && formik.errors.googleSheetId ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.googleSheetId}</div>
                  </div>
                ) : null}

                {/* Sheet Range */}
                <div className='input-container d-flex justify-content-center align-items-center mt-3'>
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <input
                    id='sheetRange'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter sheet range'
                    {...formik.getFieldProps('sheetRange')}
                  />
                </div>
                {formik.touched.sheetRange && formik.errors.sheetRange ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.sheetRange}</div>
                  </div>
                ) : null}
              </>
            ) : null}
            <Modal.Footer>
              {/* import link */}
              {!formik?.values?.isImportData ? (
                <>
                  <p
                    role='button'
                    className='pink-text paragraph-small text-decoration-underline'
                    onClick={() => formik.setFieldValue('isImportData', true)}
                  >
                    Import
                  </p>
                  <p> or </p>

                  {/* paste button */}
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    style={{
                      backgroundColor: isWayStoneStyledInUrl ? '#B86746' : '',
                    }}
                  >
                    <span
                      className='indicator-progress'
                      style={{
                        display: 'block',
                      }}
                    >
                      Paste
                    </span>
                  </button>
                </>
              ) : null}
              {/* import button */}
              {formik?.values?.isImportData ? (
                <button
                  onClick={() => fetchSheetData()}
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-primary'
                  disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                  style={{
                    backgroundColor: isWayStoneStyledInUrl ? '#B86746' : '',
                  }}
                >
                  {!isLoading && <span className='indicator-label'>Import</span>}
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Importing data...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              ) : null}
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
    </>
  )
}
export default IBLOCScenarioModal
