import React from 'react'
import PropertyCard from './PropertyCard'

interface Property {
  id: number
  imageUrl: string
  value: string
}

interface PropertyListProps {
  properties: Property[]
}

const PropertyList: React.FC<PropertyListProps> = ({properties}) => {
  return (
    <div className='property-list'>
      {properties.map((property) => (
        <PropertyCard key={property.id} imageUrl={property.imageUrl} value={property.value} />
      ))}
    </div>
  )
}

export default PropertyList
