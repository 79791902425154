import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {CompoundingGrowth} from './_model'

export const defaultAsset: number = 2

export const assets: CompoundingGrowth[] = [
  {
    id: 0,
    label: 'Cash under Mattress',
    dropdownLabel: '(-2.6% ) Cash under Mattress',
    customLabel: '-2.6%',
    color: '#9A258E', //color of label
    value: -2.6,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 1,
    label: 'Cash in Bank Savings',
    dropdownLabel: '(0.06%) Cash in Bank Savings',
    customLabel: '0.06%',
    color: '#9A258E',
    value: 0.06,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 2,
    label: 'High Yield Savings',
    dropdownLabel: '(4.5%) High Yield Savings',
    customLabel: '4.5%',
    color: '#9A258E',
    value: 4.5,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 3,
    label: 'Stocks (S&P)',
    dropdownLabel: '(6%) Stocks (S&P)',
    customLabel: '6%',
    color: '#9A258E',
    value: 6,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 4,
    label: 'Ethereum',
    dropdownLabel: '(20%) Ethereum',
    customLabel: '20%*',
    color: '#9A258E',
    value: 20,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 5,
    label: 'ARQ Before Exit',
    dropdownLabel: 'ARQ Before Exit',
    customLabel: '14%',
    color: '#9A258E',
    value: 14,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
  },
  {
    id: 6,
    label: 'ARQ After Exit',
    dropdownLabel: 'ARQ After Exit',
    customLabel: '10%',
    color: '#9A258E',
    value: 10,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
  },
  {
    id: 7,
    label: 'Custom',
    dropdownLabel: null,
    customLabel: '',
    color: '#9A258E',
    value: 0.0000001,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
]

export const contributionAndDividendsFrequency = [
  {
    id: 1,
    label: 'Weekly',
    value: 52,
  },
  {
    id: 2,
    label: 'Bi-Monthly',
    value: 24,
  },
  {
    id: 3,
    label: 'Monthly',
    value: 12,
  },
  {
    id: 4,
    label: 'Quarterly',
    value: 4,
  },
  {
    id: 5,
    label: 'Semi-Annually',
    value: 2,
  },
  {
    id: 6,
    label: 'Yearly',
    value: 1,
  },
]

export const projectionsColors = {
  loss: '#ff318c',
  gain: '#feb7ff',
  contributions: '#d80ed8',
  initialDeposit: '#7c1e72',
  white: '#ffffff',
  borrow: '#f0568d',
  gainOnReInvestment: '#db69d7',
}

export const typesOfRoR = [
  {id: 0, type: TYPES_OF_ROR.TYPE_SIMPLE},
  {id: 1, type: TYPES_OF_ROR.TYPE_ARQ_FLIP},
  {id: 2, type: TYPES_OF_ROR.TYPE_ARQ_RENTAL},
  {id: 3, type: TYPES_OF_ROR.TYPE_BEFORE_EXIT},
  {id: 4, type: TYPES_OF_ROR.TYPE_AFTER_EXIT},
]
