import {memo, useEffect, useRef, useState} from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'
import IBLOCTable from '../../IBLOC/Components/IBLOCTable'
import IBLOCTooltip from './IBLOCTooltip'
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Text,
  YAxis,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  ReferenceArea,
  Rectangle,
  ReferenceLine,
} from 'recharts'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import {projectionsColors} from '../../PastPerformance/components/PastPerformanceConfig'
import {IBLOCChartProps} from '../../../helper/SBLOC/_model'
import {Button} from 'react-bootstrap'
import {downloadChart} from '../../../helper/charts.helper'
import {handleSBLOCActions} from '../../../advisor/core/_request'
import {useSBLOCContext} from '../../../../context/SBLOCContext'
import React from 'react'
import {IBLOCLineGraphColors} from '../../projections/components/ProjectionsConfig'
import IBLOCLineChartTooltip from './IBLOCLineChartTooltip'
const SBLOCChart: React.FC<IBLOCChartProps> = (props) => {
  const {parentUserId, compareScenarioChartData} = useSBLOCContext()

  let {data: initialData, handleAddComparisonChart} = props
  const [data, setData] = useState(initialData)
  const [isLineGraph, setIsLineGraph] = useState(false)
  const [isLevargeView, setIsLevargeView] = useState(false)
  const [editInputs, setEditInputs] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [isToolTips, setIsToolTips] = useState(false)
  const [barsToAnimate, setBarsToAnimate] = useState(true)
  const [yearToBeEdit, setYearToBeEdit] = useState<number[]>([])
  const [referenceBarLabel, setReferenceBarLabel] = useState<number[]>([])
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [justDragged, setJustDragged] = useState<boolean>(false)
  const screenWidth = useScreenWidth()
  const [combinedData, setCombinedData] = useState<any>({})
  const [isCashBalanceToggle, setIsCashBalanceToggle] = useState<boolean>(true)
  const [isTotalCashValueToggle, setIsTotalCashValueToggle] = useState<boolean>(false)
  const [isIBLOCBalanceToggle, setIsIBLOCBalanceToggle] = useState<boolean>(false)
  const handleModalValues = (values: any) => {
    props.editYear(values, data)
  }
  const chartContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Scroll to the bottom of the chat container when a new message comes in
    if (chartContainerRef.current) {
      chartContainerRef.current.scrollTop = chartContainerRef.current.scrollHeight
    }
  }, [compareScenarioChartData]) // The effect runs whenever `messages` changes

  useEffect(() => {
    setData(initialData)
  }, [isLevargeView, initialData])

  useEffect(() => {
    setBarsToAnimate(true)
    setReferenceBarLabel([])
    setYearToBeEdit([])
  }, [data])
  // calculating chart height
  let chartPadding: any
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 40, right: 30, left: -30}
      : {
          top: props.isWayStoneStyled ? 20 : 80,
          bottom: props.isWayStoneStyled ? 50 : 60,
          right: 40,
          left: 10,
        }

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width, chartData} = props
    const futureValue = chartData[index].CashBalance || '0'
    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))

    return (
      <Text
        x={!isLineGraph ? x + width / 2 : x}
        y={!isLineGraph ? (screenWidth > 767 ? y - 35 : y - 15) : y - 10}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={chartData?.length <= 10 ? 0 : 270}
        //onClick={onClickLabel}
      >
        {gain}
      </Text>
    )
  }

  // custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, index} = props
    let date = new Date()
    return (
      <>
        <Text
          x={x}
          y={y + labelPadding}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          angle={labelTextAngle}
          className='custom-tick-label'
        >
          {data?.[index]?.Year}
        </Text>
      </>
    )
  }
  useEffect(() => {
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    })
  }, [data])
  const hightLightEditableBars = (payload: any, index: number) => {
    // Check if the year is already included in the yearToBeEdit array
    if (yearToBeEdit.includes(payload.Year) || referenceBarLabel.includes(payload.Year)) {
      // If it is included, remove it
      const newYearToBeEdit = yearToBeEdit.filter((year) => year !== payload.Year)
      const newReferenceLabels = referenceBarLabel.filter((year) => year !== payload.Year)
      setYearToBeEdit(newYearToBeEdit)
      setReferenceBarLabel(newReferenceLabels)
    } else {
      // If it's not included, add it
      setYearToBeEdit([...yearToBeEdit, payload.Year])
      setReferenceBarLabel([...referenceBarLabel, data[index].Year])
    }
  }

  const handleBarOnClick = (payload: any, index: number) => {
    setBarsToAnimate(false)
    if (!referenceBarLabel.includes(data[index].Year) && !showTable) {
      setReferenceBarLabel([data[index].Year])
      setYearToBeEdit([data[index].Year])
    }
    if (showTable) {
      hightLightEditableBars(payload, index)
    } else {
      setEditInputs(true)
    }
  }
  const handleEditAbleIndexFromTable = (selectedIndexes: any, isDragging: boolean) => {
    setBarsToAnimate(false)
    setJustDragged(false)
    if (selectedIndexes.length > 1) {
      let years: any = []
      selectedIndexes.forEach((item: any) => {
        years.push(data[item].Year)
      })
      setYearToBeEdit([...selectedIndexes])
      setReferenceBarLabel(selectedIndexes)
    } else {
      if (yearToBeEdit.includes(selectedIndexes) && !isDragging) {
        // If it is included, remove it
        const newYearToBeEdit = yearToBeEdit.filter((year) => year !== selectedIndexes)
        const newReferenceLabels = referenceBarLabel.filter((year) => year !== selectedIndexes)
        setYearToBeEdit(newYearToBeEdit)
        setReferenceBarLabel(newReferenceLabels)
      } else {
        // If it's not included, add it
        setYearToBeEdit([...yearToBeEdit, selectedIndexes])
        setReferenceBarLabel([...referenceBarLabel, selectedIndexes])
      }
    }
  }
  const handleIconClick = () => {
    const getLinkButton = document.getElementById('getLinkButton')
    if (getLinkButton) {
      getLinkButton.click()
    }
  }
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = (e: React.MouseEvent) => {
    setReferenceBarLabel([])
    setIsDragging(true)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }
  // Define the type for the keys you expect in the radiusConfig
  // Define the type for the keys you expect in the radiusConfig
  type DataKey =
    | 'CashIn'
    | 'TotalCashValueCalculated'
    | 'CashBalance'
    | 'IBLOCBalanceforBar'
    | 'futureValue'
  // Define the type for the radius array
  type RadiusArray = number[]
  function determineRadius(dataKey: DataKey, value: number): RadiusArray {
    const radiusConfig: Record<DataKey, RadiusArray> = {
      CashIn: value > 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      TotalCashValueCalculated: value < 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      IBLOCBalanceforBar: value > 0 ? [5, 5, 0, 0] : [0, 0, 5, 5],
      CashBalance: value > 0 ? [5, 5, 0, 0] : [0, 0, 0, 0],
      futureValue: value > 0 ? [5, 5, 0, 0] : [0, 0, 0, 0],
    }
    return radiusConfig[dataKey] || [0, 0, 0, 0]
  }
  const CustomBar = (props: any) => {
    const {fill, x, y, width, height, payload} = props
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <>
        <Rectangle
          {...props}
          fill={fill}
          stroke='transparent' // Border color
          strokeWidth={1} // Border width
          radius={determineRadius(
            props?.tooltipPayload[0]?.dataKey,
            payload?.[props?.tooltipPayload[0]?.dataKey]
          )}
        />
      </>
    )
  }
  function toggleNumberInArray(array: number[], num: number): number[] {
    const index = array.indexOf(num)

    if (index === -1) {
      return [...array, num]
    } else {
      return array.filter((item) => item !== num)
    }
  }
  const handleDragStart = () => {
    setIsDragging(true)
    setReferenceBarLabel([])
    setYearToBeEdit([])
  }

  const handleDragEnd = () => {
    let DefaultRoiToCalculate = 7 / 100
    setIsDragging(false)
    let cashInSum = 0
    let cashOutSum = 0
    let CashInSP = 0
    for (let i = 0; i < referenceBarLabel.length; i++) {
      cashInSum = cashInSum + data[referenceBarLabel[i]].CashIn
      cashOutSum = cashOutSum + data[referenceBarLabel[i]].CashOut
      CashInSP = (data[referenceBarLabel[i]].CashIn + CashInSP) * (1 + DefaultRoiToCalculate)
    }
    let RoiOnCash =
      data[referenceBarLabel[referenceBarLabel.length - 1]]?.TotalCashValueCalculated -
      data[referenceBarLabel[0]]?.TotalCashValueCalculated

    let CalculatedRoiOnCash = ((RoiOnCash - cashInSum) / RoiOnCash) * 100
    let RoiOnSPCash = ((CashInSP - cashInSum) / cashInSum) * 100
    setCombinedData({
      cashInSum,
      cashOutSum,
      CalculatedRoiOnCash,
      CashInSP,
      RoiOnSPCash,
    })
    setJustDragged(true)
  }

  const handleDrag = (index: number) => {
    if (isDragging) {
      setBarsToAnimate(false)
      setReferenceBarLabel((prev) => toggleNumberInArray(prev, index))
      setYearToBeEdit((prev) => toggleNumberInArray(prev, index))
    }
  }
  const handleTouchMove = (e: React.TouchEvent) => {
    if (isDragging) {
      const touch = e.touches[0]
      const element = document.elementFromPoint(touch.clientX, touch.clientY)
      if (element && element.getAttribute('data-index')) {
        const index = parseInt(element.getAttribute('data-index')!, 10)
        handleDrag(index)
      }
    }
  }
  // Example usage:
  const iconStyle = {
    fontSize: '1.5rem', // Make the icons bigger
    color: '#7c1e72', // Dark purple color
    cursor: 'pointer',
    marginBottom: '1rem', // Add spacing between icons
  }

  const combined_data = compareScenarioChartData[0].value.map((entry: any, index: number) => {
    let combinedEntry: any = {Year: entry?.Year}

    compareScenarioChartData.forEach((scenario: any, scenarioIndex: number) => {
      const scenarioData = scenario.value[index]

      // Iterate over all keys in the scenario data object
      Object.keys(scenarioData).forEach((key) => {
        // Construct the dynamic key name for combinedEntry
        combinedEntry[`Scenario${scenarioIndex + 1}_${key}`] = scenarioData[key]
      })
    })

    return combinedEntry
  })

  return (
    <>
      <div
        className={`${
          props.isWayStoneStyled ? '' : 'pt-20 pt-8 pt-7'
        }px-md-10 px-lg-20 user-select-none`}
      >
        {!props.isWayStoneStyled && (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              backgroundColor: props.isWayStoneStyled ? '#d0cfcd' : 'white',
            }}
          >
            <>
              <div className='p-3 px-5 d-none d-md-block'>
                <Button className='custom-primary-button' onClick={() => props.undo()}>
                  Undo
                </Button>
                <Button
                  className='mx-5 custom-primary-button'
                  onClick={() => downloadChart('ibloc-chart-container', 'Vivid Projection.png')}
                >
                  Download
                </Button>
              </div>
              <div className='p-3 px-5 d-none d-md-block'></div>
            </>
          </div>
        )}
        {/* chart */}
        {!isLoading && (
          <div
            id='ibloc-chart-container'
            ref={chartContainerRef}
            className={`${
              compareScenarioChartData.length > 1 && !isLineGraph ? 'ibloc-chart-container' : ''
            }`}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd} // Stop dragging if the mouse leaves the chart
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
            onTouchMove={handleTouchMove} // Handle touch move event
          >
            <>
              {/* bar chart */}
              {compareScenarioChartData.map((chartData: any, _index: number) => (
                <div key={_index} className='bg-white'>
                  {/* Assuming `data.label` contains the title for each chart */}
                  {!isLineGraph && (
                    <>
                      {/* Display the title */}
                      <p className='m-0 ms-4'>
                        {compareScenarioChartData.length > 1 ? chartData?.label : ''}
                      </p>{' '}
                      <ResponsiveContainer width='100%' height={380}>
                        <BarChart
                          margin={chartPadding}
                          data={compareScenarioChartData[_index]?.value}
                          style={{backgroundColor: props.isWayStoneStyled ? '#d0cfcd' : 'white'}}
                          barCategoryGap={isLevargeView ? '10%' : '20%'} // Adjust these values as needed
                          barGap={0} // Adjust this value to bring bars closer together
                        >
                          <XAxis
                            dataKey='year'
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            width={100}
                            minTickGap={5}
                            tick={<CustomizedAxisTick />}
                          />
                          {referenceBarLabel?.map((line, index) => (
                            <ReferenceArea
                              x1={line} // Starting year (ensure it matches an actual data point)
                              x2={line} // Ending year (ensure it matches an actual data point)
                              stroke='green'
                              fill='green'
                              fillOpacity={0.3}
                              yAxisId='left'
                            />
                          ))}

                          {props?.data?.map((line: any, index: any) => (
                            <>
                              <ReferenceArea
                                key={`data-reference-${index}`} // Adding a key prop for better performance
                                x1={index} // Starting year (ensure it matches an actual data point)
                                x2={index} // Ending year (ensure it matches an actual data point)
                                stroke='transparent'
                                fill='transparent'
                                fillOpacity={0.3}
                                yAxisId='left'
                                onMouseEnter={() => handleDrag(index)}
                                onTouchMove={handleTouchMove}
                                data-index={index}
                              />
                            </>
                          ))}

                          <YAxis
                            yAxisId='left'
                            allowDataOverflow={true}
                            tick={false}
                            axisLine={false}
                            tickLine={false}
                            domain={[
                              (dataMin: number) => 0 - Math.abs(dataMin),
                              (dataMax: number) => dataMax * 1.2, // Increase dataMax by 10%
                            ]}
                          />
                          {/* tooltip */}
                          {isToolTips && (
                            <Tooltip
                              contentStyle={{backgroundColor: 'white'}}
                              content={
                                <IBLOCTooltip
                                  totalIndices={referenceBarLabel}
                                  dragged={
                                    justDragged && referenceBarLabel.length > 1 ? true : false
                                  }
                                  combinedDataOfDraggedSelectedBars={combinedData}
                                />
                              }
                            />
                          )}
                          {/* red */}
                          {!isLevargeView ? (
                            <>
                              <Bar
                                dataKey='CashIn'
                                stackId='assets'
                                barSize={70}
                                yAxisId='left'
                                fill={projectionsColors.initialDeposit}
                                isAnimationActive={barsToAnimate}
                                shape={<CustomBar />} // Custom shape
                              />
                              <Bar
                                //light pink
                                barSize={70}
                                dataKey='TotalCashValueCalculated'
                                stackId='assets'
                                fill={projectionsColors.gain}
                                yAxisId='left'
                                isAnimationActive={barsToAnimate}
                                shape={<CustomBar />} // Custom shape
                              ></Bar>
                              {/* purple */}
                              <Bar
                                dataKey='CashBalance'
                                stackId='assets'
                                barSize={70}
                                yAxisId='left'
                                fill={projectionsColors.gainOnReInvestment}
                                isAnimationActive={barsToAnimate}
                                shape={<CustomBar />} // Custom shape
                              >
                                {compareScenarioChartData[_index]?.value?.length <= 40 ? (
                                  <LabelList
                                    content={<CustomizedBarTopLabel chartData={chartData?.value} />}
                                    position='top'
                                    key={new Date().getTime()}
                                  />
                                ) : null}
                              </Bar>
                              <Bar
                                barSize={70}
                                dataKey={'IBLOCBalanceforBar'}
                                stackId='assets'
                                fill={projectionsColors.borrow}
                                yAxisId='left'
                                isAnimationActive={barsToAnimate}
                                shape={<CustomBar />} // Custom shape
                              ></Bar>
                            </>
                          ) : null}
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                </div>
              ))}

              {/* line chart */}
              {/* {compareScenarioChartData.map((chartData: any, _index: number) => ( */}
              <div key={0} className='bg-white'>
                {/* Assuming `data.label` contains the title for each chart */}
                {isLineGraph && (
                  <>
                    {/* Display the title */}
                    <p className='m-0 ms-4'>
                      {/* {compareScenarioChartData.length > 1 ? chartData?.label : ''} */}
                    </p>{' '}
                    <ResponsiveContainer width='100%' height={380}>
                      <LineChart
                        width={800}
                        height={250}
                        data={combined_data}
                        margin={{top: 20, right: 30, left: 10, bottom: 5}}
                        style={{backgroundColor: 'white'}}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='Year' />
                        <YAxis tickFormatter={toAbbreviatedNumber} />

                        {isToolTips || justDragged ? (
                          <Tooltip
                            contentStyle={{backgroundColor: 'white'}}
                            content={
                              // <IBLOCTooltip
                              //     totalIndices={referenceBarLabel}
                              //     dragged={
                              //       justDragged && referenceBarLabel.length > 1 ? true : false
                              //     }
                              //     combinedDataOfDraggedSelectedBars={combinedData}
                              //   />
                              <IBLOCLineChartTooltip
                                isToolTips={isToolTips}
                                compareScenarioChartData={compareScenarioChartData}
                              />
                            }
                          />
                        ) : null}

                        <Legend />

                        {compareScenarioChartData.map((scenario: any, index: number) => (
                          <React.Fragment key={scenario.id}>
                            {isTotalCashValueToggle && (
                              <Line
                                type='monotone'
                                dataKey={`Scenario${index + 1}_TotalCashValue`}
                                stroke={IBLOCLineGraphColors.totalCashValue[index]}
                                strokeWidth={4}
                                name={`${scenario.label} - Total Cash Value`}
                              />
                            )}
                            {isIBLOCBalanceToggle && (
                              <Line
                                type='monotone'
                                dataKey={`Scenario${index + 1}_IBLOCBalance`}
                                stroke={IBLOCLineGraphColors.IBLOCBalance[index]}
                                strokeWidth={4}
                                name={`${scenario.label} - Balance`}
                              />
                            )}
                            {isCashBalanceToggle && (
                              <Line
                                type='monotone'
                                dataKey={`Scenario${index + 1}_CashBalance`}
                                stroke={IBLOCLineGraphColors.cashBalance[index]}
                                strokeWidth={1}
                                name={`${scenario.label} - Cash Balance`}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </>
                )}
              </div>
              {/* ))} */}
            </>

            {props.isWayStoneStyled && (
              <div className='d-flex flex-column align-items-center ps-6'>
                <i
                  className='fas fa-undo'
                  style={iconStyle}
                  onClick={() => {
                    props.undo()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-download'
                  style={iconStyle}
                  onClick={() => {
                    downloadChart('sbloc-chart-container', 'Vivid Projection.png')
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-cog'
                  style={iconStyle}
                  onClick={() => {
                    setEditInputs(true)
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-share'
                  style={iconStyle}
                  onClick={() => {
                    handleIconClick()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
              </div>
            )}
          </div>
        )}

        <div className='container my-10'>
          <div className='row justify-content-center'>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault1'
                  onClick={() => {
                    setShowTable(!showTable)
                    !showTable && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault1'
                >
                  Show Table
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault2'
                  onClick={() => {
                    setIsLineGraph(!isLineGraph)
                    !isLineGraph && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault2'
                >
                  Line Graph
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault3'
                  onClick={() => {
                    setIsToolTips(!isToolTips)
                    !isToolTips && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault3'
                >
                  Rollovers
                </label>
              </div>
            </div>
          </div>
          {/* line chart bars toggles */}
          {isLineGraph && (
            <div className='row m-0 justify-content-center mt-8'>
              <div className='col-6 col-lg-3'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault1'
                    defaultChecked={isCashBalanceToggle}
                    onClick={() => setIsCashBalanceToggle(!isCashBalanceToggle)}
                  />
                  <label
                    className='sliding-toggle-label form-check-label ms-2'
                    htmlFor='flexSwitchCheckDefault1'
                  >
                    Cash Balance
                  </label>
                </div>
              </div>
              <div className='col-6 col-lg-3'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault1'
                    defaultChecked={isIBLOCBalanceToggle}
                    onClick={() => setIsIBLOCBalanceToggle(!isIBLOCBalanceToggle)}
                  />
                  <label
                    className='sliding-toggle-label form-check-label ms-2'
                    htmlFor='flexSwitchCheckDefault1'
                  >
                    IBLOC Balance
                  </label>
                </div>
              </div>
              <div className='col-6 col-lg-3'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault1'
                    defaultChecked={isTotalCashValueToggle}
                    onClick={() => setIsTotalCashValueToggle(!isTotalCashValueToggle)}
                  />
                  <label
                    className='sliding-toggle-label form-check-label ms-2'
                    htmlFor='flexSwitchCheckDefault1'
                  >
                    Total Cash Value
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showTable ? (
        <div className={`IBLOC-table-container ${isToolTips ? 'mt-100' : ''} px-md-10 px-lg-20`}>
          <IBLOCTable
            tableData={props.tableData}
            handleUpdateChartData={props?.handleUpdateChartData}
            // tableData={compareScenarioTableData[selectedChartIndex]?.value}
            onEditYear={props.editYearForTable}
            yearToBeEdit={yearToBeEdit}
            editableIndex={handleEditAbleIndexFromTable}
            firstRowInputs={props.handleChangeFirstRowInputs}
            undoTable={props.undo}
            handleAddComparisonChart={handleAddComparisonChart}
          />
        </div>
      ) : null}
    </>
  )
}

export default memo(SBLOCChart)
