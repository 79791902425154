import {BEGINNERS_SECTION, sectionIds} from '../../../constants/AppConstants'
import {useLocation} from 'react-router-dom'
import FeedbackSection from './feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import InterestListButton from '../../pages/about/components/InterestListButton'
interface BeginnersSectionProps {
  subHeading: string
  paragraph: string
  secondParagraph: string
}

const BeginnersSection = (props: BeginnersSectionProps) => {
  const location = useLocation()
  const isAboutPage = location.pathname.includes('about')
  const currentHostname = window.location.hostname
  const currentPathname = window.location.pathname
  const {subHeading, paragraph, secondParagraph} = props
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  return (
    <div
      className={
        sectionIdToShowInWebViewer !== sectionIds[7] && showInWebViewer
          ? 'd-none overflow-hidden'
          : 'overflow-hidden'
      }
      style={{width: '100%'}}
    >
      <section className='position-relative z-index-1  pt-5 pt-lg-15' id={sectionIds[7]}>
        <h1 className='my-2 mb-4 mb-md-5 mb-lg-10 text-center'>
          {' '}
          {isAboutPage ? BEGINNERS_SECTION.WE_DO_FOR_ABOUT_PAGE : BEGINNERS_SECTION.WE_DO}{' '}
        </h1>
        <div className='px-7 px-md-10 px-lg-20'>
          <p className='text-center mb-1'>{subHeading}</p>
          <br></br>
          <div className='d-flex justify-content-center align-items-center'>
            <p className='text-center mission-text'>{paragraph}</p>
          </div>
          {currentPathname === '/about' ? (
            <div className='d-flex justify-content-center align-items-center'>
              <p className='text-center mission-text mb-0'>{secondParagraph}</p>
            </div>
          ) : null}

          <img
            className='cloud-big d-none d-md-block'
            src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
            alt='cloud-big'
          />
          <img
            className='cloud-small'
            src={BEGINNERS_SECTION.IMAGES.CLOUD_SMALL}
            alt='cloud-small'
          />
          <div className='row m-0 mt-7'>
            <div className='col-lg-5'>
              {/* {currentPathname !== '/about' && (
                <p className='text-center text-xl-start'>
                  {currentHostname === 'vividli.ai'
                    ? BEGINNERS_SECTION.VIVIDLI_MONOLITH_HELP
                    : BEGINNERS_SECTION.MONOLITH_HELP}
                </p>
              )} */}
            </div>
            {/* our values section desktop view */}
            <div className='d-none d-lg-block col-lg-7 our-values-container'>
              <h2 className='our-values-text position-absolute z-index-1'>
                {BEGINNERS_SECTION.OUR_VALUES}
              </h2>
              <img
                className={`position-absolute watering-plants-img ${
                  currentPathname === '/about' ? 'mt-20' : ''
                }`}
                src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
                alt='cloud-big'
              />
              <img
                className={`position-absolute our-values-img ${
                  currentPathname === '/about' ? 'mt-20' : ''
                }`}
                src={BEGINNERS_SECTION.IMAGES.VALUES_BAG}
                alt='cloud-big'
              />
            </div>
          </div>
          {/* our values section mobile view */}
          <div className='d-flex d-lg-none flex-column justify-content-center align-items-center'>
            <img
              className='watering-plants-img'
              src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
              alt='cloud-big'
            />
            <h2 className='our-values-text text-center mt-4 mt-lg-6'>
              {BEGINNERS_SECTION.OUR_VALUES}
            </h2>
            <img
              className='our-values-img'
              src={BEGINNERS_SECTION.IMAGES.VALUES_BAG}
              alt='cloud-big'
            />
          </div>

          {/* feedback section */}
          {currentPathname !== '/about' && (
            <div className={showInWebViewer ? 'd-none' : ''}>
              <FeedbackSection sectionName='beginners section' />
            </div>
          )}
        </div>
      </section>
      {window.location.pathname === '/about' && <InterestListButton />}
    </div>
  )
}

export default BeginnersSection
