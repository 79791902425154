import ProjectionsChart from '../projections/components/ProjectionsChart'
import {useMyPerformanceDataState} from './components/MyPerformanceUtils'
import {MY_PERFORMANCE_SECTION} from '../../../../constants/AppConstants'
import YTDProjectionsChart from './components/YTDProjectionsChart'
import moment from 'moment'
import {usePastPerformanceContext} from '../../../context/PastPerformanceContext'
import {formatDateHelper} from '../../helper/calculator/MyPerformanceCalculator'
import TimeFrameSelector from './TimeFrameSelector'
import {useState} from 'react'
// interface PerformanceComponentProps {
//   currentChartId: number
// }
const MyPerformanceComponent: React.FC = () => {
  // extracting chart data from useMyPerformanceDataState
  const {chartData, chartDataforLastQuarterChart, chartDataForYearToDateChart} =
    useMyPerformanceDataState()
  const {
    myPerformanceDataArray,
    setIsToggledtoShowAfterExitQuarters,
    setIncludeAppreciationToggleForLTD,
    setIncludeAppreciationToggleForProjections,
    setOppurtunityCostToggleForLTD,
    setOppurtunityCostToggleForProjections,
  } = usePastPerformanceContext()
  const [currentChartId, setCurrentChartId] = useState<number>(0)
  const [currentChartName, setCurrentChartName] = useState<string>('QTD')
  // get the current year
  const currentYear = moment().year()
  // calculate January 1st of the current year
  const januaryFirst = moment([currentYear, 0, 1])

  // helper function for rendering chart title
  const renderTitles = (index: number) => {
    const januaryFirstDate = new Date(januaryFirst.toString())
    const quarterEndDate = new Date(myPerformanceDataArray.quarter_end_date)
    let dateRangeDisplay

    if (januaryFirstDate > quarterEndDate) {
      // If January 1st is later than the quarter end date, swap the order
      dateRangeDisplay = `From ${formatDateHelper(
        myPerformanceDataArray.quarter_end_date
      )} to ${formatDateHelper(januaryFirst.toString())}`
    } else {
      // Otherwise, keep the original order
      dateRangeDisplay = `From ${formatDateHelper(januaryFirst.toString())} to ${formatDateHelper(
        myPerformanceDataArray.quarter_end_date
      )}`
    }

    if (index === 0)
      return (
        <>
          {' '}
          <p className='sub-heading-small-black mb-0 pt-5'>{MY_PERFORMANCE_SECTION.LAST_QUARTER}</p>
          <p className='gray-text'>{`From ${formatDateHelper(
            myPerformanceDataArray.quarter_start_date
          )} to ${formatDateHelper(myPerformanceDataArray.quarter_end_date)}`}</p>
          <p className='sub-heading'>{`You got ${chartDataforLastQuarterChart[1]?.ROR.toFixed(
            2
          )}% (annualized) returns`}</p>
        </>
      )
    if (index === 1)
      return (
        <>
          <p className='sub-heading-small-black mb-0 pt-5'>{`Year ${
            chartDataForYearToDateChart[0]?.startDate.split('-')[0]
          }`}</p>
          <p className='gray-text'>
            {`From ${formatDateHelper(
              chartDataForYearToDateChart[0]?.startDate
            )} to ${formatDateHelper(
              chartDataForYearToDateChart[chartDataForYearToDateChart.length - 1]?.endDate
            )}`}
          </p>
          <p className='sub-heading'>{`You got ${chartDataForYearToDateChart[
            chartDataForYearToDateChart.length - 1
          ]?.annualROR?.toFixed(2)}% (annualized) returns`}</p>
        </>
      )
    if (index === 2)
      return (
        <>
          <p className='sub-heading-small-black mb-0 pt-5'>
            {MY_PERFORMANCE_SECTION.LIFE_TIME_TO_DATE}
          </p>
          <p className='gray-text'>{`From ${formatDateHelper(
            myPerformanceDataArray.customer_start_date
          )} to ${formatDateHelper(myPerformanceDataArray.quarter_end_date)}`}</p>
          <p className='sub-heading hide'>HiddenText</p>
        </>
      )
    if (index === 3)
      return (
        // <>
        //   <h1 className='pt-5'>{MY_PERFORMANCE_SECTION.Projections_TITLE}</h1>
        //   <p className='sub-heading-small-black'>{MY_PERFORMANCE_SECTION.NEXT_2_YEARS}</p>
        // </>
        <>
          <p className='sub-heading-small-black mb-0 pt-5'>
            {MY_PERFORMANCE_SECTION.Projections_TITLE}
          </p>
          <p className='gray-text'>For next 2 years</p>
          <p className='sub-heading hide'>HiddenText</p>
        </>
      )
  }

  // handle toggle helper functions
  // ? include equity toggle for YTD chart
  const handleEquityToggle = () => {
    setIsToggledtoShowAfterExitQuarters((prev) => !prev)
  }
  // include appreciation toggle for LTD chart
  const handleAppreciationToggleForLTD = () => {
    setIncludeAppreciationToggleForLTD((prev) => !prev)
  }
  // include oppurtunity cost toggle for LTD chart
  const handleOppurtunityCostToggleForLTD = () => {
    setOppurtunityCostToggleForLTD((prev) => !prev)
  }
  // include appreciation toggle for projections chart
  const handleAppreciationToggleForProjections = () => {
    setIncludeAppreciationToggleForProjections((prev) => !prev)
  }
  // include oppurtunity cost toggle for projections chart
  const handleOppurtunityCostToggleForProjections = () => {
    setOppurtunityCostToggleForProjections((prev) => !prev)
  }
  const handleTimeFrameSelection = (index: number, value: string) => {
    setCurrentChartId(index)
    setCurrentChartName(value)
  }
  return (
    <>
      <section className='px-7 px-md-10 px-lg-15 mt-15'>
        <h1 className='text-center'>{MY_PERFORMANCE_SECTION.TITLE}</h1>

        {currentChartId === 4 ? (
          <div>
            {chartData.map((_, index) => (
              <div className='py-5' key={index}>
                <div className='text-center mt-20'>{renderTitles(index)}</div>

                {index === 1 ? (
                  <>
                    <YTDProjectionsChart
                      data={chartDataForYearToDateChart}
                      handleCustomBar={null}
                      handleReply={null}
                      handleBarRateOfreturn={null}
                    />
                    {/* <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id={`flexSwitchCheckDefault-${index}`}
                        onChange={handleEquityToggle}
                      />
                      <label
                        className='sliding-toggle-label form-check-label ms-2'
                        htmlFor={`flexSwitchCheckDefault-${index}`}
                      >
                        Including equity
                      </label>
                    </div> */}
                  </>
                ) : (
                  <>
                    {index < 4 ? (
                      <div>
                        <ProjectionsChart
                          data={index === 0 ? chartDataforLastQuarterChart : chartData[index]}
                          handleCustomBar={() => console.log('handle custom bar')}
                          handleReply={() => console.log('handle reply')}
                          handleBarRateOfreturn={() => console.log('handle bar ROR')}
                        />
                        {index === 2 || index === 3 ? (
                          <div className='d-flex flex-column flex-lg-row align-items-start justify-content-lg-end'>
                            <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center me-5'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id={`appreciationToggle-${index}`}
                                defaultChecked={true}
                                onChange={
                                  index === 2
                                    ? handleAppreciationToggleForLTD
                                    : handleAppreciationToggleForProjections
                                }
                              />
                              <label
                                className='sliding-toggle-label form-check-label ms-2'
                                htmlFor={`appreciationToggle-${index}`}
                              >
                                Include property appreciation
                              </label>
                            </div>
                            <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id={`opportunityCostToggle-${index}`}
                                defaultChecked={true}
                                onChange={
                                  index === 2
                                    ? handleOppurtunityCostToggleForLTD
                                    : handleOppurtunityCostToggleForProjections
                                }
                              />
                              <label
                                className='sliding-toggle-label form-check-label ms-2'
                                htmlFor={`opportunityCostToggle-${index}`}
                              >
                                Show opportunity cost of phase 1 profit
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            ))}
            <div className='py-10'>
              <TimeFrameSelector
                onTimeFrameSelected={handleTimeFrameSelection}
                currentChartName={currentChartName}
              />
            </div>
          </div>
        ) : currentChartId === 1 ? (
          <div className='parent-div mt-10'>
            <div className='text-center'>{renderTitles(currentChartId)}</div>
            <YTDProjectionsChart
              data={chartDataForYearToDateChart}
              handleCustomBar={null}
              handleReply={null}
              handleBarRateOfreturn={null}
            />
            <div className='py-10'>
              <TimeFrameSelector
                onTimeFrameSelected={handleTimeFrameSelection}
                currentChartName={currentChartName}
              />
            </div>
            {/* <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center pb-10 px-5'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id={`flexSwitchCheckDefault-${currentChartId}`}
                onChange={handleEquityToggle}
              />
              <label
                className='sliding-toggle-label form-check-label ms-2'
                htmlFor={`flexSwitchCheckDefault-${currentChartId}`}
              >
                Including equity
              </label>
            </div> */}
          </div>
        ) : (
          <div className='parent-div mt-10'>
            {chartData.map((_, index) => (
              <div className='' key={index}>
                {index === 1 || index !== currentChartId ? null : (
                  <>
                    <div className={`text-center`}>{renderTitles(index)}</div>
                    {index < 4 ? (
                      <div>
                        <ProjectionsChart
                          data={index === 0 ? chartDataforLastQuarterChart : chartData[index]}
                          handleCustomBar={() => console.log('handle custom bar')}
                          handleReply={() => console.log('handle reply')}
                          handleBarRateOfreturn={() => console.log('handle bar ROR')}
                        />
                        <div className='py-10'>
                          <TimeFrameSelector
                            onTimeFrameSelected={handleTimeFrameSelection}
                            currentChartName={currentChartName}
                          />
                        </div>
                        {index === 2 || index === 3 ? (
                          <div className='d-flex flex-column flex-lg-row align-items-start justify-content-lg-end py-10 px-5'>
                            <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center me-5'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id={`appreciationToggle-${index}`}
                                defaultChecked={true}
                                onChange={
                                  index === 2
                                    ? handleAppreciationToggleForLTD
                                    : handleAppreciationToggleForProjections
                                }
                              />
                              <label
                                className='sliding-toggle-label form-check-label ms-2'
                                htmlFor={`appreciationToggle-${index}`}
                              >
                                Include property appreciation
                              </label>
                            </div>
                            <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id={`opportunityCostToggle-${index}`}
                                defaultChecked={true}
                                onChange={
                                  index === 2
                                    ? handleOppurtunityCostToggleForLTD
                                    : handleOppurtunityCostToggleForProjections
                                }
                              />
                              <label
                                className='sliding-toggle-label form-check-label ms-2'
                                htmlFor={`opportunityCostToggle-${index}`}
                              >
                                Show opportunity cost of phase 1 profit
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </section>
    </>
  )
}

export default MyPerformanceComponent
