import {NavLink} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ChloeeHeader = () => {
  return (
    <>
      <nav className='navbar navbar-expand-lg bg-light'>
        <div className='container'>
          {/* <NavLink className='navbar-brand chloee-nav-brand-text chloee-gradient-text' to='#'>
            Chloee
          </NavLink> */}
          <img
            alt='chloee-img'
            className='chloee-logo'
            src={toAbsoluteUrl('/media/home/chloee-logo.svg')}
          />
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <NavLink
                  className={({isActive}) => 'nav-link chloee-nav-text' + (isActive ? '' : '')}
                  aria-current='page'
                  to='#'
                >
                  Contact us
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className={({isActive}) => 'nav-link chloee-nav-text' + (isActive ? '' : '')}
                  to='#'
                >
                  Features
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className={({isActive}) => 'nav-link chloee-nav-text' + (isActive ? '' : '')}
                  to='#'
                >
                  Pricing
                </NavLink>
              </li>
            </ul>
            {/* right side links */}
            <ul className='navbar-nav mb-2 mb-lg-0 d-flex align-items-lg-center'>
              <li className='nav-item'>
                <NavLink
                  className={({isActive}) => 'nav-link chloee-nav-text' + (isActive ? '' : '')}
                  to='#'
                >
                  Sign Up
                </NavLink>
              </li>
              <div className='chloee-secondary-btn ms-3'>Request for free</div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default ChloeeHeader
