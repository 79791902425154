import {useRef} from 'react'
import {FINANCIAL_SECTION, sectionIds} from '../../../constants/AppConstants'
import {motion, useTransform, useScroll} from 'framer-motion'
import {Footer} from '../../../../_metronic/layout/components/footer/user/Footer'
import {useReceiverFormContext} from '../../context/ReceiverContext'

interface FinanciallyFreeSectionProps {
  heading: string
  subHeading: string
  paragraph1: string
  paragraph2: string
  paragraph3: string
  paragraph4?: string
  isAboutPage?: boolean
}

const FinanciallyFreeSection = (props: FinanciallyFreeSectionProps) => {
  const {
    heading,
    subHeading,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    isAboutPage = false,
  } = props
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const birdAnimationType1X = useTransform(scrollYProgress, [0, 0.6], ['-200%', '5%'])
  const birdAnimationType1Y = useTransform(scrollYProgress, [0, 0.6], ['60%', '5%'])
  const birdAnimationType2X = useTransform(scrollYProgress, [0, 0.6], ['-200%', '10%'])
  const birdAnimationType2Y = useTransform(scrollYProgress, [0, 0.6], ['-60%', '10%'])

  const pinkBirdLeftX = useTransform(scrollYProgress, [0.1, 0.58], ['-50%', '10%'])
  const pinkBirdLeftY = useTransform(scrollYProgress, [0.1, 0.58], ['80%', '10%'])

  const pinkBirdRightX = useTransform(scrollYProgress, [0, 0.5], ['-50%', '10%'])
  const pinkBirdRightY = useTransform(scrollYProgress, [0, 0.5], ['60%', '10%'])

  const cloudMidX = useTransform(scrollYProgress, [0, 0.6], ['-30%', '30%'])
  const cloudBottomX = useTransform(scrollYProgress, [0.1, 0.45], ['-60%', '20%'])

  const cloudTopX = useTransform(scrollYProgress, [0, 1], ['-30%', '20%'])

  return (
    <div
      className={
        sectionIdToShowInWebViewer !== sectionIds[10] && showInWebViewer
          ? 'd-none overflow-hidden'
          : 'overflow-hidden'
      }
      style={{width: '100%'}}
    >
      <motion.section ref={targetRef} id={sectionIds[10]}>
        {!isAboutPage && (
          <div className='position-relative pt-20'>
            <div
              className='d-flex justify-content-center align-items-center px-10'
              id='FinancialFreeHeader'
            >
              {/* <h1 className={`my-2 mb-6 text-center`}>{heading}</h1> */}
            </div>
            <div className=' px-7 px-md-10 px-lg-20'>
              <p className='sub-heading-small position-relative z-index-1 text-center mt-20 mb-0'>
                {subHeading}
              </p>

              <div className='position-relative z-index-1 d-flex flex-column justify-content-center align-items-center'>
                <p className='text-center mt-20 mb-0 financial-freedom-text'>{paragraph1}</p>
                <p className='text-center my-13 financial-peace-text'>{paragraph2}</p>
                <p className='text-center mb-0 financial-mission-text'>{paragraph3}</p>
                {paragraph4 && (
                  <p className='text-center mb-0 financial-abundance-text'>{paragraph4}</p>
                )}
              </div>

              {/* white cloud-left */}
              <motion.img
                alt='financial-cloud-white'
                className='financial-cloud-white position-absolute'
                src={FINANCIAL_SECTION.IMAGES.CLOUD_WHITE}
                style={{x: cloudTopX}}
              />
              {/* fade cloud-right */}
              <motion.img
                alt='fi-fade-cloud-right-small'
                className='fi-fade-cloud-right-small position-absolute'
                src={FINANCIAL_SECTION.IMAGES.FADE_CLOUD_RIGHT_SMALL}
                style={{x: cloudMidX}}
              />
              {/* fade cloud-right-big */}
              <motion.img
                alt='fi-fade-cloud-right-large'
                className='fi-fade-cloud-right-large position-absolute d-none d-lg-block'
                src={FINANCIAL_SECTION.IMAGES.FADE_CLOUD_RIGHT_LARGE}
                style={{x: cloudBottomX}}
              />
              {/* red bird-left */}
              <motion.img
                alt='red-bird'
                className='red-bird-left position-absolute z-index-2'
                src={FINANCIAL_SECTION.IMAGES.RED_BIRD_LEFT}
                style={{x: pinkBirdLeftX, y: pinkBirdLeftY}}
              />
              {/* red bird-right */}
              <motion.img
                alt='red-bird'
                className='red-bird-right position-absolute'
                src={FINANCIAL_SECTION.IMAGES.RED_BIRD_RIGHT}
                style={{x: pinkBirdRightX, y: pinkBirdRightY}}
              />
              {/* line-right */}
              <img
                alt='line-right'
                className='line-right position-absolute d-none d-xl-flex'
                src={FINANCIAL_SECTION.IMAGES.LINE_RIGHT}
              />
              {/* line-left */}
              <img
                alt='line-left'
                className='line-left position-absolute d-none d-xl-flex'
                src={FINANCIAL_SECTION.IMAGES.LINE_LEFT}
              />
              {/* birds images */}
              {/* bird 1 */}
              <motion.img
                alt='bird-1'
                className='bird-1 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD1}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird 2 */}
              <motion.img
                alt='bird-2'
                className='bird-2 position-absolute d-none d-lg-block'
                src={FINANCIAL_SECTION.IMAGES.BIRD2}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird 3 */}
              <motion.img
                alt='bird-3'
                className='bird-3 position-absolute d-none d-lg-block'
                src={FINANCIAL_SECTION.IMAGES.BIRD3}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird 4 */}
              <motion.img
                alt='bird-4'
                className='bird-4 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD4}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird 5 */}
              <motion.img
                alt='bird-5'
                className='bird-5 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD5}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird  6 */}
              <motion.img
                alt='bird-6'
                className='bird-6 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD6}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  7 */}
              <motion.img
                alt='bird-7'
                className='bird-7 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD7}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  8 */}
              <motion.img
                alt='bird-8'
                className='bird-8 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD8}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  9 */}
              <motion.img
                alt='bird-9'
                className='bird-9 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD9}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  10 */}
              <motion.img
                alt='bird-10'
                className='bird-10 position-absolute d-none d-md-block'
                src={FINANCIAL_SECTION.IMAGES.BIRD10}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird  11 */}
              <motion.img
                alt='bird-11'
                className='bird-11 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD11}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  12 */}
              <motion.img
                alt='bird-12'
                className='bird-12 position-absolute d-none d-md-block'
                src={FINANCIAL_SECTION.IMAGES.BIRD12}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird  13 */}
              <motion.img
                alt='bird-13'
                className='bird-13 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD13}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird  14 */}
              <motion.img
                alt='bird-14'
                className='bird-14 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD14}
                style={{x: birdAnimationType1X, y: birdAnimationType1Y}}
              />
              {/* bird  15 */}
              <motion.img
                alt='bird-15'
                className='bird-15 position-absolute'
                src={FINANCIAL_SECTION.IMAGES.BIRD15}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
              {/* bird  16 */}
              <motion.img
                alt='bird-16'
                className='bird-16 position-absolute d-none d-lg-block'
                src={FINANCIAL_SECTION.IMAGES.BIRD16}
                style={{x: birdAnimationType2X, y: birdAnimationType2Y}}
              />
            </div>
          </div>
        )}

        <footer className='footer-container'>
          <Footer />
        </footer>
      </motion.section>
    </div>
  )
}

export default FinanciallyFreeSection
