import {useEffect, memo} from 'react'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../../components/projectionSlider/ProjectionSlider'
import {useCreditCardDept} from '../../../utils/creditCardDeptUtils'
import {CreditCardDeptChartData, CreditCardDeptInputsProps} from './_model'

const CreditCardInputs: React.FC<CreditCardDeptInputsProps> = (props) => {
  const {isFirstRender, initialValues, handleKeyPress, formik, generateData} = useCreditCardDept()
  let CreditCardDeptChartData: CreditCardDeptChartData[] = []

  useEffect(() => {
    if (isFirstRender.current) {
      CreditCardDeptChartData = generateData(initialValues)
      isFirstRender.current = false
      props.onCreditCardDeptFomSubmit(CreditCardDeptChartData)
      return
    }

    // Validate the form
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        formik.handleSubmit()
        // If there are no validation errors, proceed with data generation and submission
        CreditCardDeptChartData = generateData(formik.values)
        props.onCreditCardDeptFomSubmit(CreditCardDeptChartData)
      }
    })
  }, [formik.values])

  return (
    <>
      <div>
        <div>
          {/* Projections form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='projection-form'>
            <div className='row m-0'>
              {/* Card Balance and Asset Rate Of Return fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Card Balance</p>
                  {/* <CustomOverlayTooltip
                    content={INPUT_BOX_TOOLTIPS.STARTING_AMOUNT}
                    className='tooltip-logo'
                  /> */}
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.cardBalance && formik.errors.cardBalance ? 'error-border' : ''
                  }`}
                >
                  <CustomInputField
                    id='cardBalance'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.cardBalance}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('cardBalance', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.cardBalance}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.cardBalance}
                  maxValue={100000}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('cardBalance', parseInt(value!))
                  }
                />
              </div>
              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Card's APR</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.cardAPR && formik.errors.cardAPR ? 'error-border' : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='cardAPR'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('cardAPR')}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.cardAPR}
                  minValue={1}
                  maxValue={100}
                  isMoney={false}
                  onRangeChange={(value: any) => formik.setFieldValue('cardAPR', parseInt(value!))}
                />
              </div>

              {/* Months to Grow */}
              <div className='d-none d-md-block col-5 col-sm-5 col-md-2 fv-row mb-3 mb-lg-0 pl-3'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Months to Grow</p>
                  {/* <CustomOverlayTooltip
                    content={INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                    className='tooltip-logo'
                  /> */}
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.numberOfMonths && formik.errors.numberOfMonths
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <input
                    id='numberOfMonths'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('numberOfMonths')}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.numberOfMonths}
                  minValue={1}
                  maxValue={36}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('numberOfMonths', parseInt(value!))
                  }
                />
              </div>

              {/* Error messages */}
              <div className='text-center mt-2'>
                {formik.touched.cardBalance && formik.errors.cardBalance ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.cardBalance}</div>
                  </div>
                ) : null}
                {formik.touched.cardAPR && formik.errors.cardAPR ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.cardAPR}</div>
                  </div>
                ) : null}
                {formik.touched.numberOfMonths && formik.errors.numberOfMonths ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.numberOfMonths}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(CreditCardInputs)
