import ChloeeHeader from '../../modules/chloeeComponents/ChloeeHeader'
import ChloeeHeroSection from '../../modules/chloeeComponents/ChloeeHeroSection'

const ChloeeGPTPage = () => {
  return (
    <>
      <ChloeeHeader />
      <section className='container chloee-container d-flex align-items-lg-center px-7 pt-20'>
        <ChloeeHeroSection />
      </section>
    </>
  )
}

export {ChloeeGPTPage}
