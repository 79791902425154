import {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  assets,
  defaultAsset,
} from '../../../../app/user/modules/homeComponents/projections/components/ProjectionsConfig'
import {useReceiverFormContext} from '../../../../app/user/context/ReceiverContext'

interface CustomBarModalProps {
  show: boolean
  onHide: () => void
  title: string
}

// form schema
const customBarSchema = Yup.object().shape({
  barName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(25, 'Maximum 25 characters')
    .required('Investment name is required'),
  barValue: Yup.number()
    .min(-50, 'Value cannot be less than -50%')
    .max(100, 'Value cannot be greater than 100%')
    .required('Rate of return value is required'),
})

const initialValues = {
  barName: '',
  barValue: '',
}

const CustomBarModal: React.FC<CustomBarModalProps> = (props) => {
  const {setReceiverFormDataObj} = useReceiverFormContext()
  const [isEditing, setIsEditing] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: customBarSchema,
    onSubmit: async (values, {setFieldValue}) => {
      //to show Edit instead of Add in button
      setIsEditing(true)
      // extracting last index of assets array(assuming that last index is always a custom bar)
      const customIndex = assets.filter((item) => item.type === 'Simple ROR')
      const customAssetIndex = assets.length - 1
      assets[customAssetIndex].label = values.barName
      assets[customAssetIndex].dropdownLabel = `(${values.barValue}%) ${values.barName}`
      assets[customAssetIndex].customLabel = `${values.barValue}%`
      assets[customAssetIndex].value = parseFloat(values.barValue)

      // updating receiver advisor form context state
      setReceiverFormDataObj((prevFormData) => ({
        ...prevFormData,
        barName: values.barName,
        barValue: parseInt(values.barValue),
        selected_rate_of_return_obj: assets[assets.length - 1],
      }))
    },
  })

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* custom bar form */}
        <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
          <div className='row m-0'>
            <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Investment name</p>
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.barName && formik.errors.barName ? 'error-border' : ''
                }`}
              >
                <input
                  id='bar-name'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g. Real estate'
                  {...formik.getFieldProps('barName')}
                />
              </div>

              {formik.touched.barName && formik.errors.barName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.barName}</div>
                </div>
              )}
            </div>
            <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Rate of Return</p>
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.barValue && formik.errors.barValue ? 'error-border' : ''
                }`}
              >
                <p className='m-0 currency-symbol'>%</p>
                <input
                  id='bar-value'
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g 10'
                  {...formik.getFieldProps('barValue')}
                />
              </div>
              {formik.touched.barValue && formik.errors.barValue && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.barValue}</div>
                </div>
              )}
            </div>
          </div>
          <Modal.Footer>
            {/* <Button className='custom-primary-button' onClick={props.onHide}>
          CLOSE
        </Button> */}
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              onClick={props.onHide}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                {isEditing ? 'Edit' : 'Add'}
              </span>
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CustomBarModal
