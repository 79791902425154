import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'

// download charts
export const downloadCharts = () => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById('charts-container')

  if (chartContainer) {
    // set the desired width
    const desiredWidth = 700

    // calculate the scale based on the desired width
    const scale = desiredWidth / chartContainer.offsetWidth

    toPng(chartContainer, {
      cacheBust: false,
      width: desiredWidth, // Set the width directly to the desired width
      height: chartContainer.offsetHeight * scale, // Adjust height to maintain aspect ratio
      backgroundColor: 'white', // Set the background color to white
      style: {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      },
    })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-arq-performance-monthyear.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}
