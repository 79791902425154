import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ChloeeHeroSection = () => {
  return (
    <section className='row m-0 mt-8 mt-md-12 mt-lg-0'>
      <div className='col-12 col-lg-6 d-flex flex-column justify-content-center'>
        <p className='chloee-you-should-text chloee-gradient-text mb-0'>You Should</p>
        <p className='chloee-punch-line-text mb-10'>Connect with your friends and chat for free</p>
        <p className='chloee-chat-text mb-10'>
          We make it easy and fun to stay close to your favorite people and chat with them 24/7 for
          free!
        </p>
        <div className='chloee-primary-btn'>Get Started</div>
      </div>
      <div className='col-12 col-lg-6'>
        <img
          alt='chloee-img'
          className='chloee-hero-img '
          src={toAbsoluteUrl('/media/home/chloee-dummy-img.png')}
        />
      </div>
    </section>
  )
}

export default ChloeeHeroSection

// d-flex flex-column justify-content-center
