import React, {useEffect, useRef, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import clipboardCopy from 'clipboard-copy'
import {Button, Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {toUSDWithoutRoundOff} from '../../../helper/charts.helper'
import {SBLOC_TABLE, sectionIds} from '../../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import EditableCell from '../../SBLOC/Components/EditableCell'
import {
  CashInConfigs,
  CashOutConfigs,
  StressTestConfigs,
  TotalCashPutInSoFarConfigs,
} from '../../projections/components/ProjectionsConfig'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IBLOCDefaultData, IBLOCDefaultData2, IDataRow} from './IBLOCConfigs'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import IBLOCDropDown from './IBLOCDropDown'
import IBLOCScenarioModal from './IBLOCScenarioModal'
import CustomDropDown from './CustomDropDown'
import {useSBLOCContext} from '../../../../context/SBLOCContext'
import CustomOverlayTooltip from '../../projections/components/CustomOverlayTooltip'

const IBLOCTable: React.FC<{
  tableData: any[]
  onEditYear: (data: any) => void
  yearToBeEdit: number[]
  editableIndex: (index: any, isDragging: boolean) => void
  firstRowInputs: (newValue: any, keyId: any) => void
  undoTable: () => void
  handleAddComparisonChart: () => void
  handleUpdateChartData: (newData: any) => void
}> = ({
  tableData,
  onEditYear,
  yearToBeEdit,
  editableIndex,
  firstRowInputs,
  undoTable,
  handleAddComparisonChart,
  handleUpdateChartData,
}) => {
  const {
    isFullView,
    setIsFullView,
    compareScenarioTitle,
    compareScenarioChartData,
    setCompareScenarioTitle,
    setCompareScenarioChartData,
    setSelectedChartIndex,
    compareScenarioTableData,
    setCompareScenarioTableData,
    defaultOpportunityCostROI,
    setDefaultOpportunityCostROI,
  } = useSBLOCContext()
  const [isLoading, setIsLoading] = useState(false)
  // const [highlightedRowId, setHighlightedRowId] = useState(yearToBeEdit)
  const [scenarioData, setScenarioData] = useState<any>([])
  const [showScenarioModal, setShowScenarioModal] = useState(false)
  const [isEditableModal, setIsEditableModal] = useState(false)
  const [scenarioIdToEdit, setScenarioIdToEdit] = useState<number>()
  const customName1 = localStorage.getItem('CustomName1')
  const customName2 = localStorage.getItem('CustomName2')
  const isFirstRender = useRef(true)
  if (customName1) {
    StressTestConfigs[StressTestConfigs.length - 2].label = customName1
  }
  if (customName2) {
    StressTestConfigs[StressTestConfigs.length - 1].label = customName2
  }
  const location = useLocation()
  const isSblocPage = location.pathname.slice(1) === 'sblocPage'
  const screenWidth = useScreenWidth()
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()
  const [copyMessage, setCopyMessage] = useState<string | null>(null)
  const isWaystoneInURL =
    location.search.includes('waystone') || location.pathname.includes('waystone')
  const [selectedRows, setSelectedRows] = useState(new Set<number>())
  const [isDragging, setIsDragging] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showAllColumns, setShowAllColumns] = useState(false)
  let dragged = isDragging
  const [default_ROR, setDefault_ROR] = useState<string[]>([])
  const [default_APR, setDefault_APR] = useState<string[]>([])
  const [default_ReInvest_ROR, setDefault_ReInvest_ROR] = useState<string[]>([])
  useEffect(() => {
    const fetchInitialRor = async () => {
      try {
        let default_ROR = []
        let default_APR = []
        let default_ReInvest_ROR = []
        for (let i = 0; i < tableData.length; i++) {
          default_ROR.push(tableData[i].ROR)
          default_APR.push(tableData[i].APR)
          default_ReInvest_ROR.push(tableData[i].reInvested_ror)
        }
        setDefault_ROR(default_ROR)
        setDefault_APR(default_APR)
        setDefault_ReInvest_ROR(default_ReInvest_ROR)
      } catch (error) {
        console.error('Error fetching the CSV:', error)
      }
    }
    if (isFirstRender.current) {
      fetchInitialRor()
      isFirstRender.current = false
    }
    // onEditYear(tableData)
  }, [])

  const handleMouseDown = () => {
    setIsDragging(true)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseUp = () => {
    setIsDragging(false) // Stop dragging
    dragged = false
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const toggleRowSelection = (index: number, isShift: boolean) => {
    const newSelection = new Set(selectedRows)
    if (newSelection.has(index) && activeIndex !== index) {
      newSelection.delete(index)
      editableIndex(index, false)
      setActiveIndex(index)
    } else {
      newSelection.add(index)
      setActiveIndex(index)
      editableIndex(index, dragged)
    }
    if (isShift) {
      handleRowClicked(index, isShift)
    }
    setSelectedRows(newSelection)
  }
  const getMatchingIndexes = () => {
    const matchingIndexes: any = []

    // Loop through each year in yearToBeEdit
    yearToBeEdit.forEach((year) => {
      // Find each index in tableData where the year matches
      tableData.forEach((data, index) => {
        if (data.Year === year) {
          matchingIndexes.push(index) // Store the index if the year matches
        }
      })
    })

    return matchingIndexes
  }
  const handleCellChange = (rowIndex: number, keyId: string, newValue: string) => {
    const newData = [...tableData] // Clone the current table data
    const numericValue = parseFloat(newValue.replace(/[^0-9.-]+/g, '')) // Convert the new value to a numeric type, filtering out non-numeric characters

    // If the editableIndex array has more than one index, update all specified rows
    if (rowIndex === 0) {
      firstRowInputs(newValue, keyId)
    }
    if (newData[rowIndex][keyId] !== numericValue) {
      if (yearToBeEdit.length > 1) {
        const matchingIndexes = getMatchingIndexes()
        matchingIndexes.forEach((dataTableRowIndex: number) => {
          newData[dataTableRowIndex][keyId] = isNaN(numericValue) ? 0 : numericValue
        })
      } else {
        newData[rowIndex][keyId] = isNaN(numericValue) ? 0 : numericValue
      }
      onEditYear(newData) // Call the function to handle the updated data
    }
  }
  function getDefaultArrayToUndo(keyToUpdate: string) {
    if (keyToUpdate === 'RoR') {
      return default_ROR
    } else if (keyToUpdate === 'APR') {
      return default_APR
    } else if (keyToUpdate === 'reInvested_ror') {
      return default_ReInvest_ROR
    } else {
      return default_ROR
    }
  }
  const handleStressTestChangeValue = (label: any, keyToUpdate: string, value: string) => {
    const newData = [...tableData]
    const applyCustomArray = (localStorageKey: string) => {
      const customArrayString = localStorage.getItem(localStorageKey)
      if (customArrayString) {
        const parsedArray = cleanAndParseArray(customArrayString)
        for (let i = 0; i < tableData.length; i++) {
          newData[i][keyToUpdate] = parsedArray[i] || 0 // Set to 0 if the parsed value is undefined
        }
      } else {
        setShowModal(true)
      }
    }
    if (value === 'Given') {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = parseFloat(getDefaultArrayToUndo(keyToUpdate)[i])
      }
    } else if (value === 'Steadily Increase') {
      for (let i = 1; i < tableData.length; i++) {
        newData[i][keyToUpdate] = newData[i - 1][keyToUpdate] + 0.1
      }
    } else if (value === 'Custom 1') {
      applyCustomArray('stressTestCustomRorInputArray1')
    } else if (value === 'Custom 2') {
      applyCustomArray('stressTestCustomRorInputArray2')
    } else {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = parseFloat(value[i].replace('%', ''))
      }
    }
    onEditYear(newData)
  }
  const handleCashInDropDownValueChange = (label: any, keyToUpdate: string, value: string) => {
    const newData = [...tableData]
    if (value === 'Pay None' || value === 'Pay Cash') {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = 0
      }
    } else if (value === 'Pay Yearly' || value === 'Policy Loan') {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = newData[i].TotalPremium
      }
    }
    onEditYear(newData)
  }
  const handleTotalCashPutInSoFarDropDownChange = (
    label: any,
    keyToUpdate: string,
    value: string
  ) => {
    const newData = [...tableData]
    if (value === 'Not Counting Sunk Cost') {
      //  for (let i = 0; i < tableData.length; i++) {
      newData[0][keyToUpdate] = 0
      //  }
    } else {
      newData[0][keyToUpdate] = 350000
    }
    //  } else if (value === 'Pay Yearly' || value === 'Policy Loan') {
    //    for (let i = 0; i < tableData.length; i++) {
    //      newData[i][keyToUpdate] = newData[i].TotalPremium
    //    }
    //  }
    onEditYear(newData)
  }
  function cleanAndParseArray(customArray: any) {
    if (!customArray) {
      return []
    }

    try {
      const parsedArray = JSON.parse(customArray)
      return parsedArray.map((item: string) => {
        // Remove commas and percentage signs, then convert to number
        const cleanedItem = item.replace(/[,%]/g, '')
        return parseFloat(cleanedItem)
      })
    } catch (error) {
      console.error('Error parsing the array:', error)
      return []
    }
  }
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [currentKeyToUpdate, setCurrentKeyToUpdate] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [editableData, setEditableData] = useState(tableData)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const editableRefs = useRef<(HTMLDivElement | null)[]>([])
  useEffect(() => {
    const storedRefs = localStorage.getItem('stressTestCustomRorInputArray1')
    if (storedRefs) {
      editableRefs.current = JSON.parse(storedRefs)
    } else {
      editableRefs.current = []
    }
  }, [])

  const saveChangesInCustomStressRor = (currentKeyToUpdate: string) => {
    // Get updated values from the editable divs
    const updatedData = editableRefs.current.map((ref) => ref?.innerText || '')
    if (selectedValue === 'Custom 1') {
      localStorage.setItem('stressTestCustomRorInputArray1', JSON.stringify(updatedData))
    } else {
      localStorage.setItem('stressTestCustomRorInputArray2', JSON.stringify(updatedData))
    }

    const newTableData = updatedData.map((value, index) => ({
      ...editableData[index],
      year: editableData[index].Year,
      [currentKeyToUpdate]:
        parseFloat(value.replace('%', '')) || editableData[index][currentKeyToUpdate],
    }))
    setEditableData(newTableData)
    onEditYear(newTableData)
    setShowModal(false)
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clipboardData = event.clipboardData.getData('Text')
    const rows = clipboardData.split('\n').map((row) => row.split('\t'))
    const updatedData = [...editableData]
    rows.forEach((row, rowIndex) => {
      if (rowIndex < editableRefs.current.length) {
        const value = row[0]
        const ref = editableRefs.current[rowIndex]
        if (ref) {
          ref.innerText = value
          updatedData[rowIndex].ROR =
            parseFloat(value.replace('%', '')) || updatedData[rowIndex].ROR
        }
      }
    })

    setEditableData(updatedData)
  }
  const handlePasteAddScenario = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // Reset flag on user interaction
    isFirstRender.current = false

    // Attempt to read clipboard data
    if (navigator.clipboard) {
      try {
        const text = await navigator.clipboard.readText()

        processData(text)
      } catch (error) {
        console.error('Failed to read clipboard contents:', error)
        // Handle permissions or other errors gracefully
      }
    } else {
      console.error('Clipboard API not available.')
    }
  }

  const processData = (text: string): void => {
    const rows = text
      .trim()
      .split(/\r?\n/)
      .filter((row) => row.length)

    // Remove all spaces from headers
    const headersFromPaste = rows[0].split(/\t|,/).map((header) => header.replace(/\s+/g, ''))

    const newData: Partial<IDataRow>[] = rows.slice(1).map((row) => {
      const values = row.split(/\t|,/)
      return headersFromPaste.reduce((obj: any, header, index) => {
        obj[header] = values[index] || ''
        return obj
      }, {} as Partial<IDataRow>)
    })

    // Handle different number of rows in newData compared to existing data
    if (newData.length !== tableData.length) {
      // Adjust the existing tableData to match the length of newData
      if (newData.length > tableData.length) {
        // If newData has more rows, extend the existing tableData
        const additionalRows = newData.slice(tableData.length).map((row) => {
          return headersFromPaste.reduce((obj: any, header) => {
            obj[header] = ''
            return obj
          }, {} as IDataRow)
        })
        onEditYear([...tableData, ...additionalRows])
      }
    }
    const updatedData: IDataRow[] = newData.map((newRow, index) => {
      const existingRow = tableData[index] || tableData[tableData.length - 1]
      return {
        ...existingRow,
        ...newRow,
      }
    })
    onEditYear(updatedData)
  }
  const handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    const value = event.currentTarget.innerText
    event.currentTarget.innerText = value.replace('%', '')
  }

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>, index: number) => {
    const value = event.currentTarget.innerText
    event.currentTarget.innerText = `${value}%`
    const updatedData = [...editableData]
    updatedData[index].ROR = parseFloat(value) || editableData[index].ROR
    setEditableData(updatedData)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const charCode = event.which ? event.which : event.keyCode
    const charStr = String.fromCharCode(charCode)
    if (!charStr.match(/^[0-9.]$/) && charCode !== 8 && charCode !== 46) {
      event.preventDefault()
    }
  }

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const value = event.currentTarget.innerText
    if (!value.match(/^\d*\.?\d*$/)) {
      event.currentTarget.innerText = value.slice(0, -1)
    }
  }

  const conditionalRowStyles = [
    {
      when: (row: any) => yearToBeEdit.includes(tableData.indexOf(row)),
      style: {
        backgroundColor: 'rgba(0, 255, 0, 0.2)', // Light green background using RGBA for transparency
        color: 'black',
        border: '1px solid green', // Adding a green border
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 255, 0, 0.4)', // Darker green on hover
        },
      },
    },
  ]

  //  Assuming 'selected' is a state variable that stores the last selected index
  let lastItemToFind = yearToBeEdit[yearToBeEdit.length - 1]
  const [selected, setSelected] = useState<number>(0)
  useEffect(() => {
    setSelected(yearToBeEdit.length > 1 ? lastItemToFind : yearToBeEdit[0])
  }, [yearToBeEdit])
  const handleRowClicked = (row: any, event: any) => {
    const currentIndex = typeof row === 'object' ? tableData.findIndex((item) => item === row) : row
    editableIndex(currentIndex, isDragging)
    if (typeof event === 'boolean' ? event : event.shiftKey && selected !== null) {
      // Calculate the range of indexes
      const start = Math.min(selected, currentIndex)
      const end = Math.max(selected, currentIndex)
      const rangeOfIndexes = []

      for (let i = start; i <= end; i++) {
        rangeOfIndexes.push(i)
      }
      setSelected(
        rangeOfIndexes.length > 1 ? rangeOfIndexes[rangeOfIndexes.length - 1] : rangeOfIndexes[0]
      )
      editableIndex([...rangeOfIndexes], isDragging)
    } else {
      // Update the selected index if Shift is not pressed
      setSelected(currentIndex)
    }
  }
  const preAdditionalColumns: TableColumn<any>[] = [
    {
      // name: 'Age',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0  ibloc-column-name nowrap-text'>Age</p>
          <CustomOverlayTooltip
            content='Client age as of policy anniversary. '
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => row?.Age?.toString(),
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Base Contract Premium</p>
          <CustomOverlayTooltip content='Basic (bare min) premium' className='tooltip-logo' />
        </div>
      ),
      // name: 'Base Contract Premium',
      selector: (row) => toUSDWithoutRoundOff(row?.BaseContractPremium),
      grow: 1.3,
    },
    {
      // name: 'FPR Contract Premium',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>FPR Contract Premium</p>
          <CustomOverlayTooltip content='Additional premium as designed' className='tooltip-logo' />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.FPRContractPremium),
    },
    {
      // name: 'APPUA Premium',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>APPUA Premium</p>
          <CustomOverlayTooltip
            content='Paid up riders that boost the cash value'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.APPUAPremium),
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Premium</p>
          <CustomOverlayTooltip content='Total of the premiums' className='tooltip-logo' />
        </div>
      ),
      // name: 'Total Premium',
      selector: (row) => toUSDWithoutRoundOff(row?.TotalPremium),
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Cash Value</p>
          <CustomOverlayTooltip
            content='Total cash value as guaranteed per schedule.'
            className='tooltip-logo'
          />
        </div>
      ),
      // name: 'Total Cash Value',
      selector: (row) => toUSDWithoutRoundOff(row?.TotalCashValue),
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text nowrap-text'>
            Increase in Total Cash Value
          </p>

          <CustomOverlayTooltip
            content='Delta year over year, as scheduled.'
            className='tooltip-logo'
          />
        </div>
      ),
      // name: 'Increase in Total Cash Value',
      selector: (row) => toUSDWithoutRoundOff(row?.IncreaseInTotalCashValue),
      grow: 1.5,
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Death Benefit</p>
          <CustomOverlayTooltip content='As scheduled.' className='tooltip-logo' />
        </div>
      ),
      // name: 'Total Death Benefit',
      selector: (row) => toUSDWithoutRoundOff(row?.TotalDeathBenefit),
    },
    {
      // name: 'Dividend',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Dividend</p>
          <CustomOverlayTooltip
            content='Non-guaranteed. But historically it has been close as Waystone shows.'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.Dividend),
    },
    {
      // name: 'Total Cash Value',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Cash Value</p>
          <CustomOverlayTooltip
            content='Non-guaranteed. But historically it has been close as Waystone shows.'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.DividendTotalCashValue),
    },
    {
      // name: 'Increase in Total Cash Value',
      name: (
        <div className=' align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Increase in Total Cash Value</p>
          <CustomOverlayTooltip content='Delta year over year. ' className='tooltip-logo' />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.DividendIncreaseInTotalCashValue),
      // grow: 1.3,
    },
    {
      // name: 'Death Benefit without Dividends',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Death Benefit without Dividends</p>
          <CustomOverlayTooltip content='As scheduled' className='tooltip-logo' />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.DeathBenefitWithoutDividends),
      // grow: 1.5,
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Death Benefit</p>
          <CustomOverlayTooltip content='As scheduled' className='tooltip-logo' />
        </div>
      ),
      // name: 'Total Death Benefit',
      selector: (row) => toUSDWithoutRoundOff(row?.DividendTotalDeathBenefit),
    },
  ]

  const postAdditionalColumns: TableColumn<any>[] = [
    {
      // name: 'Total CV Growth LTD',
      name: (
        <div className=' align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>
            Growth in Cash Value since last year
          </p>
          <CustomOverlayTooltip
            content='In a sense, the ROR for the year for that cash value (from Penn Mutual). '
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => `${row?.TotalCvGrowthLTD?.toFixed(2)}%`,
      cell: (row) => (
        <div>
          <span data-tip='Age of the policyholder'>{row?.Age?.toString()}</span>
        </div>
      ),
    
    },
    {
      // name: 'Cash BALANCE Growth YoY',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>
            Total Growth in Cash Value since start
          </p>
          <CustomOverlayTooltip
            content='In a sense, how much did the Penn Mutual cash value grow since start?'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => `${row?.CashBalanceGrowth?.toFixed(2)}%`,
      // grow: 2,
    },
    {
      // name: 'Cash BALANCE Growth LTD',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>
            Growth in Cash Balance since last year
          </p>
          <CustomOverlayTooltip
            content='This is the "ROR" of the cash balance, which includes the cost of the policy loans. '
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => `${row?.CashBalanceGrowthLTD?.toFixed(2)}%`,
      // grow: 2,
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <CustomDropDown
            keyId='TotalCashPutInSoFar'
            optionsArray={TotalCashPutInSoFarConfigs}
            action={handleTotalCashPutInSoFarDropDownChange}
          />
          <CustomOverlayTooltip
            content="How much external cash in the system since the start. By default, it does not include the 'sunk cost' of year 1 & 2. Toggle the dropdown to see what it looks like with that sunk cost. "
            className='tooltip-logo'
          />
        </div>
      ),
      // name: (
      //   <CustomDropDown
      //     keyId='TotalCashPutInSoFar'
      //     optionsArray={TotalCashPutInSoFarConfigs}
      //     action={handleTotalCashPutInSoFarDropDownChange}
      //   />
      // ),
      selector: (row) => toUSDWithoutRoundOff(row?.TotalCashPutInSoFar?.toFixed(2)),
      grow: 2,
    },
    {
      name: (
        <div className=' align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Cash On Cash ROI</p>
          <CustomOverlayTooltip
            content='How much cash are you getting out, for all the external cash you put in?'
            className='tooltip-logo'
          />
        </div>
      ),
      // name: 'Cash On Cash ROI',
      selector: (row) => {
        const cashOnCashROI = row?.CashOnCashROI?.toFixed(2)
        // Check if it is not 'Infinity' or 'NaN'
        return cashOnCashROI !== 'Infinity' &&
          cashOnCashROI !== 'NaN' &&
          cashOnCashROI !== '-Infinity'
          ? `${cashOnCashROI}%`
          : '0%'
      },
    },
    {
      // name: 'Total Cash Opportunity Cost vs S&P 7%',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>
            Total Cash Opportunity Cost vs S&P 7%
          </p>
          <CustomOverlayTooltip
            content='What if you had been investing your total cash instead?'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.TotalCashOpportunity?.toFixed(2)),
    },
    {
      // name: 'TCOO ROI',
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Cash Opportunity Cost ROI</p>
          <CustomOverlayTooltip
            content='The % return if you had been investing your total cash since start.'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => {
        const TCOOROI = row?.TCOOROI?.toFixed(2)
        // Check if it is not 'Infinity' or 'NaN'
        return TCOOROI !== 'Infinity' && TCOOROI !== 'NaN' && TCOOROI !== '-Infinity'
          ? `${TCOOROI}%`
          : '0%'
      },
     
    },
  ]
  const tableColumns: TableColumn<any>[] = [
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Year</p>
          <CustomOverlayTooltip content='Compare This' className='tooltip-logo' />
        </div>
      ),
      selector: (row) => row?.Year?.toString(),
    },
    ...(showAllColumns ? preAdditionalColumns : []),
    {
      // name: 'CashIn',
      name: (
        <div className='d-flex align-items-baseline'>
          <CustomDropDown
            keyId='CashIn'
            optionsArray={CashInConfigs}
            action={handleCashInDropDownValueChange}
          />
          <CustomOverlayTooltip
            content='$0 means you took a Policy Loan; >$0 means you put "cash into the system". '
            className='tooltip-logo'
          />
        </div>
      ),
      // name: (
      //   <CustomDropDown
      //     keyId='CashIn'
      //     optionsArray={CashInConfigs}
      //     action={handleCashInDropDownValueChange}
      //   />
      // ),
      selector: (row) => toUSDWithoutRoundOff(row?.CashIn),
      cell: (row, index) => (
        <EditableCell
          value={`${toUSDWithoutRoundOff(row?.CashIn)}`}
          rowIndex={index}
          keyId='CashIn'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      grow: 1.5,
    },
    {
      name: (
        <div className='d-flex align-items-baseline'>
          <CustomDropDown
            keyId='APR'
            optionsArray={StressTestConfigs}
            action={handleStressTestChangeValue}
          />
          <CustomOverlayTooltip
            content='What do you assume the Policy Loan % to be? Actual % is set once a year by Penn Mutual ~ in December.'
            className='tooltip-logo'
          />
        </div>
      ),
      // name: (
      //   <CustomDropDown
      //     keyId='APR'
      //     optionsArray={StressTestConfigs}
      //     action={handleStressTestChangeValue}
      //   />
      // ),
      selector: (row) => row?.APR,
      cell: (row, index) => (
        <EditableCell
          value={`${row?.APR + '%'}`}
          rowIndex={index}
          keyId='APR'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      grow: 1.5,
    },
    {
      // name: 'Cash Out (IBLOC/PL)',
      name: (
        <div className='d-flex align-items-baseline'>
          <CustomDropDown
            keyId='CashOut'
            optionsArray={CashOutConfigs}
            action={handleCashInDropDownValueChange}
          />
          <CustomOverlayTooltip
            content='How much did you take a loan for? Could be a Policy Loan to pay premiums or invest elsewhere. '
            className='tooltip-logo'
          />
        </div>
      ),
      // name: (
      //   <CustomDropDown
      //     keyId='CashOut'
      //     optionsArray={CashOutConfigs}
      //     action={handleCashInDropDownValueChange}
      //   />
      // ),
      selector: (row) => row?.CashOut,
      cell: (row, index) => (
        <EditableCell
          value={`${toUSDWithoutRoundOff(row?.CashOut)}`}
          rowIndex={index}
          keyId='CashOut'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      grow: 1.5,
    },
    {
      name: (
        <div className='align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Increase in Total Cash Value</p>
          <CustomOverlayTooltip
            content='How much did you take a loan for? Could be a Policy Loan to pay premiums or invest elsewhere. '
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.IBLOCBalance),
    },
    {
      // name: 'Total Cash Value',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Total Cash Value</p>
          <CustomOverlayTooltip
            content='Total Cash Value per Penn Mutual schedule. '
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.TotalCashValueCalculated),
    },
    {
      // name: 'Cash BALANCE',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>Cash BALANCE</p>
          <CustomOverlayTooltip
            content='This is the Total Cash value, minus policy loans and cumulative interest. >$0 means system is still self-contained.'
            className='tooltip-logo'
          />
        </div>
      ),
      selector: (row) => toUSDWithoutRoundOff(row?.CashBalance),
    },
    {
      // name: 'CV Growth YoY',
      name: (
        <div className='d-flex align-items-baseline'>
          <p className='ps-1 m-0 ibloc-column-name nowrap-text'>CV Growth YoY</p>
          <CustomOverlayTooltip content='Compare This' className='tooltip-logo' />
        </div>
      ),
      selector: (row) => `${row.CvGrowthYoY.toFixed(2)}%`,
    },
    ...(showAllColumns ? postAdditionalColumns : []),
  ]
  const copyTableData = () => {
    // Generate header row from column names
    const tableText = [tableColumns.map((column) => column.name).join('\t')]
    // Generate each row from table data
    tableData.forEach((row) => {
      const rowData = tableColumns.map((column) => {
        // Use the selector to get the value from the row, or default to empty string
        let value = column.selector ? column.selector(row) : ''
        // Sanitize the value to replace tabs and new lines to prevent format disruptions
        value = value?.toString().replace(/\t/g, ' ').replace(/\n/g, ' ')
        // Ensure undefined values are converted to an empty string
        return value !== undefined ? value : ''
      })
      // Join all columns' values with tabs to form a complete row
      tableText.push(rowData.join('\t'))
    })
    // Copy the formatted table data to the clipboard
    if (isSblocPage) {
      window.parent.postMessage({type: 'TABLE_DATA', data: tableText}, '*')
      setCopyMessage('Table data copied to clipboard.')
      setTimeout(() => setCopyMessage(''), 3000)
    } else {
      navigator.clipboard
        .writeText(tableText.join('\n'))
        .then(() => {
          // Success: Display a success message and clear it after 3 seconds
          setCopyMessage('Table data copied to clipboard.')
          setTimeout(() => setCopyMessage(''), 3000)
        })
        .catch((error) => {
          // Error: Display a failure message and clear it after 0.5 seconds
          setCopyMessage('Copy failed. Please try again.')
          setTimeout(() => setCopyMessage(''), 500)
        })
    }
  }
  const elements = document.querySelectorAll('div[id^="cell-sbloc-"]')

  elements.forEach((element: any) => {
    element.style.padding = '0'
  })

  const getCustomNameFromLocalSTorage = () => {
    return selectedValue === 'Custom 1'
      ? localStorage.getItem('CustomName1')
      : selectedValue === 'Custom 2'
      ? localStorage.getItem('CustomName2')
      : selectedValue
  }
  const [editableTitle, setEditableTitle] = useState(getCustomNameFromLocalSTorage())
  useEffect(() => {
    setEditableTitle(getCustomNameFromLocalSTorage())
  }, [selectedValue])
  const [isEditing, setIsEditing] = useState(false)

  const handleCustomBarName = () => {
    localStorage.setItem(
      selectedValue === 'Custom 1' ? 'CustomName1' : 'CustomName2',
      editableTitle || ''
    )
    setIsEditing(false)
  }

  // Function to handle modal close
  const handleClose = () => {
    setIsEditing(false)
    setShowModal(false)
  }

  // Use effect to focus the div when it becomes editable
  const dummyScenarios = [
    {
      id: 1,
      value: 0,
      label: 'Default',
    },
    {
      id: 2,
      value: IBLOCDefaultData2,
      label: 'Persona A',
    },
    {
      id: 2,
      value: IBLOCDefaultData,
      label: 'Persona B',
    },
  ]

  // Key to store/retrieve the persona data
  const localStorageKey = 'IBLOCScenarioData'
  useEffect(() => {
    // Check for persona data in local storage
    const storedScenarios = localStorage.getItem(localStorageKey)
    if (storedScenarios) {
      // If found, parse and set it to state
      setScenarioData(JSON.parse(storedScenarios))
    } else {
      // If not found, use dummyPersonas and save them to local storage
      setScenarioData(dummyScenarios)
      localStorage.setItem(localStorageKey, JSON.stringify(dummyScenarios))
    }
  }, [])

  const comparisonChartDataLocalStorageKey = 'IBLOC_COMPARISON_CHART_DATA'
  const comparisonTableDataLocalStorageKey = 'IBLOC_COMPARISON_TABLE_DATA'

  const handleEditChartComparison = (idToEdit: number) => {
    const stateIndexToUpdate = compareScenarioChartData.findIndex(
      (item: any) => item.id === idToEdit
    )

    if (stateIndexToUpdate !== -1) {
      // Set the selected chart index
      setSelectedChartIndex(idToEdit)
      // Update the chart data with the value of the selected index
      handleUpdateChartData(compareScenarioChartData[stateIndexToUpdate]?.value)
    } else {
      console.log('Item not found')
    }
  }

  const handleDeleteChartComparison = (idToDelete: number) => {
    // delete index from table array
    const updatedTableData = compareScenarioTableData.filter((item: any) => item.id !== idToDelete)
    setCompareScenarioTableData(updatedTableData)

    // delete index from chart array
    const updatedChartData = compareScenarioChartData.filter((item: any) => item.id !== idToDelete)
    setCompareScenarioChartData(updatedChartData)

    // delete index from chart data
    const existingChartData = localStorage.getItem(comparisonChartDataLocalStorageKey)
    if (existingChartData) {
      const parsedExistingChartData = JSON.parse(existingChartData)

      // Find the index to delete in the local storage data
      const storageIndexToDelete = parsedExistingChartData.findIndex(
        (item: any) => item.id === idToDelete
      )

      if (storageIndexToDelete !== -1) {
        // Remove the item from the local storage data array
        parsedExistingChartData.splice(storageIndexToDelete, 1)

        // Store the updated data back in local storage
        localStorage.setItem(
          comparisonChartDataLocalStorageKey,
          JSON.stringify(parsedExistingChartData)
        )
      } else {
        console.log('Item not found in local storage')
      }
    } else {
      console.log('No existing data in local storage')
    }

    // delete index from table data
    const existingTableData = localStorage.getItem(comparisonTableDataLocalStorageKey)
    if (existingTableData) {
      const parsedExistingTableData = JSON.parse(existingTableData)

      // Find the index to delete in the local storage data
      const storageIndexToDelete = parsedExistingTableData.findIndex(
        (item: any) => item.id === idToDelete
      )

      if (storageIndexToDelete !== -1) {
        // Remove the item from the local storage data array
        parsedExistingTableData.splice(storageIndexToDelete, 1)

        // Store the updated data back in local storage
        localStorage.setItem(
          comparisonTableDataLocalStorageKey,
          JSON.stringify(parsedExistingTableData)
        )
      } else {
        console.log('Item not found in local storage')
      }
    } else {
      console.log('No existing data in local storage')
    }

    setSelectedChartIndex(0)
    handleUpdateChartData(compareScenarioChartData[0]?.value)
  }

  return (
    <>
      {!isLoading ? (
        <div
          id={sectionIds[2]}
          className={
            sectionIdToShowInWebViewer !== sectionIds[2] && showInWebViewer ? 'd-none' : ''
          }
        >
          <div className='d-flex justify-content-between'>
            <div>
              <IBLOCDropDown
                data={scenarioData}
                onValueChange={(data: IDataRow[]) => onEditYear(data)}
                localStorageKey={localStorageKey}
                setScenarioData={(updatedData: any) => {
                  setScenarioData(JSON.parse(updatedData))
                }}
                isScenarioModal={() => setShowScenarioModal(true)}
                isEditableModal={() => setIsEditableModal(true)}
                setScenarioIdToEdit={(id: number) => setScenarioIdToEdit(id)}
              />
              <IBLOCScenarioModal
                show={showScenarioModal}
                onHide={() => setShowScenarioModal(false)}
                setScenarioData={(updatedData: any) => {
                  setScenarioData(JSON.parse(updatedData))
                }}
                onSubmit={() => {}}
                localStorageKey={localStorageKey}
                isEditableModal={isEditableModal}
                scenarioIdToEdit={scenarioIdToEdit}
              />
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/plus.svg')}
                style={{width: '22px', height: '22px', cursor: 'pointer'}}
                onClick={() => {
                  setShowScenarioModal(true)
                  setIsEditableModal(false)
                }}
                alt='+'
              />
              <Button className='mx-5 custom-primary-button my-10' onClick={undoTable}>
                Undo
              </Button>
              {!isFullView ? (
                <img
                  onClick={() => setIsFullView(true)}
                  style={{width: '30px', height: '30px', objectFit: 'contain', cursor: 'pointer'}}
                  src={toAbsoluteUrl('/media/home/zoomIn.png')}
                  alt='zoom In'
                  title='Full view'
                />
              ) : (
                <img
                  onClick={() => setIsFullView(false)}
                  style={{width: '30px', height: '30px', objectFit: 'contain', cursor: 'pointer'}}
                  src={toAbsoluteUrl('/media/home/zoomOut.png')}
                  title='Actual view'
                  alt='zoom Out'
                />
              )}
              {!showAllColumns ? (
                <img
                  onClick={() => setShowAllColumns(true)}
                  style={{
                    width: '30px',
                    height: '30px',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  src={toAbsoluteUrl('/media/home/table-columns-insert.svg')}
                  alt='zoom In'
                  title='Full view'
                />
              ) : (
                <img
                  onClick={() => setShowAllColumns(false)}
                  style={{
                    width: '30px',
                    height: '30px',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  src={toAbsoluteUrl('/media/home/table-columns-remove.svg')}
                  title='Actual view'
                  alt='zoom Out'
                />
              )}
            </div>
            <div
              aria-disabled={compareScenarioChartData.length > 3}
              style={{
                opacity: compareScenarioChartData.length > 3 ? 0.5 : 1,
                pointerEvents: compareScenarioChartData.length > 3 ? 'none' : 'auto',
              }}
              className={`${screenWidth < 500 ? 'mt-n8' : ''}`}
            >
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>ROI</p>
                <CustomOverlayTooltip
                  content='ROT to calculate Opportunity Cost'
                  className='tooltip-logo'
                />
              </div>
              <div className='input-container d-flex justify-content-center align-items-center'>
                <input
                  id='number-of-years'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='7%'
                  value={defaultOpportunityCostROI}
                  onChange={(e) => {
                    setDefaultOpportunityCostROI(e.target.value)
                    onEditYear([...tableData])
                  }}
                />
              </div>
            </div>
            <div
              aria-disabled={compareScenarioChartData.length > 3}
              style={{
                opacity: compareScenarioChartData.length > 3 ? 0.5 : 1,
                pointerEvents: compareScenarioChartData.length > 3 ? 'none' : 'auto',
              }}
              className={`${screenWidth < 500 ? 'mt-n8' : ''}`}
            >
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Compare This</p>
                <CustomOverlayTooltip content='Compare This' className='tooltip-logo' />
              </div>
              <div className='input-container d-flex justify-content-center align-items-center'>
                <input
                  id='number-of-years'
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='e.g. I pay it down'
                  value={compareScenarioTitle}
                  onChange={(e) => setCompareScenarioTitle(e.target.value)}
                />

                <img
                  src={toAbsoluteUrl('/media/icons/duotune/general/plus.svg')}
                  style={{width: '22px', height: '22px', cursor: 'pointer'}}
                  className='me-2'
                  onClick={() => {
                    handleAddComparisonChart()
                  }}
                  alt='+'
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-center '>
            {compareScenarioChartData.map((data: any, _index: number) => (
              <>
                {_index > 0 && (
                  <div className='ibloc-comparison-tile px-4 mb-0 mx-2 d-flex justify-content-between align-items-center'>
                    <p
                      className='mb-0 me-3'
                      style={{fontSize: '25px', color: 'white'}}
                      onClick={() => handleDeleteChartComparison(data?.id)}
                    >
                      ×
                    </p>
                    <p
                      className='mb-0 text-white'
                      style={{fontSize: '13px'}}
                      onClick={() => handleEditChartComparison(data?.id)}
                    >
                      {data?.label}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
          <div
            className='mt-3 user-select-none'
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <div
              key={showAllColumns ? 'with-all-columns' : 'default-columns'}
              className='sbloc-data-table-custom'
            >
              <DataTable
                columns={tableColumns}
                data={tableData}
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={handleRowClicked}
              />
              <div className='d-flex justify-content-end'>
                {copyMessage && <div>{copyMessage}</div>}
                <div className='ms-3 my-5'>
                  <div className='clipboard-btn p-2' onClick={copyTableData}>
                    <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-2x' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  )
}

export default IBLOCTable
