import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header/user/HeaderWrapper'
import {ScrollTop} from './components/scroll-top'
import {reInitMenu} from '../helpers'
import {PageDataProvider} from './core'
import {Content} from './components/content/user'
import {FooterWrapper} from './components/footer/user/FooterWrapper'
import {ADVISORS} from '../../app/constants/AppConstants'

const UserLayout = () => {
  const location = useLocation()

  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const currentHostname = window.location.hostname

  return (
    <>
      <PageDataProvider>
        {/* <HeaderWrapper /> */}
        <Content>
          <Outlet />
        </Content>
        {/* <FooterWrapper /> */}
        {/* <ScrollTop /> */}
        {isArqInURL && (
          <div className='poweredBy-text pb-1 pe-2'>
            <span>
              {currentHostname === 'vividli.ai'
                ? ADVISORS.VIVIDLI_POWERED_BY_MESSAGE
                : ADVISORS.POWERED_BY_MESSAGE}
            </span>
          </div>
        )}
      </PageDataProvider>
    </>
  )
}

export {UserLayout}
