import {BEGINNERS_SECTION, sectionIds} from '../../../constants/AppConstants'
import {useLocation} from 'react-router-dom'
import FeedbackSection from './feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import InterestListButton from '../../pages/about/components/InterestListButton'

interface PossibilitiesGifSectionProps {
  subHeading: string
  paragraph: string
  secondParagraph: string
}

const PossibilitiesGifSection = (props: PossibilitiesGifSectionProps) => {
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  return (
    <div
      className={
        sectionIdToShowInWebViewer !== sectionIds[7] && showInWebViewer
          ? 'd-none overflow-hidden'
          : 'overflow-hidden'
      }
      style={{width: '100%'}}
    >
      <section className='pt-5 pt-lg-15 position-relative' id={sectionIds[8]}>
        <div className='px-7 px-md-10 px-lg-20'>
          <div className=''>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <h2 className='my-2 mb-4 mb-md-5 mb-lg-10 text-center mt-10 fw-bolder position-relative z-index-2'>
                  Easily help your clients make decisions by showing the possibilities.
                </h2>
              </div>
              <div className='col-12 col-lg-6'>
                <img
                  className='my-2 mb-4 mb-md-5 mb-lg-10 text-center vividli-gif-img w-100'
                  src={toAbsoluteUrl('/media/home/vividli.gif')}
                  alt='Descriptive Text for GIF'
                />
              </div>
            </div>
            {/* cloud */}
            <img
              className='cloud-about-big d-none d-lg-block'
              src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
              alt='cloud-big'
            />
            {/* cloud */}
          </div>

          <div className='my-5'>
            <h2 className='text-center mb-4'>Tell your story to next-gen Clients</h2>
            <div className='row d-flex text-center mx-5 justify-content-around'>
              <div className='col-12 col-md-3'>
                <h5 className='custom-secondary-button btn-blue mt-5'>Attract</h5>
                <p className='card-text mt-5'>
                  Make financial planning attractive to ‘Zillennial’ aspiring investors.
                </p>
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <h5 className='custom-secondary-button btn-blue mt-5'>Retain</h5>

                <p className='card-text  mt-5'>Chat strategy using visuals vs crunching numbers.</p>
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <h5 className='custom-secondary-button btn-blue mt-5'>Scale</h5>
                <p className='card-text mt-5'>Answer “what-ifs” quickly and easily using AI.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <InterestListButton />
    </div>
  )
}

export default PossibilitiesGifSection
