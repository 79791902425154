import React, {useEffect, memo, useState} from 'react'
import {SBLOC_INPUT_BOX_TOOLTIPS} from '../../../../../constants/AppConstants'
import CustomOverlayTooltip from '../../projections/components/CustomOverlayTooltip'
import {useSBLOCProjections} from '../../../utils/sblocUtils'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../../components/projectionSlider/ProjectionSlider'
import {SBLOCInputsProps} from '../../../helper/SBLOC/_model'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {
  contributionAndDividendsFrequency,
  defaultPersonasData,
} from '../../projections/components/ProjectionsConfig'
import CustomDropdown from './CustomDropDown'
import {useSBLOCContext} from '../../../../context/SBLOCContext'

let barsDataArrayToShowOnChart = []

const SBLOCInputs: React.FC<SBLOCInputsProps> = (props) => {
  const {isFirstRender, initialValues, handleKeyPress, formik, generateData} = useSBLOCProjections()
  const screenWidth = useScreenWidth()
  const {personaData, setPersonaData} = useSBLOCContext()
  const [maxLimit, setMaxLimit] = useState<number>(10000000)
  const [showPersonaModal, setShowPersonaModal] = useState<any>(false)

  useEffect(() => {
    if (isFirstRender.current) {
      props.onChangeValues(formik.values)
      barsDataArrayToShowOnChart = generateData(initialValues)
      isFirstRender.current = false
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      return
    }
    if (!props.isFirstRowOfTableChanged) {
      let maxLimit = functionToSetMaxLimit(formik.values.starting_amount)
      setMaxLimit(maxLimit)
      const dataObj = formik.values
      barsDataArrayToShowOnChart = []
      barsDataArrayToShowOnChart = generateData(dataObj)
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      props.onChangeValues(formik.values)
    }
  }, [formik.values])

  useEffect(() => {
    if (props.isUndo) {
      formik.setValues(initialValues)
      props.resetUndo()
    }
  }, [props.isUndo])

  useEffect(() => {
    if (props.sharedSbLocInputs && Object.keys(props.sharedSbLocInputs).length > 1) {
      formik.setFieldValue('starting_amount', props.sharedSbLocInputs.starting_amount)
      formik.setFieldValue('asset_rate_of_return', props.sharedSbLocInputs.ROR)
      formik.setFieldValue('years_to_grow', props.sharedSbLocInputs.years_to_grow)
      formik.setFieldValue('borrowed_amount', props.sharedSbLocInputs.borrowed_amount)
      formik.setFieldValue('annualized_interest_rate', props.sharedSbLocInputs.APR)
      formik.setFieldValue('re_invested_amount', props.sharedSbLocInputs.reInvested_asset)
      formik.setFieldValue('re_invest_rate_of_return', props.sharedSbLocInputs.reInvested_ror)
      formik.setFieldValue('cash_flow_that_year', props.sharedSbLocInputs.cash_flow_that_year)
      formik.setFieldValue('cash_flow_frequency', props.sharedSbLocInputs.cash_flow_frequency)
    }
  }, [props.sharedSbLocInputs])

  useEffect(() => {
    if (props.isFirstRowOfTableChanged && props.firstTableRowValue) {
      Object.entries(props.firstTableRowValue).forEach(([key, value]) => {
        const numValue = parseFloat(value as string)
        if (!isNaN(numValue)) {
          formik.setFieldValue(key, numValue)
        } else {
          console.warn(`Invalid number conversion for field '${key}' with value '${value}'`)
        }
      })
    }
  }, [props.isFirstRowOfTableChanged])

  function functionToSetMaxLimit(input: number): number {
    if (input >= 0 && input < 100000) {
      return 100000
    } else if (input >= 100000 && input < 300000) {
      return 300000
    } else if (input >= 300000 && input < 1000000) {
      return 1000000
    } else if (input >= 1000000 && input < 10000000) {
      return 10000000
    } else if (input >= 10000000 && input < 30000000) {
      return 30000000
    } else if (input >= 30000000 && input < 100000000) {
      return 100000000
    } else {
      return 100000000
    }
  }
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('cash_flow_frequency', value)
  }

  // const dummyPersonas = [
  //   {
  //     id: 0,
  //     value: 0,
  //     label: 'Select persona',
  //     annualized_interest_rate: 8,
  //     asset_rate_of_return: 9,
  //     borrowed_amount: 2000000,
  //     re_invest_rate_of_return: 10,
  //     re_invested_amount: 1000000,
  //     starting_amount: 4000000,
  //     years_to_grow: 15,
  //     cash_flow_that_year: 0,
  //     cash_flow_frequency: 0,
  //   },
  //   {
  //     id: 1,
  //     value: 1,
  //     label: 'Persona 1',
  //     annualized_interest_rate: 5,
  //     asset_rate_of_return: 5,
  //     borrowed_amount: 5000000,
  //     re_invest_rate_of_return: 5,
  //     re_invested_amount: 5000000,
  //     starting_amount: 5000000,
  //     years_to_grow: 5,
  //     cash_flow_that_year: 0,
  //     cash_flow_frequency: 0,
  //   },
  //   {
  //     id: 2,
  //     value: 2,
  //     label: 'Persona 2',
  //     annualized_interest_rate: 6,
  //     asset_rate_of_return: 6,
  //     borrowed_amount: 6000000,
  //     re_invest_rate_of_return: 6,
  //     re_invested_amount: 6000000,
  //     starting_amount: 6000000,
  //     years_to_grow: 6,
  //     cash_flow_that_year: 0,
  //     cash_flow_frequency: 0,
  //   },
  //   {
  //     id: 3,
  //     value: 3,
  //     label: 'Persona 3',
  //     annualized_interest_rate: 7,
  //     asset_rate_of_return: 7,
  //     borrowed_amount: 7000000,
  //     re_invest_rate_of_return: 7,
  //     re_invested_amount: 7000000,
  //     starting_amount: 7000000,
  //     years_to_grow: 7,
  //     cash_flow_that_year: 0,
  //     cash_flow_frequency: 0,
  //   }
  // ]

  useEffect(() => {
    // Key to store/retrieve the persona data
    const localStorageKey = 'personasData'

    // Check for persona data in local storage
    const storedPersonas = localStorage.getItem(localStorageKey)
    if (storedPersonas) {
      // If found, parse and set it to state
      setPersonaData(JSON.parse(storedPersonas))
    } else {
      // If not found, use dummyPersonas and save them to local storage
      setPersonaData(defaultPersonasData)
      localStorage.setItem(localStorageKey, JSON.stringify(defaultPersonasData))
    }
  }, [])

  const handlePersonaChange = (value: number) => {
    let selectedPersona = personaData.find((persona: any) => persona.value === value)

    if (selectedPersona && selectedPersona.value !== 0) {
      formik.setValues({
        annualized_interest_rate: selectedPersona.annualized_interest_rate,
        asset_rate_of_return: selectedPersona.asset_rate_of_return,
        borrowed_amount: selectedPersona.borrowed_amount,
        re_invest_rate_of_return: selectedPersona.re_invest_rate_of_return,
        re_invested_amount: selectedPersona.re_invested_amount,
        starting_amount: selectedPersona.starting_amount,
        years_to_grow: selectedPersona.years_to_grow,
        cash_flow_that_year: selectedPersona.cash_flow_that_year,
        cash_flow_frequency: selectedPersona.cash_flow_frequency,
      })
    }
  }

  return (
    <>
      <div>
        <div>
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='projection-form'>
            <div className='row m-0 justify-content-center'>
              <div className='col-12 col-md-3 mx-2 mt-3'>
                {/* Starting Amount */}
                <div className='fv-row mb-3 d-flex starting-amount-bordered-div'>
                  <div className='col-6'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                      <CustomOverlayTooltip
                        content={SBLOC_INPUT_BOX_TOOLTIPS.STARTING_AMOUNT}
                        className='tooltip-logo'
                      />
                    </div>
                    <div
                      className={`input-container d-flex justify-content-center align-items-center ${
                        formik.touched.starting_amount && formik.errors.starting_amount
                          ? 'error-border'
                          : ''
                      }`}
                    >
                      <CustomInputField
                        id='starting_amount'
                        className='form-control form-control-lg form-control-solid'
                        placeholder=' e.g 1000.00'
                        decimalsLimit={2}
                        maxLength={8}
                        defaultValue={formik.values.starting_amount}
                        onValueChange={(value: any) => {
                          const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value)
                          formik.setFieldValue('starting_amount', parsedValue)
                        }}
                        onKeyDown={handleKeyPress}
                        value={formik.values.starting_amount}
                      />
                    </div>
                  </div>
                  <div className='col-6 d-flex align-items-center justify-content-center'>
                    <div style={{width: '100%'}} className='mt-10'>
                      <ProjectionSlider
                        value={formik.values.starting_amount}
                        maxValue={maxLimit}
                        onRangeChange={(value: any) =>
                          formik.setFieldValue('starting_amount', parseInt(value))
                        }
                        isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                        sliderColor={props?.sliderColor}
                      />
                    </div>
                  </div>
                </div>
                <div className='starting-amount-gain-bordered-div'>
                  <div className='fv-row mb-3 d-flex '>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>ROR</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.Asset_rate_of_return}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.asset_rate_of_return && formik.errors.asset_rate_of_return
                            ? 'error-border'
                            : ''
                        } ${props.IsWayStoneSlider ? 'waystone-slider-color' : ''}`}
                        style={{width: '6rem'}}
                      >
                        <CustomInputField
                          id='asset_rate_of_return'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          maxLength={8}
                          prefix='%'
                          allowDecimals={true}
                          decimalsLimit={2}
                          defaultValue={formik.values.asset_rate_of_return}
                          onValueChange={(value: any) => {
                            formik.setFieldValue('asset_rate_of_return', value || '')
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.asset_rate_of_return}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.asset_rate_of_return}
                          minValue={1}
                          maxValue={25}
                          isMoney={false}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('asset_rate_of_return', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Years to Grow */}
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Years to Grow</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.years_to_grow && formik.errors.years_to_grow
                            ? 'error-border'
                            : ''
                        }`}
                        style={{width: '6rem'}}
                      >
                        <CustomInputField
                          id='years_to_grow'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={0}
                          maxLength={8}
                          defaultValue={formik.values.years_to_grow}
                          prefix=''
                          onValueChange={(value: any) => {
                            const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                            formik.setFieldValue('years_to_grow', parsedValue)
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.years_to_grow}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.years_to_grow}
                          minValue={1}
                          maxValue={100}
                          isMoney={false}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('years_to_grow', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-none d-md-block'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Save Personas</p>
                    <CustomOverlayTooltip content='Your saved personas' className='tooltip-logo' />
                  </div>
                  <CustomDropdown data={personaData} onValueChange={handlePersonaChange}  sliderColor={props?.sliderColor} />
                </div>
                {/* ROR */}
              </div>

              <div className='col-12 col-md-3  mx-2 mt-3'>
                {/* Borrowed Amount */}
                <div className='borrowed-amount-bordered-div'>
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Borrowed Amount</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.BORROWED_AMOUNT}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.borrowed_amount && formik.errors.borrowed_amount
                            ? 'error-border'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          id='borrowed_amount'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          defaultValue={formik.values.borrowed_amount}
                          onValueChange={(value: any) => {
                            const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value)
                            formik.setFieldValue('borrowed_amount', parsedValue)
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.borrowed_amount}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.borrowed_amount}
                          minValue={100}
                          maxValue={maxLimit / 2}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('borrowed_amount', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>

                  {/* APR */}
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>APR</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.ANNUALIZED_INTEREST_RATE}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.annualized_interest_rate &&
                          formik.errors.annualized_interest_rate
                            ? 'error-border'
                            : ''
                        }`}
                        style={{width: '6rem'}}
                      >
                        <CustomInputField
                          id='annualized_interest_rate'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          prefix='%'
                          defaultValue={formik.values.annualized_interest_rate}
                          onValueChange={(value: any) => {
                            formik.setFieldValue('annualized_interest_rate', value || '')
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.annualized_interest_rate}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.annualized_interest_rate}
                          minValue={1}
                          maxValue={25}
                          isMoney={false}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('annualized_interest_rate', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-3  mx-2 mt-3'>
                {/* Re-invested Amount */}
                <div className='reinvested-amount-bordered-div'>
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Re-invested Amount</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.REINVESTED_AMOUNT}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.re_invested_amount && formik.errors.re_invested_amount
                            ? 'error-border'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          id='re_invested_amount'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          defaultValue={formik.values.re_invested_amount}
                          onValueChange={(value: any) => {
                            const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value)
                            formik.setFieldValue('re_invested_amount', parsedValue)
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.re_invested_amount}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.re_invested_amount}
                          maxValue={maxLimit / 2}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('re_invested_amount', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Re-invest ROR */}
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Re-Invest ROR</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.REINVESTING_RATE_OF_RETURN}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.re_invest_rate_of_return &&
                          formik.errors.re_invest_rate_of_return
                            ? 'error-border'
                            : ''
                        }`}
                        style={{width: '6rem'}}
                      >
                        <CustomInputField
                          prefix='%'
                          id='re_invest_rate_of_return'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          defaultValue={formik.values.re_invest_rate_of_return}
                          onValueChange={(value: any) => {
                            formik.setFieldValue('re_invest_rate_of_return', value || '')
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.re_invest_rate_of_return}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.re_invest_rate_of_return}
                          minValue={1}
                          maxValue={25}
                          isMoney={false}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('re_invest_rate_of_return', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Cash Flow */}
                  <div className='fv-row mb-3 d-flex'>
                    <div className='col-6'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Cash Flow</p>
                        <CustomOverlayTooltip
                          content={SBLOC_INPUT_BOX_TOOLTIPS.CASH_FLOW}
                          className='tooltip-logo'
                        />
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year
                            ? 'error-border'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          id='cash_flow_that_year'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          defaultValue={formik.values.cash_flow_that_year}
                          onValueChange={(value: any) => {
                            const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value)
                            formik.setFieldValue('cash_flow_that_year', parsedValue)
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.cash_flow_that_year}
                        />
                      </div>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <div style={{width: '100%'}} className='mt-10'>
                        <ProjectionSlider
                          value={formik.values.cash_flow_that_year}
                          maxValue={maxLimit / 2}
                          onRangeChange={(value: any) =>
                            formik.setFieldValue('cash_flow_that_year', parseInt(value))
                          }
                          isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                          sliderColor={props?.sliderColor}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-5 col-md-6 fv-row sbloc-select dropdown-bg-color mb-0 mb-lg-0 pe-0 pe-md-2 '>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>Cash Flow Frequency</p>
                      <CustomOverlayTooltip
                        content={SBLOC_INPUT_BOX_TOOLTIPS.CASH_FLOW}
                        className='tooltip-logo'
                      />
                    </div>
                    <select
                      id='compounding-frequency'
                      className={`form-select dropdown ${screenWidth < 768 ? 'ms-n2' : ''}`}
                      // style={{backgroundColor: `${props?.sliderColor} !important`, borderColor: `${props?.sliderColor} !important`}}
                      style={{ backgroundColor: props?.sliderColor, borderColor: props?.sliderColor }}
                      {...formik.getFieldProps('cash_flow_frequency')}
                      onChange={handleCompoundingFreqChange}
                    >
                      {contributionAndDividendsFrequency.map((option) => {
                        return (
                          <option className='px-2' key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-block d-md-none'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Save Personas</p>
                <CustomOverlayTooltip content='Your saved personas' className='tooltip-logo' />
              </div>
              <CustomDropdown data={personaData} onValueChange={handlePersonaChange} />
            </div>
            {/* Error messages */}
            <div className='text-center mt-2'>
              {formik.touched.starting_amount && formik.errors.starting_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.starting_amount}</div>
                </div>
              ) : null}
              {formik.touched.asset_rate_of_return && formik.errors.asset_rate_of_return ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.asset_rate_of_return}</div>
                </div>
              ) : null}
              {formik.touched.years_to_grow && formik.errors.years_to_grow ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.years_to_grow}</div>
                </div>
              ) : null}
              {formik.touched.borrowed_amount && formik.errors.borrowed_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.borrowed_amount}</div>
                </div>
              ) : null}
              {formik.touched.re_invested_amount && formik.errors.re_invested_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.re_invested_amount}</div>
                </div>
              ) : null}
              {formik.touched.annualized_interest_rate && formik.errors.annualized_interest_rate ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.annualized_interest_rate}</div>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(SBLOCInputs)
