import React from 'react'

interface PropertyCardProps {
  imageUrl: string
  value: string
}

const PropertyCard: React.FC<PropertyCardProps> = ({imageUrl, value}) => {
  return (
    <div className='property-card'>
      <img src={imageUrl} alt='Property' className='property-image' />
      <div className='property-info'>
        <span>Current Value</span>
        <span className='property-value'>${value}</span>
      </div>
    </div>
  )
}

export default PropertyCard
