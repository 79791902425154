import {addToInterestList, updateInterestList} from '../core/_request'
import {toast} from 'react-toastify'
import ReactGA from 'react-ga4'
import {ChartExperiment} from '../../../modules/ABTest/_ABTestConfig'
import {emitter} from '@marvelapp/react-ab-test'
import {Button} from 'react-bootstrap'
import useFocus from '../../../modules/hooks/useFocus'
import React, {FormEvent, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {ABOUT_PAGE} from '../../../../constants/AppConstants'
const InterestListButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [interestListEmail, setInterestListEmail] = useState('')
  const [searchParams] = useSearchParams()
  const [userRowId, setUserRowId] = useState(0)
  const [joinInterestList, setJoinInterestList] = useState(0)
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const notifyError = (msg: string) => toast.error(msg)
  const isProduction = process.env.NODE_ENV === 'production'
  const {inputRef, focusInput} = useFocus()
  const userDescription = ['Investor', 'Advisor', 'CTO', 'Ally', 'Just Curious']
  const [selectedDescription, setSelectedDescription] = useState<string[]>([])
  const [showInput, setShowInput] = useState(false)
  const {INTEREST_LIST} = ABOUT_PAGE
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // add user to interest list
    setIsLoading(true)
    try {
      const row = await addToInterestList(interestListEmail, searchParams.get('via'))
      if (row.data?.row?.id !== undefined && row.data?.row?.id !== 0 && row.data?.row?.id !== null)
        setUserRowId(row.data?.row?.id)
      setIsLoading(false)
      setJoinInterestList(2)
      const newTimeoutId = window.setTimeout(() => {
        setJoinInterestList(3)
      }, 4500)
      setTimeoutId(newTimeoutId as unknown as number)
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsLoading(false)
    }

    // Only track the GA event in production
    if (isProduction) {
      ReactGA.event({
        category: ChartExperiment.name,
        action: emitter.getActiveVariant(ChartExperiment.name),
      })
    }
  }
  const handleOptionClick = (description: string) => {
    let updatedDescriptionList: string[] = []

    const descExists = selectedDescription.includes(description)
    if (descExists) {
      // Remove the description if it's already selected
      updatedDescriptionList = selectedDescription.filter((item) => item !== description)
    } else {
      // Add the description if it's not selected
      updatedDescriptionList = [...selectedDescription, description]
    }

    setSelectedDescription(updatedDescriptionList)

    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }

    const newTimeoutId = window.setTimeout(async () => {
      setJoinInterestList(3)
      if (updatedDescriptionList.length > 0 && userRowId !== null && userRowId !== undefined) {
        await updateInterestList(userRowId, updatedDescriptionList)
      }
    }, 4500)

    setTimeoutId(newTimeoutId as number) // TypeScript ensures newTimeoutId is a number
  }
  const toggleWaitlistInput = () => {
    setShowInput(true)
    setJoinInterestList(1)
  }
  const handleInterestListUI = (joinInterestList: number) => {
    switch (joinInterestList) {
      case 1:
        return (
          <>
            <form
              className='d-flex align-items-center flex-column mt-20 pt-6'
              onSubmit={handleSubmit}
            >
              <div className='col-sm-6 col-12 d-flex justify-content-center align-items-center waitlist-input'>
                <input
                  ref={inputRef}
                  type='email'
                  className='form-control form-control-lg form-control-solid py-2'
                  placeholder='Enter your email'
                  required
                  value={interestListEmail}
                  onChange={(e) => setInterestListEmail(e.target.value)}
                />
              </div>
              <Button
                type='submit'
                className='custom-secondary-button my-6 border-0'
                disabled={isLoading}
              >
                {!isLoading && <span className='indicator-label'>SUBMIT</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </form>
          </>
        )
      case 2:
        return (
          <div className='stay-tuned-join-container container-fluid'>
            <div className='row text-center'>
              <p className='sub-heading-small primary-text mb-4 mb-md-10'>THANKS!</p>
              <p className='fw-bold pink-text m-0 p-0 mb-3'>
                QQ, for our info, which describe you? (Optional)
              </p>
              <div className='d-flex justify-content-center flex-wrap gap-4'>
                {userDescription.map((desc, index) => (
                  <div
                    key={index}
                    className={`my-3 cursor-pointer border-0 ${
                      selectedDescription.includes(desc)
                        ? 'custom-selected-div '
                        : 'custom-secondary-button'
                    }`}
                    onClick={() => handleOptionClick(desc)}
                  >
                    {desc}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )

      case 3:
        return (
          <div className='stay-tuned-join-container'>
            <p className='sub-heading-small primary-text'>THANK YOU</p>
          </div>
        )
      default:
        return (
          <div className='stay-tuned-join-container'>
            <Button className='custom-secondary-button' onClick={toggleWaitlistInput}>
              {INTEREST_LIST.JOIN_INTEREST_LIST}
            </Button>
          </div>
        )
    }
  }
  return <div>{handleInterestListUI(joinInterestList)}</div>
}

export default InterestListButton
