import React from 'react'

const SummaryCard = () => {
  return (
    <div className='summary-card'>
      <p>If I sold ARQ today, I'd get...</p>
      <div className='values'>
        <span className='amount'>$45,282</span>
        <span className='percentage'>🔼55%</span>
      </div>
    </div>
  )
}

export default SummaryCard
