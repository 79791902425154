import React, {createContext, useState, useContext, Dispatch, SetStateAction} from 'react'
import {WithChildren} from '../../../_metronic/helpers'

type AdvisorFormContextProps = {
  isFormUploading: boolean | undefined
  setIsFormUploading: Dispatch<SetStateAction<boolean>>
  advisorURL: string | undefined
  setAdvisorURL: Dispatch<SetStateAction<string>>
  notes: string | undefined
  setNotes: Dispatch<SetStateAction<string>>
  isAdvisorLogoLoading: boolean | undefined
  setIsAdvisorLogoLoading: Dispatch<SetStateAction<boolean>>
}

const initAdvisorContextPropsState = {
  isFormUploading: false,
  setIsFormUploading: () => {},
  advisorURL: '',
  setAdvisorURL: () => {},
  notes: '',
  setNotes: () => {},
  isAdvisorLogoLoading: false,
  setIsAdvisorLogoLoading: () => {},
}

const AdvisorFormContext = createContext<AdvisorFormContextProps>(initAdvisorContextPropsState)

const useAdvisorFormContext = () => {
  const context = useContext(AdvisorFormContext)
  if (!context) {
    throw new Error('useAdvisorForm must be used within an AdvisorFormProvider')
  }
  return context
}

const AdvisorFormProvider: React.FC<WithChildren> = ({children}) => {
  // Use React.FC and WithChildren
  const [isFormUploading, setIsFormUploading] = useState(false)
  const [advisorURL, setAdvisorURL] = useState('')
  const [notes, setNotes] = useState('')
  const [isAdvisorLogoLoading, setIsAdvisorLogoLoading] = useState(false)

  return (
    <AdvisorFormContext.Provider
      value={{
        isFormUploading,
        setIsFormUploading,
        advisorURL,
        setAdvisorURL,
        notes,
        setNotes,
        isAdvisorLogoLoading,
        setIsAdvisorLogoLoading,
      }}
    >
      {children}
    </AdvisorFormContext.Provider>
  )
}

export {AdvisorFormProvider, useAdvisorFormContext}
