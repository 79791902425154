import {CustomInvestment} from '../../homeComponents/comparePerformance/core/_model'
import {ComparePerformanceAssetsCompoundingGrowth} from './_model'

// calculate custom ROI
export const calculateCustomROI = (futureValue: number, initialDeposit: number) => {
  return ((futureValue - initialDeposit) / initialDeposit) * 100
}

// generate chart data
export const generateComparePerformanceChartData = (
  initialDeposit: number,
  defaultCustomInvestmentFV: number,
  numberOfYears: number,
  compoundingGrowth: ComparePerformanceAssetsCompoundingGrowth[],
  contributions: number,
  contributionFrequency: number,
  customInvestmentsArray: CustomInvestment[]
) => {
  const chartData = compoundingGrowth.map((rate, _index) => {
    const defaultCustomAssetIndex = rate?.assetBarType === 'defaultCustomInvestment' ? _index : null
    const customAssetIndex = rate?.assetBarType === 'customInvestment' ? _index : null
    const customInvestmentId = rate?.assetBarType === 'customInvestment' ? rate?.id : null
    const matchingCustomInvestment = customInvestmentsArray.find(
      (asset) => asset?.custom_id === customInvestmentId
    )
    const customInitialDeposit = matchingCustomInvestment
      ? matchingCustomInvestment?.custom_start_value
      : initialDeposit

    const customInvestmentFV = matchingCustomInvestment
      ? +matchingCustomInvestment?.custom_end_value
      : defaultCustomInvestmentFV

    // gains
    const gains = customInitialDeposit * (rate.value / 100)
    //   future value
    const futureValue = customInitialDeposit + gains

    //   return values
    return {
      id: rate.id,
      value: rate.value,
      customROR: rate.value,
      label: rate.label,
      customLabel: rate.customLabel,
      labelColor: rate.color,
      futureValue:
        _index === defaultCustomAssetIndex || _index === customAssetIndex
          ? customInvestmentFV.toFixed(2)
          : parseFloat(futureValue.toFixed(2)),
      contributions: contributions * contributionFrequency * numberOfYears,
      initialDeposit: customInitialDeposit,
      isLoss:
        _index === defaultCustomAssetIndex || _index === customAssetIndex
          ? customInvestmentFV < customInitialDeposit
          : rate.value < 0,
      numberOfYears: numberOfYears,
      type: rate.type,
      startDate: rate.pastData.startDate,
      startValue: rate.pastData.startValue,
      oldDate: rate.pastData.endDate,
      oldValue: rate.pastData.endValue,
    }
  })

  return chartData
}
