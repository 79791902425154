import React from 'react'

const GreetingCard = () => {
  return (
    <div className='greeting-card mt-20'>
      <span className='greeting-emoji' role='img' aria-label='sun'>
        ☀️
      </span>
      <span className='greeting-text'>
        Assalam aluikum! <br /> Friday 5/17
      </span>
    </div>
  )
}

export default GreetingCard
