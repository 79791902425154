import React, {FormEvent, useState, useRef, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import {emitter} from '@marvelapp/react-ab-test'
import ReactGA from 'react-ga4'
import {toast} from 'react-toastify'
import {STAYTUNED_SECTION, sectionIds} from '../../../constants/AppConstants'
import {ChartExperiment} from '../ABTest/_ABTestConfig'
import {addToWaitlist} from './core/_request'
import useFocus from '../hooks/useFocus'
import FeedbackSection from './feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {motion, useTransform, useScroll} from 'framer-motion'

type StayTunedSectionProps = {
  refProp: React.RefObject<HTMLDivElement>
}

const StayTunedSection: React.FC<StayTunedSectionProps> = ({refProp}) => {
  const [showInput, setShowInput] = useState(false)
  const {inputRef, focusInput} = useFocus()
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  useEffect(() => {
    if (showInput) {
      focusInput()
    }
  }, [showInput, focusInput])

  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const [joinWaitlistToggle, setJoinWaitlistToggle] = useState(0)
  const [waitListEmail, setWaitListEmail] = useState('')
  const [isFinancePro, setIsFinancePro] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleWaitlistInput = () => {
    setShowInput(true)
    setJoinWaitlistToggle(1)
  }
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const isProduction = process.env.NODE_ENV === 'production'
  const cloudTopX = useTransform(scrollYProgress, [0, 1], ['-30%', '20%'])
  const cloudMidX = useTransform(scrollYProgress, [0, 0.6], ['-30%', '30%'])
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // add user to waitlist
    setIsLoading(true)
    try {
      await addToWaitlist(waitListEmail, isFinancePro)
      setIsLoading(false)
      setJoinWaitlistToggle(2)
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsLoading(false)
    }

    // Only track the GA event in production
    if (isProduction) {
      ReactGA.event({
        category: ChartExperiment.name,
        action: emitter.getActiveVariant(ChartExperiment.name),
      })
    }
  }
  const handleWaitlistToggleUI = (joinWaitlistToggle: number) => {
    switch (joinWaitlistToggle) {
      case 1:
        return (
          <>
            <form
              className='d-flex align-items-center flex-column mt-20 pt-6'
              onSubmit={handleSubmit}
            >
              <div className='col-sm-6 col-12 d-flex justify-content-center align-items-center waitlist-input'>
                <input
                  ref={inputRef}
                  type='email'
                  className='form-control form-control-lg form-control-solid py-2'
                  placeholder='Enter your email'
                  required
                  value={waitListEmail}
                  onChange={(e) => setWaitListEmail(e.target.value)}
                />
              </div>
              <label className='mt-5'>
                <input
                  type='checkbox'
                  checked={isFinancePro}
                  onChange={() => setIsFinancePro(!isFinancePro)}
                />
                I'm also a finance pro
              </label>
              <Button
                type='submit'
                className='custom-secondary-button my-6 border-0'
                disabled={isLoading}
              >
                {!isLoading && <span className='indicator-label'>SUBMIT</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </form>
          </>
        )
      case 2:
        return (
          <div className='mb-8 stay-tuned-join'>
            <p className='sub-heading-small primary-text'>THANK YOU</p>
          </div>
        )
      default:
        return (
          <div className='mb-8 stay-tuned-join'>
            <Button className='custom-secondary-button' onClick={toggleWaitlistInput}>
              {STAYTUNED_SECTION.JOIN_WAITLIST}
            </Button>
          </div>
        )
    }
  }

  return (
    <>
      <div
        className={sectionIdToShowInWebViewer !== sectionIds[8] && showInWebViewer ? 'd-none' : ''}
      >
        <motion.section
          ref={targetRef}
          id={sectionIds[10]}
          className={`pt-10 pt-lg-20 position-relative z-index-1`}
          // ref={refProp}
          // id={sectionIds[8]}
        >
          <div className='px-7 px-md-10 px-lg-20 pb-10 pb-lg-15 z-index-1'>
            <h1 className='my-2 mb-6 text-center'>{STAYTUNED_SECTION.STAY_TUNED}</h1>
            <p className='text-center mb-0'>{STAYTUNED_SECTION.IMAGINE}</p>
          </div>
          <div className='px-7 px-md-10 px-lg-20 pt-8 z-index-1'>
            <div>
              <p className='black-small-sub-heading text-center'>{STAYTUNED_SECTION.SAVE_TIME}</p>
              <p className='text-center'>{STAYTUNED_SECTION.NO_NEED}</p>
            </div>
            <div className='my-10 my-md-14'>
              <p className='black-small-sub-heading text-center'>{STAYTUNED_SECTION.CONFIDENT}</p>
              <p className='text-center'>{STAYTUNED_SECTION.HARD_MATH}</p>
            </div>
            <div className='position-relative'>
              <p className='black-small-sub-heading text-center'>{STAYTUNED_SECTION.GET_RETURNS}</p>
              <p className='text-center'>{STAYTUNED_SECTION.INVESTING}</p>
            </div>
            {/* join waitlist UI */}
            {handleWaitlistToggleUI(joinWaitlistToggle)}
            <motion.img
              alt='financial-cloud-white'
              className='financial-cloud-white position-absolute'
              src={STAYTUNED_SECTION.IMAGES.CLOUD_RIGHT}
              style={{x: cloudTopX}}
            />
            <motion.img
              alt='stay-tuned-cloud-right'
              className='stay-tuned-cloud-right position-absolute z-index--1'
              src={STAYTUNED_SECTION.IMAGES.CLOUD_RIGHT}
              style={{x: cloudMidX}}
            />
            <img
              alt='stay-tuned-cloud-left'
              className='stay-tuned-cloud-left position-absolute z-index--1'
              src={STAYTUNED_SECTION.IMAGES.CLOUD_LEFT}
            />
            <img
              alt='stay-tuned-ellipse-right'
              className='d-none d-lg-block stay-tuned-ellipse-right position-absolute z-index--1'
              src={STAYTUNED_SECTION.IMAGES.ELLIPSE_RIGHT}
            />
            <img
              alt='stay-tuned-ellipse-left'
              className='d-none d-lg-flex stay-tuned-ellipse-left position-absolute z-index--1'
              src={STAYTUNED_SECTION.IMAGES.ELLIPSE_LEFT}
            />
            <img
              alt='stay-tuned-bird-left'
              className='position-absolute stay-tuned-bird-left d-none d-xl-block'
              src={STAYTUNED_SECTION.IMAGES.PATH_LEFT}
              data-aos='fade-right'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-easing='ease-in-sine'
            />
            <img
              alt='stay-tuned-bird-right'
              className='stay-tuned-bird-right-image position-absolute d-none d-xl-block'
              src={STAYTUNED_SECTION.IMAGES.PATH_RIGHT}
              data-aos='fade-left'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-easing='ease-in-sine'
            />
          </div>
        </motion.section>
      </div>
      {/* feedback section */}
      {!showInWebViewer ? (
        <section className='mt-7 pe-20 position-relative z-index-2'>
          <FeedbackSection sectionName='stay tuned section' />
        </section>
      ) : (
        ''
      )}
    </>
  )
}

export default StayTunedSection
