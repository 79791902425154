import ProjectionsInput from './ProjectionsInput'
import ProjectionsSection from '../ProjectionsSection'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {useEffect, useRef} from 'react'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {useProjectionsState} from '../../../utils/commonUtils'
import {TYPES_OF_ROR, sectionIds} from '../../../../../constants/AppConstants'
import {fetchAdvisorFormData} from '../../../advisor/core/_request'
import {assets, defaultAsset} from './ProjectionsConfig'
import {InvestmentChartProps, InvestmentTableModel} from '../../../helper/calculator/_model'
import {usePastPerformanceState} from '../../PastPerformance/components/PastPerformanceUtils'

const ARQProjectionsSection: React.FC = () => {
  const {chartData, isDataSet, setTableData, setChartData} = useProjectionsState()

  const {setPastChartData, setPastChartDataOldValues} = usePastPerformanceState()
  // setting toastify suc
  const notifyError = (msg: string) => toast.error(msg)
  const formRef = useRef<HTMLDivElement | null>(null)

  // receiver context
  const {
    setIsSticky,
    isReceiverDataLoading,
    setIsReceiverDataLoading,
    setReceiverFormDataObj,
    sectionIdToShowInWebViewer,
    showInWebViewer,
  } = useReceiverFormContext()

  const {advisor_url} = useParams()

  useEffect(() => {
    const formElement = formRef.current

    const handleScroll = () => {
      if (formElement) {
        const boundingBox = formElement.getBoundingClientRect()
        if (boundingBox.top <= 40) {
          setIsSticky(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }

  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)
  const ProjectionsInputRef = useRef<HTMLDivElement>(null)
  const handleTableDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  const handleChartDataChange = (newData: InvestmentChartProps[]) => {
    if (newData.length) {
      setChartData(newData)
    }
  }
  const handlePastChartDataChange = (newData: InvestmentChartProps[]) => {
    if (newData?.length) {
      setPastChartData(newData)
    }
  }

  const handlePastChartDataOldValuesChange = (newData: InvestmentChartProps[]) => {
    if (newData?.length) {
      // setting past data for old values
      setPastChartDataOldValues(newData)
    }
  }

  // fetching advisor data
  const handleFetchAdvisorData = async () => {
    try {
      if (advisor_url) {
        const res = await fetchAdvisorFormData(advisor_url)
        setReceiverFormDataObj({
          starting_amount: res.data.advisorFormData.starting_amount,
          contributions: res.data.advisorFormData.contributions,
          contributions_frequency: res.data.advisorFormData.contributions_frequency,
          custom_investment_values: res.data.customInvestments,
          years_to_grow: res.data.advisorFormData.years_to_grow,
          greetings: res.data.advisorFormData.greeting_message,
          logo_url: res.data.advisorFormData.logo,
          ARQ_Flip_Gain: res.data.advisorFormData.ARQ_Flip_Gain,
          ARQ_rental_income: res.data.advisorFormData.ARQ_rental_income,
          ARQ_rental_contribution: res.data.advisorFormData.ARQ_rental_contribution,
          ARQ_appreciation: res.data.advisorFormData.ARQ_appreciation,
          ARQ_property_worth: res.data.advisorFormData.ARQ_property_worth,
          // as we are showing the distributor's percent so that's why deducting 100 from management_fee
          management_fee: 100 - res.data.advisorFormData.management_fee,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
          custom_email: res.data.advisorFormData.custom_email,
          selected_bars: res.data.advisorFormData.selected_bars,
          past_selected_bars: res.data.advisorFormData.past_selected_bars,
          customer_actual: res.data.advisorFormData.is_customer_actual
            ? res.data.advisorFormData.is_customer_actual
            : false,
          rolled_over_to_phase2: res.data.advisorFormData.is_rolled_over_to_phase2
            ? res.data.advisorFormData.is_rolled_over_to_phase2
            : false,
          property_worth_end_value: res.data.advisorFormData.property_worth_end_value,
          lump_sump_phase1_rent_received: res.data.advisorFormData.lump_sump_phase1_rent_received,
          new_starting_amount: res.data.advisorFormData.new_starting_amount,
        })

        const customAssetIndex = assets.findIndex((asset) => asset.label === 'Custom')
        if (customAssetIndex !== -1 || res.data?.customInvestments.length > 0) {
          // Remove the last index
          assets.pop()

          var arq_fix_n_flip_legacy = {
            id: assets.length + 1, // Incrementing the ID
            label: 'ARQ Fix & Flip (Legacy)',
            dropdownLabel: `ARQ Fix & Flip (Legacy)`,
            customLabel: `2%`,
            color: '#9A258E', // Different color for the second asset
            value: 2, // Different value for the second asset
            type: TYPES_OF_ROR.TYPE_ARQ_FLIP,
            isLegacy: true,
            assetChartType: 'projections',
          }

          // Creating a new object for arq_rental_legacy
          var arq_rental_legacy = {
            id: assets.length + 2, // Incrementing the ID
            label: 'ARQ Rental (Legacy)', // Different label for the third asset
            dropdownLabel: `ARQ Rental (Legacy)`, // Different dropdown label for the third asset
            customLabel: `3%`, // Different custom label for the third asset
            color: '#9A258E', // Different color for the third asset
            value: 3, // Different value for the third asset
            type: TYPES_OF_ROR.TYPE_ARQ_RENTAL,
            isLegacy: true,
            assetChartType: 'projections',
          }
          assets.push(arq_fix_n_flip_legacy, arq_rental_legacy)
          for (const customInvestment of res.data?.customInvestments) {
            const newAsset = {
              id: assets.length + 1,
              label: customInvestment.custom_investment_label,
              dropdownLabel: `(${customInvestment.custom_investment_value}%) ${customInvestment.custom_investment_label}`,
              customLabel: `${customInvestment.custom_investment_value}%`,
              color: '#9A258E',
              value: parseFloat(customInvestment.custom_investment_value),
              type: customInvestment.type_of_ror,
              assetChartType: 'projections',
            }

            assets.push(newAsset)
          }
          // Add custom investments from the customInvestments array
        }
        setReceiverFormDataObj((prevFormData) => ({
          ...prevFormData,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
        }))
        // Process the response here
      } else {
        // Handle the case when advisor_url is undefined or null
      }
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
    } finally {
      setIsReceiverDataLoading(false)
    }
  }

  // for advisor URL API
  useEffect(() => {
    if (advisor_url) {
      handleFetchAdvisorData()
    } else {
      setIsReceiverDataLoading(false)
    }
  }, [])

  return (
    <div className='p-15'>
      <h1 className='text-black'>About ARQ</h1>
      <p>ARQ enables...</p>
      <p>...Latest news...</p>
      <h1 className='text-black'>Questions?</h1>
      <button className='btn btn-primary me-4' onClick={() => {}}>
        REPLY
      </button>
      <button className='btn btn-primary' onClick={() => {}}>
        MEET
      </button>
      <h1 className='text-black mb-10 mt-15'>Tools</h1>
      {/* projections section */}
      {showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[0] ? (
        ''
      ) : (
        <div className='primary-sky-blue-component p-4'>
          <div className='text-select-transparent'>
            <ProjectionsInput
              refProp={ProjectionsInputRef}
              onTableDataChange={handleTableDataChange}
              onChartDataChange={handleChartDataChange}
              onPastChartDataChange={handlePastChartDataChange}
              onPastChartDataOldValuesChange={handlePastChartDataOldValuesChange}
            />
          </div>

          {!isDataSet && !isReceiverDataLoading ? (
            <div className='py-20 py-8 px-7 px-md-10 px-lg-20'>
              <ProjectionsSection
                refProp={ProjectionsSectionRef}
                onTableDataChange={handleDataChange}
                chartData={chartData}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}
export {ARQProjectionsSection}
