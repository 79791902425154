import React, {useState} from 'react'
import PersonaModal from './PersonaModal'

type Persona = {
  id: number
  value: number
  label: string
}

interface CustomDropdownProps {
  data: Persona[]
  //   onEdit: (persona: Persona) => void
  onValueChange: (value: number) => void // Function to call on value change
  sliderColor?: string
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({data, onValueChange, sliderColor}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<string>('Select Persona')
  const [selectedPersonaObj, setSelectedPersonaObj] = useState<any>({})
  const [showPersonaModal, setShowPersonaModal] = useState<any>(false)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleSelect = (label: string, value: number) => {
    setSelectedLabel(label)
    onValueChange(value)
    const selectedPersona = data.find((persona: any) => persona.value === value)
    setIsOpen(false)
  }

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, persona: Persona, value: number) => {
    e.stopPropagation() // Prevents the dropdown from closing
    let editablePersona = data.find((persona: any) => persona.value === value)
    setSelectedPersonaObj(editablePersona)
    setShowPersonaModal(true)
  }

  return (
    <>
      <div className='persona-custom-dropdown'>
        <div onClick={toggleDropdown} className='persona-dropdown-button form-select dropdown' style={{backgroundColor: sliderColor}}>
          {selectedLabel}
        </div>

        {isOpen && (
          <ul className='persona-dropdown-list'>
            {data.map((persona) => (
              <li key={persona.id} className='persona-dropdown-item'>
                <span
                  onClick={() => handleSelect(persona?.label, persona?.value)}
                  className='d-flex w-auto'
                >
                  {persona.label}
                </span>
                {persona.value !== 0 && (
                  <button onClick={(e) => handleEdit(e, persona, persona?.value)} className='bg-transparent'>
                    ✏️
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {showPersonaModal ? (
        <PersonaModal
          show={showPersonaModal}
          onHide={() => setShowPersonaModal(false)}
          title='Edit Inputs'
          inititaldataobj={selectedPersonaObj}
          onSubmit={() => {}}
        />
      ) : null}
    </>
  )
}

export default CustomDropdown
