import {FormEvent, useEffect, useState} from 'react'
import ReactGA from 'react-ga4'
import {ABOUT_PAGE, STAYTUNED_SECTION, sectionIds} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {toast} from 'react-toastify'
import {ChartExperiment} from '../../../modules/ABTest/_ABTestConfig'
import {emitter} from '@marvelapp/react-ab-test'
import useFocus from '../../../modules/hooks/useFocus'
import {Button} from 'react-bootstrap'
import {addToInterestList, updateInterestList} from '../core/_request'
import InterestListButton from './InterestListButton'
import {useSearchParams} from 'react-router-dom'

const JoinInterestList: React.FC = () => {
  const [showInput, setShowInput] = useState(false)
  const {inputRef, focusInput} = useFocus()
  const {INTEREST_LIST} = ABOUT_PAGE
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  useEffect(() => {
    if (showInput) {
      focusInput()
    }
  }, [showInput, focusInput])

  // setting toastify success/error

  return (
    <div
      className={`pt-10 pt-lg-20 position-relative z-index-1 overflow-hidden${
        sectionIdToShowInWebViewer !== sectionIds[15] && showInWebViewer ? 'd-none' : ''
      }`}
      id={sectionIds[16]}
      style={{width: '100%'}}
    >
      <div className='px-7 px-md-10 px-lg-20'>
        <div className='pb-10 pb-lg-15 z-index-1'>
          <div
            className='d-flex justify-content-center align-items-center px-10'
            id='joinInterestListHeader'
          >
            <h1 className='my-2 mb-6 text-center'>{ABOUT_PAGE.FIND_OUT_MORE}</h1>
          </div>
          <p className='text-center mb-0'>{ABOUT_PAGE.BE_ANGEL_DESC}</p>
        </div>

        <div className='pt-8 z-index-1'>
          <div>
            <p className='black-small-sub-heading text-center'>{ABOUT_PAGE.GIVE_IT_A_TRY}</p>
            <p className='text-center'>{ABOUT_PAGE.GIVE_IT_A_TRY_DESC}</p>
          </div>
          <div className='my-10 my-md-14'>
            <p className='black-small-sub-heading text-center'>{ABOUT_PAGE.SUPPORT}</p>
            <p className='text-center'>{ABOUT_PAGE.SUPPORT_DESC}</p>
          </div>
          <div className='position-relative'>
            <p className='black-small-sub-heading text-center'>{ABOUT_PAGE.STAY_TUNED}</p>
            <p className='text-center'>{ABOUT_PAGE.STAY_TUNED_DESC}</p>
          </div>
          {/* join Interest List UI */}
          <InterestListButton />
          <img
            alt='stay-tuned-cloud-right'
            className='stay-tuned-cloud-right position-absolute z-index--1'
            src={STAYTUNED_SECTION.IMAGES.CLOUD_RIGHT}
          />
          <img
            alt='stay-tuned-cloud-left'
            className='stay-tuned-cloud-left-image position-absolute z-index--1'
            src={STAYTUNED_SECTION.IMAGES.CLOUD_LEFT}
          />
          <img
            alt='stay-tuned-ellipse-right'
            className='d-none d-lg-block stay-tuned-ellipse-right position-absolute z-index--1'
            src={STAYTUNED_SECTION.IMAGES.ELLIPSE_RIGHT}
          />

          <img
            alt='stay-tuned-ellipse-left'
            className='d-none d-lg-flex stay-tuned-ellipse-left position-absolute z-index--1'
            src={STAYTUNED_SECTION.IMAGES.ELLIPSE_LEFT}
          />
          <img
            alt='stay-tuned-bird-left'
            className='position-absolute stay-tuned-bird-left d-none d-xl-block'
            src={STAYTUNED_SECTION.IMAGES.PATH_LEFT}
            // data-aos='fade-right'
            // data-aos-duration='1000'
            // data-aos-once='true'
            // data-aos-easing='ease-in-sine'
          />
          <img
            alt='stay-tuned-bird-right'
            className='stay-tuned-bird-right-image position-absolute d-none d-xl-block'
            src={STAYTUNED_SECTION.IMAGES.PATH_RIGHT}
            // data-aos='fade-left'
            // data-aos-duration='1000'
            // data-aos-once='true'
            // data-aos-easing='ease-in-sine'
          />
        </div>
      </div>
    </div>
  )
}

export default JoinInterestList
