import ProjectionsInput from './ProjectionsInput'
import TimeSeriesSection from '../../TSBG/TimeSeriesSection'
import ProjectionsTable from './ProjectionsTable'
import ProjectionsSection from '../ProjectionsSection'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {Fragment, useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {useProjectionsState} from '../../../utils/commonUtils'
import MetaTags from '../../../../../Meta/MetaTags'
import {motion, useScroll, useTransform} from 'framer-motion'
import {
  HERO_SECTION,
  PROJECTIONS_TABLE,
  PROJECTION_INPUTS_SECTION,
  TYPES_OF_ROR,
  sectionIds,
} from '../../../../../constants/AppConstants'
import {Button, Spinner} from 'react-bootstrap'
import {useAdvisorFormContext} from '../../../../context/AdvisorContext'
import {fetchAdvisorFormData} from '../../../advisor/core/_request'
import {assets, defaultAsset} from './ProjectionsConfig'
import HeroSection from '../../HeroSection'
import Pictograph from '../../pictograph/Pictograph'
import {InvestmentChartProps, InvestmentTableModel} from '../../../helper/calculator/_model'
import PastPerformance from '../../PastPerformance/PastPerformance'
import {AdvisorsMeetLinks} from '../../../../../constants/AppConstants'
import {usePastPerformanceState} from '../../PastPerformance/components/PastPerformanceUtils'
import {usePastPerformanceContext} from '../../../../context/PastPerformanceContext'
import {NavbarComponent} from '../../../../../../_metronic/layout/components/header/user/NavbarComponent'

type StickyContainerProps = {
  handleClickStayTuned: () => void
  handleClickProjections: () => void
  refProp: React.RefObject<HTMLDivElement>
}
const StickyContainer: React.FC<StickyContainerProps> = ({
  handleClickStayTuned,
  handleClickProjections,
  refProp,
}) => {
  const {tableData, chartData, isDataSet, handleReplyButton, setTableData, setChartData} =
    useProjectionsState()

  const {pastChartData, setPastChartData, pastChartDataOldValues, setPastChartDataOldValues} =
    usePastPerformanceState()
  // setting toastify suc
  const notifyError = (msg: string) => toast.error(msg)
  const formRef = useRef<HTMLDivElement | null>(null)

  // receiver context
  const {
    setIsSticky,
    isReceiverDataLoading,
    setIsReceiverDataLoading,
    receiverFormDataObj,
    setReceiverFormDataObj,
    sectionIdToShowInWebViewer,
    showInWebViewer,
  } = useReceiverFormContext()

  const navigate = useNavigate()
  const location = useLocation()
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone', 'aghaz']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isAghazInUrl = location.pathname.includes('aghaz')
  const isWaystoneInURL = location.pathname.includes('waystone')
  const currentHostname = window.location.hostname
  const {advisor_url} = useParams()
  const {isFormUploading} = useAdvisorFormContext()
  const navLinks = [
    {path: '#', label: 'Home', scrollToId: 'home-section'},
    {path: '#', label: 'Features', scrollToId: 'features-section'},
    {path: '#', label: 'Pricing', scrollToId: 'pricing-section'},
  ]

  useEffect(() => {
    const formElement = formRef.current

    const handleScroll = () => {
      if (formElement) {
        const boundingBox = formElement.getBoundingClientRect()
        if (boundingBox.top <= 40) {
          setIsSticky(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)
  const ProjectionsInputRef = useRef<HTMLDivElement>(null)
  const handleTableDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  const handleChartDataChange = (newData: InvestmentChartProps[]) => {
    if (newData.length) {
      setChartData(newData)
    }
  }
  const handlePastChartDataChange = (newData: InvestmentChartProps[]) => {
    if (newData?.length) {
      setPastChartData(newData)
    }
  }

  const handlePastChartDataOldValuesChange = (newData: InvestmentChartProps[]) => {
    if (newData?.length) {
      // setting past data for old values
      setPastChartDataOldValues(newData)
    }
  }

  // fetching advisor data
  const handleFetchAdvisorData = async () => {
    try {
      if (advisor_url) {
        const res = await fetchAdvisorFormData(advisor_url)
        setReceiverFormDataObj({
          starting_amount: res.data.advisorFormData.starting_amount,
          contributions: res.data.advisorFormData.contributions,
          contributions_frequency: res.data.advisorFormData.contributions_frequency,
          custom_investment_values: res.data.customInvestments,
          years_to_grow: res.data.advisorFormData.years_to_grow,
          greetings: res.data.advisorFormData.greeting_message,
          logo_url: res.data.advisorFormData.logo,
          ARQ_Flip_Gain: res.data.advisorFormData.ARQ_Flip_Gain,
          ARQ_rental_income: res.data.advisorFormData.ARQ_rental_income,
          ARQ_rental_contribution: res.data.advisorFormData.ARQ_rental_contribution,
          ARQ_appreciation: res.data.advisorFormData.ARQ_appreciation,
          ARQ_property_worth: res.data.advisorFormData.ARQ_property_worth,
          // as we are showing the distributor's percent so that's why deducting 100 from management_fee
          management_fee: 100 - res.data.advisorFormData.management_fee,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
          custom_email: res.data.advisorFormData.custom_email,
          selected_bars: res.data.advisorFormData.selected_bars,
          past_selected_bars: res.data.advisorFormData.past_selected_bars,
          customer_actual: res.data.advisorFormData.is_customer_actual
            ? res.data.advisorFormData.is_customer_actual
            : false,
          rolled_over_to_phase2: res.data.advisorFormData.is_rolled_over_to_phase2
            ? res.data.advisorFormData.is_rolled_over_to_phase2
            : false,
          property_worth_end_value: res.data.advisorFormData.property_worth_end_value,
          lump_sump_phase1_rent_received: res.data.advisorFormData.lump_sump_phase1_rent_received,
          new_starting_amount: res.data.advisorFormData.new_starting_amount,
        })

        const customAssetIndex = assets.findIndex((asset) => asset.label === 'Custom')
        if (customAssetIndex !== -1 || res.data?.customInvestments.length > 0) {
          // Remove the last index
          assets.pop()

          var arq_fix_n_flip_legacy = {
            id: assets.length + 1, // Incrementing the ID
            label: 'ARQ Fix & Flip (Legacy)',
            dropdownLabel: `ARQ Fix & Flip (Legacy)`,
            customLabel: `2%`,
            color: '#9A258E', // Different color for the second asset
            value: 2, // Different value for the second asset
            type: TYPES_OF_ROR.TYPE_ARQ_FLIP,
            isLegacy: true,
            assetChartType: 'projections',
          }

          // Creating a new object for arq_rental_legacy
          var arq_rental_legacy = {
            id: assets.length + 2, // Incrementing the ID
            label: 'ARQ Rental (Legacy)', // Different label for the third asset
            dropdownLabel: `ARQ Rental (Legacy)`, // Different dropdown label for the third asset
            customLabel: `3%`, // Different custom label for the third asset
            color: '#9A258E', // Different color for the third asset
            value: 3, // Different value for the third asset
            type: TYPES_OF_ROR.TYPE_ARQ_RENTAL,
            isLegacy: true,
            assetChartType: 'projections',
          }
          assets.push(arq_fix_n_flip_legacy, arq_rental_legacy)
          for (const customInvestment of res.data?.customInvestments) {
            const newAsset = {
              id: assets.length + 1,
              label: customInvestment.custom_investment_label,
              dropdownLabel: `(${customInvestment.custom_investment_value}%) ${customInvestment.custom_investment_label}`,
              customLabel: `${customInvestment.custom_investment_value}%`,
              color: '#9A258E',
              value: parseFloat(customInvestment.custom_investment_value),
              type: customInvestment.type_of_ror,
              assetChartType: 'projections',
            }

            assets.push(newAsset)
          }
          // Add custom investments from the customInvestments array
        }
        setReceiverFormDataObj((prevFormData) => ({
          ...prevFormData,
          selected_rate_of_return_obj:
            assets.find((asset) => asset.id === res.data.advisorFormData.rate_of_return) ||
            assets[defaultAsset],
        }))
        // Process the response here
      } else {
        // Handle the case when advisor_url is undefined or null
      }
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
    } finally {
      setIsReceiverDataLoading(false)
    }
  }

  // for advisor URL API
  useEffect(() => {
    if (advisor_url) {
      handleFetchAdvisorData()
    } else {
      setIsReceiverDataLoading(false)
    }
  }, [])

  const backgroundImageY = useTransform(scrollYProgress, [0.2, 1], ['0%', '-50%'])
  const opacityOfProjections = useTransform(scrollYProgress, [0, 0.4], [0, 1])
  const projectionsY = useTransform(scrollYProgress, [0, 0.3], ['-30%', '0%'])
  const contentY = useTransform(scrollYProgress, [0, 0.3], ['0%', '14%'])
  const bottomCloudX = useTransform(scrollYProgress, [0.5, 0.8], ['0%', '100%'])
  const hillsY = useTransform(scrollYProgress, [0.5, 0.8], ['0%', '-100%'])
  const mountainsY = useTransform(scrollYProgress, [0.5, 0.7], ['0%', '-100%'])
  const midCloudX = useTransform(scrollYProgress, [0.5, 0.7], ['0%', '-100%'])

  const screenWidth = useScreenWidth()

  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <motion.section className='container-custom container content' ref={targetRef}>
        {screenWidth > 768 && !isReceiverDataLoading ? (
          <>
            <NavbarComponent navLinks={navLinks} />
            {/* parallax */}
            <div
              id={sectionIds[12]}
              className={
                showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[12] ? 'd-none' : ''
              }
            >
              <motion.section className='content-container' id='hero-section'>
                <motion.img
                  src={HERO_SECTION.IMAGES.HERO_IMAGE}
                  alt='mountains'
                  className='d-none d-md-flex'
                  style={{y: backgroundImageY}}
                />
                <motion.div className='d-flex d-md-none position-relative hero-mob-image-container'>
                  <motion.img src={HERO_SECTION.IMAGES.TOP_CLOUD} className='hero-mob-top-cloud' />
                  <motion.img
                    src={HERO_SECTION.IMAGES.BOTTOM_CLOUD}
                    style={{x: bottomCloudX}}
                    className='hero-mob-bottom-cloud'
                  />
                  <motion.img
                    src={HERO_SECTION.IMAGES.MID_CLOUD}
                    className='hero-mob-mid-cloud'
                    style={{x: midCloudX}}
                  />
                  <motion.img
                    src={HERO_SECTION.IMAGES.MOUNTAINS}
                    style={{y: mountainsY}}
                    className='hero-mob-mountains'
                  />
                  <motion.img
                    src={HERO_SECTION.IMAGES.HILLS}
                    style={{y: hillsY}}
                    className='hero-mob-hills'
                  />
                </motion.div>
                <motion.div
                  className='hero-container px-7 px-md-15 z-index-1 mt-2'
                  style={{y: contentY}}
                >
                  <div className='d-flex justify-content-center'>
                    <img className='logo-image' src={HERO_SECTION.IMAGES.LOGO_IMAGE} alt='logo' />
                  </div>
                  <h1 className='mb-md-6 mt-md-16 mt-6 mb-4 text-center'>
                    {isArqInURL && !isWaystoneInURL && !isAghazInUrl
                      ? HERO_SECTION.ARQ_HEADER
                      : isWaystoneInURL
                      ? 'See how you can grow with Waystone'
                      : isAghazInUrl
                      ? 'See how you can grow with Aghaz'
                      : HERO_SECTION.GROWTH}
                  </h1>

                  {!isArqInURL ? (
                    <>
                      {/* desktop view text */}
                      <p className='d-none d-md-block mb-14 text-center'>
                        {currentHostname === 'vividli.ai'
                          ? HERO_SECTION.VIVIDLI_CALCULATOR
                          : HERO_SECTION.CALCULATOR}{' '}
                        {/* <span
                          className='fw-bold pink-text cursor-pointer'
                          onClick={handleClickStayTuned}
                        >
                          {HERO_SECTION.JOIN_WAITLIST}
                        </span> */}
                      </p>
                      {/* mobile view text */}
                      <p className='d-block d-md-none my-4 text-center'>
                        {currentHostname === 'vividli.ai'
                          ? HERO_SECTION.VIVIDLI_CALCULATOR
                          : HERO_SECTION.CALCULATOR_MOBILE}{' '}
                      </p>
                      <p
                        className='d-block d-md-none text-center fw-bold pink-text cursor-pointer'
                        onClick={handleClickStayTuned}
                      >
                        {HERO_SECTION.JOIN_WAITLIST}
                      </p>
                    </>
                  ) : (
                    <>
                      {!advisor_url && (
                        <p className='mt-2 text-center'>
                          {isArqInURL && !isWaystoneInURL && !isAghazInUrl
                            ? HERO_SECTION.ARQ_SUB_HEADING
                            : isWaystoneInURL
                            ? 'Hey! See how Waystone compares. Hit Meet or Reply with any questions. - Pete, Waystone Advisor'
                            : isAghazInUrl
                            ? 'Salaams! See how Aghaz compares. Hit Meet or Reply with any questions. - Khurram, CEO, Aghaz Investments"'
                            : ''}
                        </p>
                      )}{' '}
                      <p className='mt-4 text-center mb-0'>{receiverFormDataObj?.greetings}</p>
                      {/* meet and reply buttons */}
                      <div className='p-3 px-5 text-center'>
                        {advisor_url ? (
                          <>
                            <a
                              href={
                                isAghazInUrl
                                  ? AdvisorsMeetLinks.AGHAZ_LINK
                                  : AdvisorsMeetLinks.ARQ_LINK
                              }
                              target='_blank'
                              rel='noreferrer'
                            >
                              <Button className='custom-secondary-button me-2'>MEET</Button>
                            </a>
                            <Button
                              className='custom-secondary-button mx-2'
                              onClick={() => navigate(`/contact/${advisor_url}`)}
                            >
                              REPLY
                            </Button>
                          </>
                        ) : null}

                        {/* if url contains arq than show reply button */}
                        {!advisor_url ? (
                          <>
                            <a
                              href={
                                isAghazInUrl
                                  ? AdvisorsMeetLinks.AGHAZ_LINK
                                  : AdvisorsMeetLinks.ARQ_LINK
                              }
                              target='_blank'
                              rel='noreferrer'
                              onClick={() => handleReplyButton(true)}
                            >
                              <Button className='custom-secondary-button me-2'>MEET</Button>
                            </a>
                            <Button
                              className='custom-secondary-button mx-2'
                              disabled={isFormUploading}
                              onClick={() => handleReplyButton(false)}
                            >
                              {!isFormUploading && <span className='indicator-label'>REPLY</span>}
                              {isFormUploading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Please wait...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}

                  <div className='d-flex flex-column justify-content-center align-items-center mt-12'>
                    <h2>{HERO_SECTION.EXPLORE}</h2>
                    <img
                      alt='explore-arrow'
                      className='explore-arrow-img mt-2'
                      src={HERO_SECTION.IMAGES.EXPLORE_ARROW}
                      onClick={handleClickProjections}
                    />
                  </div>
                </motion.div>
              </motion.section>
            </div>

            {/* parent motion div */}
            <motion.div
              style={{opacity: opacityOfProjections, y: projectionsY}}
              ref={ProjectionsSectionRef}
            >
              {/* projections input and chart */}
              {showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[0] ? (
                ''
              ) : (
                <>
                    <ProjectionsInput
                      refProp={ProjectionsInputRef}
                      onTableDataChange={handleTableDataChange}
                      onChartDataChange={handleChartDataChange}
                      onPastChartDataChange={handlePastChartDataChange}
                      onPastChartDataOldValuesChange={handlePastChartDataOldValuesChange}
                    />

                  {!isDataSet && !isReceiverDataLoading ? (
                    <motion.section
                      className={`d-none d-md-block py-20 py-8 px-7 px-md-10 px-lg-20`}
                    >
                      <motion.div>
                        <ProjectionsSection
                          refProp={ProjectionsSectionRef}
                          onTableDataChange={handleDataChange}
                          chartData={chartData}
                        />
                      </motion.div>
                    </motion.section>
                  ) : (
                    ''
                  )}
                </>
              )}

              {/* tsbg-chart */}
              <div
                id={sectionIds[1]}
                className={
                  showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[1] ? 'd-none' : ''
                }
              >
                <motion.section className='d-none d-md-block py-20 py-8 px-7 px-md-10 px-lg-20'>
                  <motion.div>
                    <TimeSeriesSection />
                  </motion.div>
                </motion.section>
              </div>

              {/* projection-table */}
              <div
                id={sectionIds[2]}
                className={
                  sectionIdToShowInWebViewer !== sectionIds[2] && showInWebViewer ? 'd-none' : ''
                }
              >
                <div className='d-none d-md-block py-md-20 py-8 px-7 px-md-10 px-lg-20 mt-10'>
                  <h1 className='my-2 mb-6 text-center z-index-1'>{PROJECTIONS_TABLE.TITLE}</h1>
                  <ProjectionsTable tableData={tableData} />
                </div>
              </div>

              {/* pictograph */}
              <div
                id={sectionIds[3]}
                className={
                  showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[3] ? 'd-none' : ''
                }
              >
                <Pictograph />
              </div>

              {advisor_url && pastChartData.length !== 0 ? (
                <PastPerformance
                  pastChartData={pastChartData}
                  pastChartDataOldValues={pastChartDataOldValues}
                />
              ) : null}

              {/* end of parent motion div */}
            </motion.div>
          </>
        ) : (
          <div className='d-none d-md-block text-center mt-10'>
            <Spinner animation='grow' style={{background: '#9b258f'}} />
          </div>
        )}

        <section className='d-block d-md-none px-sm-10'>
          {screenWidth < 768 && !isReceiverDataLoading ? (
            <>
              <div className={showInWebViewer ? 'd-none' : ''}>
                <HeroSection
                  handleClickProjections={handleClickProjections}
                  handleClickStayTuned={handleClickStayTuned}
                  refProp={ProjectionsSectionRef}
                />
              </div>
              <div
                className={
                  sectionIdToShowInWebViewer !== sectionIds[0] && showInWebViewer ? 'd-none' : ''
                }
              >
                <div className='px-6 text-select-transparent'>
                    <ProjectionsInput
                      refProp={ProjectionsInputRef}
                      onTableDataChange={handleTableDataChange}
                      onChartDataChange={handleChartDataChange}
                      onPastChartDataChange={handlePastChartDataChange}
                      onPastChartDataOldValuesChange={handlePastChartDataOldValuesChange}
                    />

                  <div className='py-20'>
                    <ProjectionsSection
                      refProp={ProjectionsSectionRef}
                      onTableDataChange={handleDataChange}
                      chartData={chartData}
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  sectionIdToShowInWebViewer !== sectionIds[1] && showInWebViewer
                    ? 'd-none'
                    : 'px-6'
                }
              >
                <TimeSeriesSection />
              </div>
              <div
                className={
                  sectionIdToShowInWebViewer !== sectionIds[2] && showInWebViewer
                    ? 'd-none'
                    : 'px-6'
                }
              >
                <h1 className='my-2 mb-6 text-center z-index-1 '>{PROJECTIONS_TABLE.TITLE}</h1>
                <ProjectionsTable tableData={tableData} />
              </div>
              <div
                id={sectionIds[3]}
                className={
                  showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[3] ? 'd-none' : ''
                }
              >
                <Pictograph />
              </div>
              {advisor_url && pastChartData.length !== 0 ? (
                <PastPerformance
                  pastChartData={pastChartData}
                  pastChartDataOldValues={pastChartDataOldValues}
                />
              ) : null}
            </>
          ) : (
            ''
          )}
        </section>
        <div ref={formRef}></div>
      </motion.section>
    </>
  )
}
export {StickyContainer}
