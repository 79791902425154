import axios from 'axios'
const ADD_TO_WAITLIST = 'waitlist/add-user'

// fetching waitlist
export function addToWaitlist(email: string, is_finance_pro: boolean) {
  return axios.post(ADD_TO_WAITLIST, {
    email,
    is_finance_pro,
  })
}
