import {memo, useEffect, useRef} from 'react'
import SBLOCSection from '../../modules/homeComponents/SBLOC/SBLOCSection'
import {useSBLOCContext} from '../../context/SBLOCContext'
import {useLocation} from 'react-router-dom'
import {useSBLOCProjections} from '../../modules/utils/sblocUtils'
import {fetchSharedSBlocDataArray} from '../../modules/advisor/core/_request'
import IBLOCTable from '../../modules/homeComponents/IBLOC/Components/IBLOCTable'
import IBLOCSection from '../../modules/homeComponents/IBLOC/IBLOCSection'
import _ from 'lodash'
const IBLOCPage: React.FC = (props) => {
  const isFirstRender = useRef(true)
  const location = useLocation()
  const {isFullView} = useSBLOCContext()
  
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]

  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )

 
  return (
    <section
      className={`${!isFullView ? 'container-custom container' : 'container-custom-IBLOC'} ${
        isWayStoneStyledInUrl ? 'container-waystone hide-scroll-bar' : ''
      } content padding-bottom-iframe`}
    >
      <IBLOCSection />
    </section>
  )
}

export default memo(IBLOCPage)
