import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'
const CreditCardDeptToolTip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  return (
    <div className='custom-tooltip'>
      {/* date */}
      <p className='paragraph-small label-main'>{data.date}</p>

      {/* debt  */}
      <p className='paragraph-x-small mb-0 label-borrow'>
        Total Debt : <span className='label-bold'>{`${data.totalDept.toFixed(1)}%`}</span>
      </p>

      {/* interest */}
      <p className='paragraph-x-small mb-0 label-borrow'>
        Total Interest On Card Balance :{' '}
        <span className='label-bold'>{toUSD(data.totalInterest.toFixed(2))}</span>
      </p>

      {/* card balance */}
      {data.cardBalance ? (
        <p className='paragraph-x-small mb-0 label-initial-deposit'>
          Starting Card Balance:{' '}
          <span className='label-bold'>{toUSD(data.cardBalance ? data.cardBalance : 0)}</span>
        </p>
      ) : null}
    </div>
  )
}

export default CreditCardDeptToolTip
