import {BEGINNERS_SECTION, sectionIds} from '../../../constants/AppConstants'
import {useLocation} from 'react-router-dom'
import FeedbackSection from './feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import InterestListButton from '../../pages/about/components/InterestListButton'

interface ExplainHardConceptsProps {
  subHeading: string
  paragraph: string
  secondParagraph: string
}

const ExplainHardConcepts = (props: ExplainHardConceptsProps) => {
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  return (
    <div
      className={
        sectionIdToShowInWebViewer !== sectionIds[19] && showInWebViewer
          ? 'd-none overflow-hidden'
          : 'overflow-hidden'
      }
      style={{width: '100%'}}
    >
      <section className='position-relative' id={sectionIds[8]}>
        <div className='px-7 px-md-10 px-lg-20 d-flex flex-column justify-content-center align-items-center'>
          {/* cloud-small-screen */}
          <img
            className='d-block d-lg-none'
            style={{width: '400px'}}
            src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
            alt='cloud-big'
          />
          <h2
            className='my-2 mt-10 fw-bolder position-relative z-index-1'
            style={{textAlign: 'center'}}
          >
            Explain hard concepts using visuals
          </h2>
          <img
            className='my-2 mb-4 mb-md-5 mb-lg-10 text-center hard-concept-gif-img'
            src={toAbsoluteUrl('/media/home/hard-concepts.gif')}
            alt='Descriptive Text for GIF'
          />
          {/* cloud-small-screen */}
          <img
            className='d-block d-lg-none'
            src={BEGINNERS_SECTION.IMAGES.CLOUD_SMALL}
            alt='cloud-big'
          />
        </div>
        {/* cloud-large-screen */}
        <img
          className='cloud-about-large d-none d-lg-block'
          src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
          alt='cloud-big'
        />
        <img
          className='cloud-about-small d-none d-lg-block'
          src={BEGINNERS_SECTION.IMAGES.CLOUD_SMALL}
          alt='cloud-big'
        />
      </section>
      <InterestListButton />
    </div>
  )
}

export default ExplainHardConcepts
