import {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {contributionAndDividendsFrequency} from '../../projections/components/ProjectionsConfig'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {useSBLOCContext} from '../../../../context/SBLOCContext'
import {Button} from 'react-bootstrap'
import {FaEdit} from 'react-icons/fa'
import {useLocation} from 'react-router-dom'

interface PersonaModalProps {
  show: boolean
  onHide: () => void
  inititaldataobj: {
    id: number
    label: string
    value: number
    starting_amount: number
    asset_rate_of_return: number
    years_to_grow: number
    borrowed_amount: number
    annualized_interest_rate: number
    re_invested_amount: number
    re_invest_rate_of_return: number
    cash_flow_that_year: number
    cash_flow_frequency: number
  }
  title: string
  onSubmit: (values: any) => void
}
// form schema
const cardSchema = Yup.object().shape({
  startingAmount: Yup.number(),
  ROR: Yup.number(),
  years_to_grow: Yup.number(),
  borrowedAmount: Yup.number(),
  APR: Yup.number(),
  reInvestedAmount: Yup.number(),
  reInvestedRor: Yup.number(),
  cash_flow_that_year: Yup.number(),
  cash_flow_frequency: Yup.number(),
  personaLabel: Yup.string(),
})
const getInitialValues = (inititaldataobj: PersonaModalProps['inititaldataobj']) => ({
  startingAmount: inititaldataobj.starting_amount || '',
  ROR: inititaldataobj.asset_rate_of_return || '',
  years_to_grow: inititaldataobj.years_to_grow || '',
  borrowedAmount: inititaldataobj.borrowed_amount || '',
  APR: inititaldataobj.annualized_interest_rate || '',
  reInvestedAmount: inititaldataobj.re_invested_amount || 0,
  reInvestedRor: inititaldataobj.re_invest_rate_of_return || '',
  cash_flow_that_year: inititaldataobj.cash_flow_that_year || 0,
  cash_flow_frequency: inititaldataobj.cash_flow_frequency || 1,
  personaLabel: inititaldataobj.label || 'Persona',
})

const PersonaModal: React.FC<PersonaModalProps> = (props) => {
  const {personaData, setPersonaData} = useSBLOCContext()
  const initialValues = getInitialValues(props.inititaldataobj)
  const screenWidth = useScreenWidth()
  const [isEditing, setIsEditing] = useState(false)
  const [isChangeTitle, setIsChangeTitle] = useState(false)
  const location = useLocation()
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  // Helper function outside the component
  const updatePersona = (personas: any, updateValue: any, newAttributes: any) => {
    return personas.map((persona: any) => {
      if (persona.value === updateValue) {
        // Update all specified attributes
        return {...persona, ...newAttributes}
      }
      return persona
    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: cardSchema,
    onSubmit: async (values) => {
      console.log(values)
      const newAttributes = {
        annualized_interest_rate: values?.APR,
        asset_rate_of_return: values?.ROR,
        borrowed_amount: values?.borrowedAmount,
        re_invest_rate_of_return: values?.reInvestedRor,
        re_invested_amount: values?.reInvestedAmount,
        starting_amount: values?.startingAmount,
        years_to_grow: values?.years_to_grow,
        cash_flow_that_year: values?.cash_flow_that_year,
        cash_flow_frequency: values?.cash_flow_frequency,
        label: values?.personaLabel,
      }
      //   props.onSubmit(values)
      setPersonaData((prevPersonas: any) => {
        const updatedPersonas = updatePersona(
          prevPersonas,
          props.inititaldataobj?.value,
          newAttributes
        )
        // Update local storage after updating the state
        localStorage.setItem('personasData', JSON.stringify(updatedPersonas))
        return updatedPersonas
      })

      !isChangeTitle && props.onHide()
      setIsChangeTitle(false)
    },
  })
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('cash_flow_frequency', value)
  }
  return (
    <>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
          <Modal.Header closeButton>
            {isEditing ? (
              <>
                <div className='input-container d-flex justify-content-center align-items-center'>
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <input
                    id='number-of-years'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Enter Custom Name'
                    {...formik.getFieldProps('personaLabel')}
                    maxLength={18}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault() // Prevent the default action to avoid form submission if inside form
                        setIsChangeTitle(true)
                        setIsEditing(false)
                        formik.handleSubmit()
                      }
                    }}
                  />
                  <Button
                    className={`input-field-button me-1 ${isWayStoneStyledInUrl ? '' : ''}`}
                    style={{backgroundColor: isWayStoneStyledInUrl ? '#B86746' : ''}}
                    onClick={() => {
                      formik.handleSubmit()
                      setIsChangeTitle(true)
                      setIsEditing(false)
                    }}
                  >
                    ✓
                  </Button>
                </div>
              </>
            ) : (
              <div className='d-flex align-items-center'>
                <Modal.Title
                  id='contained-modal-title-vcenter'
                  className='heading sub-heading-x-small me-3'
                >
                  {!isChangeTitle ? formik.values?.personaLabel : props?.inititaldataobj?.label}
                </Modal.Title>
                <FaEdit onClick={() => setIsEditing(true)} style={{cursor: 'pointer'}} />
              </div>
            )}
          </Modal.Header>
          <Modal.Body>
            <div className='row m-0'>
              {/* startingAmount  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.startingAmount && formik.errors.startingAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('startingAmount')}
                  />
                </div>
                {formik.touched.startingAmount && formik.errors.startingAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.startingAmount}</div>
                  </div>
                )}
              </div>
              {/* ROR */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>ROR</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.ROR && formik.errors.ROR ? 'error-border' : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('ROR')}
                  />
                </div>
                {formik.touched.ROR && formik.errors.ROR && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.ROR}</div>
                  </div>
                )}
              </div>
              {/* years to grow */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Years To Grow</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.years_to_grow && formik.errors.years_to_grow
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('years_to_grow')}
                  />
                </div>
                {formik.touched.years_to_grow && formik.errors.years_to_grow && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.years_to_grow}</div>
                  </div>
                )}
              </div>
              {/* borrowedAmount */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Borrowed Amount</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.borrowedAmount && formik.errors.borrowedAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('borrowedAmount')}
                  />
                </div>
                {formik.touched.borrowedAmount && formik.errors.borrowedAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.borrowedAmount}</div>
                  </div>
                )}
              </div>

              {/* APR */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>APR</p>
                </div>

                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.APR && formik.errors.APR ? 'error-border' : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('APR')}
                  />
                </div>
                {formik.touched.APR && formik.errors.APR && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.APR}</div>
                  </div>
                )}
              </div>
              {/* Re Invested Amount */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re Invested Amount</p>
                </div>

                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.reInvestedAmount && formik.errors.reInvestedAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('reInvestedAmount')}
                  />
                </div>
                {formik.touched.reInvestedAmount && formik.errors.reInvestedAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.reInvestedAmount}</div>
                  </div>
                )}
              </div>

              {/* Re Invested ROR  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re Invested ROR</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.reInvestedRor && formik.errors.reInvestedRor
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('reInvestedRor')}
                  />
                </div>
                {formik.touched.reInvestedRor && formik.errors.reInvestedRor && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>* {formik.errors.reInvestedRor}</div>
                  </div>
                )}
              </div>
              {/* cash flow */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Cash Flow</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('cash_flow_that_year')}
                  />
                </div>
                {formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>* {formik.errors.cash_flow_that_year}</div>
                  </div>
                )}
              </div>

              {/* frequency  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='col-5 col-md-6 fv-row projections-select dropdown-bg-color mb-0 mb-lg-0 pe-0 pe-md-2'>
                  <p className='ps-1 m-0 fields-label time-frame'>Time Frame</p>
                  <select
                    id='compounding-frequency'
                    className={`form-select dropdown ${screenWidth < 768 ? 'ms-n2' : ''}`}
                    {...formik.getFieldProps('cash_flow_frequency')}
                    onChange={handleCompoundingFreqChange}
                  >
                    {contributionAndDividendsFrequency.map((option) => {
                      return (
                        <option className='px-2' key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                // disabled={!formik.getFieldProps('APR').value}
                style={{
                  backgroundColor: isWayStoneStyledInUrl ? '#B86746' : '',
                }}
              >
                <span
                  className='indicator-progress'
                  style={{
                    display: 'block',
                  }}
                >
                  Save
                </span>
              </button>
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
    </>
  )
}
export default PersonaModal
