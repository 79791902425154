import {useEffect} from 'react'
import ProjectionSlider from '../../../../components/projectionSlider/ProjectionSlider'
import {INPUT_BOX_TOOLTIPS, PAST_PERFORMANCE} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import CustomOverlayTooltip from '../projections/components/CustomOverlayTooltip'
import PastPerformanceChart from './components/PastPerformanceChart'
import {InvestmentChartProps} from './components/_model'
import {sectionIds} from '../../../../constants/AppConstants'
import {usePastPerformanceContext} from '../../../context/PastPerformanceContext'

type PastPerformanceSectionProps = {
  pastChartData: InvestmentChartProps[]
  pastChartDataOldValues: InvestmentChartProps[]
}

const PastPerformance: React.FC<PastPerformanceSectionProps> = ({
  pastChartData,
  pastChartDataOldValues,
}) => {
  const {receiverFormDataObj} = useReceiverFormContext()
  const {PPYearsAgo, setPPYearsAgo} = usePastPerformanceContext()

  return (
    <>
      <section className='px-7 px-md-10 px-lg-20 pb-20' id={sectionIds[17]}>
        <h1 className='my-2 mb-6 text-center z-index-1'>{PAST_PERFORMANCE.title}</h1>
        {/* sentence  */}
        <p className='text-center px-4 mb-0 glassmorphism-container py-4 py-lg-0 mb-6'>
          How <span className='pink-text fw-600'>${receiverFormDataObj?.starting_amount}</span> in{' '}
          <span className='pink-text fw-600'>
            {receiverFormDataObj?.selected_rate_of_return_obj.dropdownLabel}
          </span>{' '}
          would have done, had you invested{' '}
          <span className='pink-text fw-600'>{PPYearsAgo} months</span> ago today.
        </p>
        {/* Years ago */}
        <div className='d-none d-md-block col-5 col-sm-5 col-md-2 fv-row mb-0 mb-lg-0 pe-0'>
          <div className='d-flex align-items-baseline'>
            <p className='ps-1 m-0 fields-label'>Months Ago</p>
            <CustomOverlayTooltip
              content={INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
              className='tooltip-logo'
            />
          </div>
          <div className='input-container d-flex justify-content-center align-items-center'>
            <p className='m-0 currency-symbol d-none'>$</p>
            <input
              id='number-of-years'
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder='e.g 10'
              value={PPYearsAgo}
              onChange={(e) => setPPYearsAgo(+e.target.value)}
            />
          </div>
          <ProjectionSlider
            value={PPYearsAgo}
            minValue={1}
            maxValue={24}
            isMoney={false}
            isPastYears={true}
            onRangeChange={(value: any) => setPPYearsAgo(parseInt(value!))}
          />
        </div>
        <PastPerformanceChart
          data={pastChartDataOldValues} // sending pastChartData as pastChartDataOldValues for showing pastChartDataOldValues in chart as bars
          oldData={pastChartData} // sending pastChartDataOldValues as pastChartData for showing pastChartData in chart as benchmarkk
          handleCustomBar={() => console.log('handle custom bar')}
          handleReply={() => console.log('handle reply')}
        />
      </section>
    </>
  )
}

export default PastPerformance
