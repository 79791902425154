import {CreditCardDeptChartData} from '../../homeComponents/CreditCardDept/components/_model'
import {calculateFutureValue} from './CommonCalculatorFunctions'

// calculate dept
export const calculateCreditCardDept = (
  cardBalance: number, //same as initial deposit
  cardAPR: number,
  period: number //number of months
): number => {
  let compoundingGrowth = cardAPR
  let futureValue = calculateFutureValue(cardBalance, compoundingGrowth, 12, period, 0)
  return futureValue
}

export const calculatePeriodicCreditCardDept = (
  cardBalance: number,
  cardAPR: number,
  numberOfMonths: number
): CreditCardDeptChartData[] => {
  // Initialize an array to store the credit card debt data
  const periodicCreditCardData: CreditCardDeptChartData[] = []

  // Initialize variables for future values, total debt, and total interest
  let futureValue = 0
  let futureValueWithoutCardBalance = 0
  let totalDept = 0
  let totalInterest = 0

  // Loop through the specified number of months
  for (let i = 1; i <= numberOfMonths; i++) {
    // Calculate the date as "month year"
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + i - 1)
    // Output will be like "January 2024 i.e month and year"
    const date = currentDate.toLocaleDateString('en-US', {year: 'numeric', month: 'long'})
    // Calculate the future credit card debt and total debt
    futureValue = calculateCreditCardDept(cardBalance, cardAPR, i / 12)
    futureValueWithoutCardBalance = futureValue - cardBalance
    totalDept = (futureValueWithoutCardBalance / cardBalance) * 100
    totalInterest = futureValueWithoutCardBalance

    // Create an object with the calculated data and add it to the array
    periodicCreditCardData.push({
      date,
      totalDept,
      totalInterest,
      cardBalance,
      futureValue,
      futureValueWithoutCardBalance,
    })
  }

  return periodicCreditCardData
}
