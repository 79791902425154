import GreetingCard from './GreetingCard'
import PropertyList from './PropertyLIst'

const ARQStatus = () => {
  const properties = [
    {
      id: 1,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    {
      id: 2,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    {
      id: 3,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    {
      id: 4,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    {
      id: 5,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    {
      id: 6,
      imageUrl:
        'https://images.arrived.com/tr:h-468,w-956,q-100,c-at_max/properties/566/3dea7874e49fa6714a7aee28ae486cf8.jpg',
      value: '1556.50',
    },
    // Add more properties as needed
  ]

  return (
    <>
      <div className='d-flex justify-content-center align-items-center mt-5'>
        <GreetingCard />
      </div>
      <div className='py-20'>
        {' '}
        <div
          data-aos='fade-up'
          data-aos-anchor-placement='top-bottom'
          data-aos-duration='1000'
          data-aos-delay='500'
        >
          <PropertyList properties={properties} />{' '}
        </div>
      </div>
    </>
  )
}

export default ARQStatus
