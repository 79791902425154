import React from 'react'

// Define an interface for the component props
interface MetricCardProps {
  label: string
  value: string
  subtext: string
}

const MetricCard: React.FC<MetricCardProps> = ({label, value, subtext}) => {
  return (
    <div className='metric-card'>
      <div className='metric-label'>{label}</div>
      <div className='metric-value'>{value}</div>
      <div className='metric-subtext'>{subtext}</div>
    </div>
  )
}

export default MetricCard
