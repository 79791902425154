import {useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {CreditCardDept} from '../homeComponents/CreditCardDept/components/_model'
import {calculatePeriodicCreditCardDept} from '../helper/calculator/CreditCardDeptCalculator'

export function useCreditCardDept() {
  // Define the validation schema using Yup
  const CreditCardDeptSchema = Yup.object().shape({
    cardBalance: Yup.number()
      .min(0, 'Please enter an Card Balance greater than or equal to $0')
      .max(10000000, 'Please enter an Card Balance less than or equal to $10,000,000')
      .typeError('Please enter an Card Balance greater than or equal to $0')
      .required('Please enter the Card Balance'),

    cardAPR: Yup.number()
      .min(0, 'Please enter Card APR value greater than or equal to $0')
      .max(100, 'Please enter Card APR value less than or equal to 100%')
      .typeError('Please enter Card APR value greater than or equal to 0%')
      .required('Please enter the asset rate of return value'),

    numberOfMonths: Yup.number()
      .min(1, 'Please enter a number of months greater than or equal to 1')
      .max(36, 'Please enter a number of months less than or equal to 100')
      .typeError('Please enter number of months greater than or equal to 1')
      .required('Please enter the number of months to grow'),
  })

  // Define the initial form values
  const initialValues: CreditCardDept = {
    cardBalance: 1000,
    cardAPR: 18,
    numberOfMonths: 6,
  }
  const isFirstRender = useRef(true)

  const generateData = (values: CreditCardDept) => {
    return calculatePeriodicCreditCardDept(
      values.cardBalance,
      values.cardAPR,
      values.numberOfMonths
    )
  }

  const formik = useFormik<CreditCardDept>({
    initialValues,
    enableReinitialize: true,
    validationSchema: CreditCardDeptSchema,
    onSubmit: () => {},
  })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return {
    isFirstRender,
    handleKeyPress,
    formik,
    initialValues,
    generateData,
  }
}
