import {memo, useEffect, useRef} from 'react'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {COMPARE_PERFORMANCE_SECTION} from '../../../../../constants/AppConstants'
import {useComparePerformanceState} from '../../../utils/ComparePerformanceUtils'
import CustomOverlayTooltip from '../../projections/components/CustomOverlayTooltip'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {comparePerformanceAssets} from '../../projections/components/ProjectionsConfig'

const ComparePerformanceInputs: React.FC = () => {
  // destructuring compare performance utils state
  const {
    formik,
    handleKeyPress,
    calculateNoOfYears,
    investmentNamesObj,
    handleAddInvestment,
    handleRemoveInvestment,
    handleInvestmentChange,
  } = useComparePerformanceState()

  // first render detector
  const isFirstRender = useRef(true)

  // no. of years
  const noOfYears = calculateNoOfYears()

  // find last date of prev month
  const lastDateOfPrevMonth = moment().subtract(1, 'months').endOf('month').toDate()
  // start date
  const currentDate = moment(lastDateOfPrevMonth).subtract(1, 'years').toDate()
  // five years old date from today
  const fiveYearsOldDate = moment().subtract(5, 'years').toDate()

  // useEffect for form submission
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      formik.handleSubmit()
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [formik.values])

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className='form'
        id='compare-performance-form'
      >
        <div className='row m-0'>
          {/* Start date field */}
          <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
            <div className='d-flex align-items-baseline'>
              <p className='ps-1 m-0 fields-label'>{COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD1}</p>
              <CustomOverlayTooltip content='start_date' className='tooltip-logo' />
            </div>
            <div
              className={`input-container d-flex justify-content-center align-items-center ${
                formik.touched.start_date && formik.errors.start_date ? 'error-border' : ''
              }`}
            >
              <DatePicker
                minDate={fiveYearsOldDate}
                maxDate={lastDateOfPrevMonth}
                className='form-control form-control-lg form-control-solid date-picker-custom-styles'
                placeholderText={`${moment()
                  .subtract(1, 'months')
                  .endOf('month')
                  .format('MM/DD/YYYY')}`}
                selected={
                  formik.values.start_date &&
                  moment(formik.values.start_date, 'YYYY-MM-DD').isValid()
                    ? moment(formik.values.start_date).toDate()
                    : null
                }
                onChange={(date: Date) => {
                  if (date && moment(date).isValid()) {
                    // set the date only if it's a valid date object
                    formik.setFieldValue('start_date', moment(date).format('YYYY-MM-DD'))
                  } else {
                    formik.setFieldValue('start_date', moment().format('MM-DD-YYYY'))
                  }
                }}
              />
            </div>
          </div>

          {/* Start value field */}
          <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
            <div className='d-flex align-items-baseline'>
              <p className='ps-1 m-0 fields-label'>{COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD2}</p>
              <CustomOverlayTooltip content='start_value' className='tooltip-logo' />
            </div>
            <div
              className={`input-container d-flex justify-content-center align-items-center ${
                formik.touched.start_value && formik.errors.start_value ? 'error-border' : ''
              }`}
            >
              <CustomInputField
                id='start-date'
                className='form-control form-control-lg form-control-solid'
                placeholder=' e.g $1,000'
                decimalsLimit={2}
                maxLength={9}
                value={formik.values.start_value}
                onValueChange={(value: any) => {
                  formik.setFieldValue('start_value', value || '')
                }}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>

          {/* End date field */}
          <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
            <div className='d-flex align-items-baseline'>
              <p className='ps-1 m-0 fields-label'>{COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD3}</p>
              <CustomOverlayTooltip content='end_date' className='tooltip-logo' />
            </div>
            <div
              className={`input-container d-flex justify-content-center align-items-center ${
                formik.touched.end_date && formik.errors.end_date ? 'error-border' : ''
              }`}
            >
              <DatePicker
                minDate={fiveYearsOldDate}
                maxDate={moment().toDate()}
                className='form-control form-control-lg form-control-solid date-picker-custom-styles'
                placeholderText={`${moment().format('MM/DD/YYYY')}`}
                selected={
                  formik.values.end_date && moment(formik.values.end_date, 'YYYY-MM-DD').isValid()
                    ? moment(formik.values.end_date).toDate()
                    : null
                }
                onChange={(date: Date) => {
                  if (date && moment(date).isValid()) {
                    // set the date only if it's a valid date object
                    formik.setFieldValue('end_date', moment(date).format('YYYY-MM-DD'))
                  } else {
                    formik.setFieldValue(
                      'end_date',
                      moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY')
                    )
                  }
                }}
              />
            </div>
          </div>

          {/* End value field */}
          <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
            <div className='d-flex align-items-baseline'>
              <p className='ps-1 m-0 fields-label'>{COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD4}</p>
              <CustomOverlayTooltip content='end_value' className='tooltip-logo' />
            </div>
            <div
              className={`input-container d-flex justify-content-center align-items-center ${
                formik.touched.end_value && formik.errors.end_value ? 'error-border' : ''
              }`}
            >
              <CustomInputField
                id='end-date'
                className='form-control form-control-lg form-control-solid'
                placeholder=' e.g $1,000'
                decimalsLimit={2}
                maxLength={9}
                value={formik.values.end_value}
                onValueChange={(value: any) => {
                  formik.setFieldValue('end_value', value || '')
                }}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>

          {/* Investments to compare dropdown */}
          <div className='col-lg-3'>
            <div className='d-flex align-items-baseline'>
              <p className='ps-1 m-0 fields-label'>{COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD5}</p>
              <CustomOverlayTooltip content='Investments to Compare' className='tooltip-logo' />
            </div>
            <div
              className={`input-container ps-0 ${
                formik.touched.investments_to_compare && formik.errors.investments_to_compare
                  ? 'error-border'
                  : ''
              }`}
            >
              <Select
                closeMenuOnSelect={false}
                isMulti
                // value={formik.values.investments_to_compare}
                options={investmentNamesObj}
                className='custom-select-styles'
                onChange={(selectedOption) => {
                  formik.setFieldValue('investments_to_compare', selectedOption)
                }}
              />
            </div>
          </div>
          {formik.values.custom_investments.length < 2 && comparePerformanceAssets.length ? (
            <div className='col-1 d-flex align-items-center'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark ms-3'
                onClick={handleAddInvestment}
              >
                <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2x' />
              </div>
            </div>
          ) : null}

          {/* custom investments */}
          <div className='mt-2 px-0'>
            {formik.values.custom_investments.map((investment, index) => (
              <>
                <div key={index} className='row m-0 mb-3'>
                  {/* custom start date field */}
                  <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>
                        {COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD1}
                      </p>
                      <CustomOverlayTooltip content='start_date' className='tooltip-logo' />
                    </div>
                    <div
                      className='input-container d-flex justify-content-center align-items-center'
                      // className={`input-container d-flex justify-content-center align-items-center ${
                      //   formik.touched.start_date && formik.errors.start_date ? 'error-border' : ''
                      // }`}
                    >
                      <DatePicker
                        minDate={fiveYearsOldDate}
                        maxDate={lastDateOfPrevMonth}
                        className='form-control form-control-lg form-control-solid date-picker-custom-styles'
                        placeholderText={`${moment()
                          .subtract(1, 'months')
                          .endOf('month')
                          .format('MM/DD/YYYY')}`}
                        selected={
                          investment.custom_start_date &&
                          moment(investment.custom_start_date, 'YYYY-MM-DD').isValid()
                            ? moment(investment.custom_start_date).toDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          if (date && moment(date).isValid()) {
                            // set the date only if it's a valid date object
                            handleInvestmentChange(
                              index,
                              'custom_start_date',
                              moment(date).format('YYYY-MM-DD')
                            )
                          } else {
                            handleInvestmentChange(
                              index,
                              'custom_start_date',
                              moment().format('MM-DD-YYYY')
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* custom start value field */}
                  <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>
                        {COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD2}
                      </p>
                      <CustomOverlayTooltip content='start_value' className='tooltip-logo' />
                    </div>
                    <div
                      className='input-container d-flex justify-content-center align-items-center'
                      // className={`input-container d-flex justify-content-center align-items-center ${
                      //   formik.touched.start_value && formik.errors.start_value ? 'error-border' : ''
                      // }`}
                    >
                      <CustomInputField
                        id='custom-start-value'
                        className='form-control form-control-lg form-control-solid'
                        placeholder=' e.g $1,000'
                        decimalsLimit={2}
                        maxLength={9}
                        value={investment.custom_start_value}
                        onValueChange={(value: any) => {
                          handleInvestmentChange(index, 'custom_start_value', value || '')
                        }}
                        onKeyDown={handleKeyPress}
                      />
                    </div>
                  </div>
                  {/* custom end date field */}
                  <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>
                        {COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD3}
                      </p>
                      <CustomOverlayTooltip content='end_date' className='tooltip-logo' />
                    </div>
                    <div
                      className='input-container d-flex justify-content-center align-items-center'
                      // className={`input-container d-flex justify-content-center align-items-center ${
                      //   formik.touched.start_date && formik.errors.start_date ? 'error-border' : ''
                      // }`}
                    >
                      <DatePicker
                        minDate={fiveYearsOldDate}
                        maxDate={moment().toDate()}
                        className='form-control form-control-lg form-control-solid date-picker-custom-styles'
                        placeholderText={`${moment().format('MM/DD/YYYY')}`}
                        selected={
                          investment.custom_end_date &&
                          moment(investment.custom_end_date, 'YYYY-MM-DD').isValid()
                            ? moment(investment.custom_end_date).toDate()
                            : null
                        }
                        onChange={(date: Date) => {
                          if (date && moment(date).isValid()) {
                            handleInvestmentChange(
                              index,
                              'custom_end_date',
                              moment(date).format('YYYY-MM-DD')
                            )
                          } else {
                            handleInvestmentChange(
                              index,
                              'custom_end_date',
                              moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY')
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* custom end value field */}
                  <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>
                        {COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD4}
                      </p>
                      <CustomOverlayTooltip content='end_value' className='tooltip-logo' />
                    </div>
                    <div
                      className='input-container d-flex justify-content-center align-items-center'
                      // className={`input-container d-flex justify-content-center align-items-center ${
                      //   formik.touched.start_value && formik.errors.start_value ? 'error-border' : ''
                      // }`}
                    >
                      <CustomInputField
                        id='custom-end-value'
                        className='form-control form-control-lg form-control-solid'
                        placeholder=' e.g $1,000'
                        decimalsLimit={2}
                        maxLength={9}
                        value={investment.custom_end_value}
                        onValueChange={(value: any) => {
                          handleInvestmentChange(index, 'custom_end_value', value || '')
                        }}
                        onKeyDown={handleKeyPress}
                      />
                    </div>
                  </div>
                  {/* <div className='col'>
                <input
                  name={`customInvestments[${index}].custom_name`}
                  value={investment.custom_name}
                  onChange={(e) => handleInvestmentChange(index, 'custom_name', e.target.value)}
                  // Add other props as needed
                />
              </div> */}
                  <div className='col-6 col-lg-2 mb-2 mb-lg-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>
                        {COMPARE_PERFORMANCE_SECTION.TOOLTIP.FIELD6}
                      </p>
                      <CustomOverlayTooltip content='custom_name' className='tooltip-logo' />
                    </div>
                    <div
                      className='input-container d-flex justify-content-center align-items-center'
                      // className={`input-container d-flex justify-content-center align-items-center ${
                      //   formik.touched.start_value && formik.errors.start_value ? 'error-border' : ''
                      // }`}
                    >
                      <input
                        id='custom-value'
                        placeholder=' e.g My Investment'
                        className='form-control form-control-lg form-control-solid'
                        name={`customInvestments[${index}].custom_name`}
                        value={investment.custom_name}
                        onChange={(e) =>
                          handleInvestmentChange(index, 'custom_name', e.target.value)
                        }
                        // Add other props as needed
                      />
                    </div>
                  </div>
                  {/* Add other fields for startDate, startValue, etc., similar to the custom_name field */}

                  {/* Remove Button */}
                  {/* <div className='col-auto'>
                <button type='button' onClick={() => handleRemoveInvestment(index)}>
                  Remove
                </button>
              </div> */}
                  <div className='col-2 d-flex align-items-center'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark mt-5'
                      onClick={() => handleRemoveInvestment(index)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen036.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {/* formik errors */}
        <div className='d-flex flex-column justify-content-center align-items-center'>
          {/* Start date field error */}
          {formik.touched.start_date && formik.errors.start_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'> * {formik.errors.start_date}</div>
            </div>
          )}
          {/* Start value field error */}
          {formik.touched.start_value && formik.errors.start_value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'> * {formik.errors.start_value}</div>
            </div>
          )}
          {/* End date field error */}
          {formik.touched.end_date && formik.errors.end_date && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'> * {formik.errors.end_date}</div>
            </div>
          )}
          {/* End value field error */}
          {formik.touched.end_value && formik.errors.end_value && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'> * {formik.errors.end_value}</div>
            </div>
          )}
          {/* Investments to compare dropdown error */}
          {formik.touched.investments_to_compare && formik.errors.investments_to_compare && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {' '}
                * {formik.errors.investments_to_compare as string}
              </div>
            </div>
          )}
          {/* show error if no. of years > 2 and user selects ETH(we don't have more than 2 years old data for ETH) */}
          {noOfYears > 2 &&
            formik.values.investments_to_compare?.some(
              (investment) => investment.label === 'ETH'
            ) && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  * Comparison with Ethereum is not available for data or events that extend beyond
                  a two-year historical range.
                </div>
              </div>
            )}
        </div>
      </form>
    </>
  )
}

export default memo(ComparePerformanceInputs)
