import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {login, getUserByToken} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {UserRoles} from '../../../config/UserRoles'
import {CSSProperties} from 'react' // Corrected import
import {toast} from 'react-toastify' // Ensure toastify is imported if used

const LoginPage: React.FC = () => {
  const notifyError = (msg: string) => toast.error(msg)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, currentUser} = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      const {data: auth} = await login(email, password)
      saveAuth(auth)
      const {data: user} = await getUserByToken(auth.auth_token)
      setCurrentUser(user)
      navigate(user.user_role === UserRoles.admin ? '/dashboard' : '/home')
    } catch (error: any) {
      console.error(error)
      saveAuth(undefined)
      setLoading(false)
      if (error?.response?.status === 403) {
        notifyError('User not verified or blocked')
      }
    }
  }

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.loginForm}>
        <img
          src='/media/home/ARQ-logo.jpg' // Assuming toAbsoluteUrl is simply mapping to a static directory
          alt='ARQ Ventures'
          style={styles.logo}
        />
        <input
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
          style={styles.input}
        />
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Password'
          style={styles.input}
        />
        <button type='submit' style={styles.button} disabled={loading}>
          Continue
        </button>
        <a href='#' style={styles.link}>
          Forgot Password?
        </a>
        <p style={styles.signupText}>
          Not an investor yet?{' '}
          <a href='#' style={styles.signupLink}>
            Sign up
          </a>
        </p>
      </form>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url('/media/home/login-background.png')`, // Changed the path for clarity
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  } as CSSProperties,
  loginForm: {
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '10px', // Changed for better aesthetics
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
  } as CSSProperties,
  logo: {
    marginBottom: '10px',
    maxWidth: '100px',
  } as CSSProperties,
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  } as CSSProperties,
  button: {
    width: '100%',
    backgroundColor: '#800080',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  } as CSSProperties,
  link: {
    color: '#800080',
    textDecoration: 'none',
  } as CSSProperties,
  signupText: {
    marginTop: '20px',
  } as CSSProperties,
  signupLink: {
    color: '#800080',
    textDecoration: 'none',
  } as CSSProperties,
}

export default LoginPage
