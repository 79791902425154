import {useRef, useState} from 'react'
import MetaTags from '../../../Meta/MetaTags'
import {NavbarComponent} from '../../../../_metronic/layout/components/header/user/NavbarComponent'
import ARQStatus from './ARQStatus'
import ARQNumbers from './ARQNumbers'
import {sectionIds} from '../../../constants/AppConstants'
import MobileSuperChart from './MobileSuperChart'
import PropertyDetailCard from './PropertyDetailCard'
import {ARQProjectionsSection} from '../../modules/homeComponents/projections/components/ARQProjectionsSection'
import {motion, useScroll, useTransform} from 'framer-motion'

const MobileHomePage = () => {
  const [showDetails, setShowDetails] = useState(true)

  const handleClickProjections = () => {
    const projectionsDiv = document.getElementById(sectionIds[21])
    // setShowDetails(false)
    if (projectionsDiv) {
      projectionsDiv.scrollIntoView({
        behavior: 'smooth',
      })
      // Scroll a little more down after the initial scroll
      setTimeout(() => {
        window.scrollBy({
          top: -10, // Adjust this value to scroll down a bit more
          behavior: 'smooth',
        })
      }, 500) // Adjust timing if necessary to ensure it happens after scrollIntoView
    }
  }

  const navLinks = [
    {path: '#', label: 'ARQ Status', scrollToId: sectionIds[20]},
    {path: '#', label: 'Numbers', scrollToId: sectionIds[21]},
    {path: '#', label: "My ARQ's", scrollToId: sectionIds[22]},
    {path: '#', label: 'Deals', scrollToId: sectionIds[23]},
    {path: '#', label: 'About', scrollToId: sectionIds[24]},
  ]

  const ref = useRef(null)
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ['start start', 'end start'],
  })

  // define intervals and corresponding transformations
  const backgroundY = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ['60%', '40%', '20%', '0%', '-10%']
  )
  const position = useTransform(scrollYProgress, (pos) => {
    return pos >= 0 ? 'fixed' : 'absolute'
  })

  return (
    <>
      <MetaTags URL={window.location.href} />
      <section className='container-arq container content'>
        <NavbarComponent navLinks={navLinks} />
        <div className='content-wrapper'>
          <div className='justify-content-center mb-20'>
            <ARQStatus showDetails={showDetails} />
          </div>
          {/* Arrow button */}
          <div className='scroll-arrow' onClick={handleClickProjections}>
            &#x2193;
          </div>
          {/* here */}
          <div id={sectionIds[21]}></div>
          <div className='position-relative'>
            <motion.div
              className='w-100 h-100'
              style={{
                backgroundImage: `url(/media/home/container-background.svg)`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                y: backgroundY,
                top: 0,
                left: 0,
                zIndex: -1,
                position,
              }}
            />
            {/* mobile super chart */}
            <div
              className='my-10'
              data-aos='fade-up'
              data-aos-anchor-placement='top-bottom'
              data-aos-duration='1000'
              data-aos-delay='500'
            >
              <MobileSuperChart />
            </div>
            {/* property details card */}
            <div
              data-aos='fade-up'
              data-aos-anchor-placement='top-bottom'
              className=' mt-20 mb-20'
              data-aos-duration='1000'
              data-aos-delay='100'
              data-aos-offset='200'
              id={sectionIds[22]}
            >
              <PropertyDetailCard />
            </div>
            {/* ARQ numbers */}
            <div className='mt-100' id={sectionIds[23]}>
              <ARQNumbers />
            </div>
            {/* ARQ projections section */}
            <div className=' mt-10 mb-20' id={sectionIds[24]}>
              <ARQProjectionsSection />
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  )
}

export {MobileHomePage}
