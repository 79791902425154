import React from 'react'
import {TooltipProps} from 'recharts'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {toUSD} from '../../../helper/charts.helper'

const TimeSeriesTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  const {receiverFormDataObj} = useReceiverFormContext()
  const selectedRoR = receiverFormDataObj.selected_rate_of_return_obj

  if (!active || !payload || payload.length === 0) {
    return null
  }

  const data = payload[0].payload
  const isLegacy =
    selectedRoR.type !== TYPES_OF_ROR.TYPE_AFTER_EXIT &&
    selectedRoR.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT

  const management_fee = data.OriginalARQValues.management_fee
  const appreciationLabel = selectedRoR.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
    <p className='paragraph-x-small mb-0 label-gain'>
      Gain (Appreciation): <span className='label-bold'>{toUSD(data.appreciation)}</span>
      {data.OriginalARQValues.appreciation !== 0 && data.appreciation !== 0 && (
        <span className='label-bold'>
          {' ('}
          {data.OriginalARQValues.proportionValue.toFixed(2)}% of{' '}
          {!isLegacy && management_fee + '% of '}
          {toUSD(data.OriginalARQValues.appreciation)}
          {') '}
        </span>
      )}
    </p>
  )

  const rentalIncomeLabel = selectedRoR.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
    <p className='paragraph-x-small mb-0 label-gain'>
      Gain (Rental Income): <span className='label-bold'>{toUSD(data.rentalIncome)}</span>
      {data.OriginalARQValues.rental !== 0 && data.rentalIncome !== 0 && (
        <span className='label-bold'>
          {' ('}
          {data.OriginalARQValues.proportionValue.toFixed(2)}% of{' '}
          {!isLegacy && management_fee + '% of '}
          {toUSD(data.OriginalARQValues.rental)}
          {') '}
        </span>
      )}
    </p>
  )

  const flipProfitLabel = selectedRoR.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT && (
    <p className='paragraph-x-small mb-0 label-gain'>
      Gain (Flip Profit): <span className='label-bold'>{toUSD(data.flipGain)}</span>
      {data.OriginalARQValues.fixAndFlip !== 0 && data.flipGain !== 0 && (
        <span className='label-bold'>
          {' ('}
          {data.OriginalARQValues.proportionValue.toFixed(2)}% of{' '}
          {!isLegacy && management_fee + '% of '}
          {toUSD(data.OriginalARQValues.fixAndFlip)}
          {') '}
        </span>
      )}
    </p>
  )

  const LossLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-loss'>
        Loss: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  const GainLabel = ({value}: {value: number}) => {
    return (
      <p className='paragraph-x-small mb-0 label-gain'>
        Gain: <span className='label-bold'>{toUSD(value)}</span>
      </p>
    )
  }

  const contributionsLabel = (
    <p className='paragraph-x-small mb-0 label-contributions'>
      Contributions: <span className='label-bold'>{toUSD(data?.cumulativeContributions)}</span>
    </p>
  )

  const toDateLabel = (
    <div>
      <p className='paragraph-small label-main'>To {data?.lastValues?.yearOrMonth}:</p>
      {data.isLoss ? (
        <LossLabel value={parseFloat(data?.lastValues.gain)} />
      ) : (
        <GainLabel value={data?.lastValues.gain} />
      )}
    </div>
  )

  const totalContributionsThisPeriodLabel =
    selectedRoR.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
      <p className='paragraph-x-small mb-0 label-contributions'>
        Contributions: <span className='label-bold'>{toUSD(data?.lastValues?.contributions)}</span>
      </p>
    ) : null

  return (
    <div className='custom-tooltip'>
      <p className='paragraph-small label-main'>{data?.yearOrMonth}</p>
      {selectedRoR.type !== TYPES_OF_ROR.TYPE_SIMPLE ? (
        <>
          {selectedRoR.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
            <>
              {selectedRoR.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT && appreciationLabel}
              {rentalIncomeLabel}
            </>
          )}
          {flipProfitLabel}
        </>
      ) : (
        <>
          {!data.isLoss ? <GainLabel value={data?.gain} /> : <LossLabel value={data?.gain} />}
          {contributionsLabel}
          <br />
          {toDateLabel}
        </>
      )}
      {totalContributionsThisPeriodLabel}
      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        Starting amount: <span className='label-bold'>{toUSD(data?.initialDeposit)}</span>
      </p>
    </div>
  )
}

export default TimeSeriesTooltip
