import React from 'react'
import {ICustomSVGProps} from './models'

const CustomSVG: React.FC<ICustomSVGProps> = ({
  color,
  assetQty,
  src,
  viewBox,
  offset,
  lastIndex,
}) => {
  // Calculate width and height based on assetQty
  let width
  let height

  if (assetQty >= 1 && assetQty <= 5) {
    width = '175px'
    height = '175px'
  } else if (assetQty >= 6 && assetQty <= 10) {
    width = '150px'
    height = '150px'
  } else if (assetQty >= 11 && assetQty <= 15) {
    width = '125px'
    height = '125px'
  } else {
    width = '100px'
    height = '100px'
  }

  return (
    <>
      {lastIndex ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox={viewBox}
          width={width}
          height={height}
          fill='url(#customGradient)'
        >
          <defs>
            <linearGradient id='customGradient' x1='0%' y1='100%' x2='0%' y2='0%'>
              <stop offset={offset} style={{stopColor: color}} />
              <stop offset={offset} style={{stopColor: 'gray'}} />
            </linearGradient>
          </defs>
          <path fill='none' d='M0 0h24v24H0z' />
          <path d={src} />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox={viewBox}
          width={width}
          height={height}
          fill='url(#customGradient2)'
        >
          <defs>
            <linearGradient id='customGradient2' x1='0%' y1='100%' x2='0%' y2='0%'>
              <stop offset={offset} style={{stopColor: color}} />
              <stop offset={offset} style={{stopColor: 'gray'}} />
            </linearGradient>
          </defs>
          <path fill='none' d='M0 0h24v24H0z' />
          <path d={src} />
        </svg>
      )}
    </>
  )
}

export default CustomSVG
