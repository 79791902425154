import React from 'react'
import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, LabelList, Text, Cell} from 'recharts'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {projectionsColors} from '../../projections/components/ProjectionsConfig'
import TimeSeriesTooltip from './TimeSeriesTooltip'

const TimeSeriesChart: React.FC = () => {
  // extracting timeSeriesGraphDataObj from receiver context
  const {timeSeriesGraphDataObj} = useReceiverFormContext()

  // calculating screen width hook
  const screenWidth = useScreenWidth()

  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: 30}
      : {top: 80, bottom: 30, right: 40, left: 40}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width} = props
    const futureValue = Math.abs(timeSeriesGraphDataObj[index]?.futureValue).toFixed(2)

    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))

    return (
      <Text
        x={x + width / 2}
        y={screenWidth > 767 ? y - 23 : y - 15}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={timeSeriesGraphDataObj.length <= 10 ? 0 : 270}
      >
        {gain ? gain : '$X'}
      </Text>
    )
  }

  //custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, payload} = props

    return (
      <Text
        x={x}
        y={y + labelPadding}
        width={90}
        textAnchor='middle'
        verticalAnchor='start'
        angle={labelTextAngle}
        className='custom-tick-label'
      >
        {payload.value}
      </Text>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={_chartHeight} id='chart-container'>
      <BarChart
        margin={chartPadding}
        data={timeSeriesGraphDataObj}
        style={{backgroundColor: 'white'}}
      >
        <XAxis
          dataKey='yearOrMonth'
          axisLine={false}
          tickLine={false}
          interval={0}
          width={100}
          minTickGap={5}
          tick={<CustomizedAxisTick />}
        />

        {/* tooltip */}
        <Tooltip content={<TimeSeriesTooltip />} />
        {/* bars */}
        <Bar dataKey='initialDeposit' stackId='assets' fill={projectionsColors.initialDeposit} />
        <Bar
          dataKey='cumulativeContributions'
          stackId='assets'
          fill={projectionsColors.contributions}
        />
        <Bar dataKey='flipGain' stackId='assets' fill={projectionsColors.gain} />
        <Bar dataKey='rentalIncome' stackId='assets' fill={projectionsColors.gain} />
        <Bar dataKey='appreciation' stackId='assets' fill={projectionsColors.gain} />
        <Bar dataKey='gain' stackId='assets'>
          {timeSeriesGraphDataObj.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`${entry.isLoss ? projectionsColors.loss : projectionsColors.gain}`}
            />
          ))}
          {timeSeriesGraphDataObj.length <= 20 ? (
            <LabelList dataKey='gain' content={<CustomizedBarTopLabel />} position='top' />
          ) : null}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
export default TimeSeriesChart
