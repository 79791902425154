import React, {useState, useEffect} from 'react'
import {Slider} from 'rsuite'
import CustomPictographModal from './components/CustomPictographModal'
import {Thumb} from '../../../../components/projectionSlider/ProjectionSlider'
import {PICTOGRAPH_SECTION, sectionIds} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {toUSD} from '../../helper/charts.helper'
import CustomSVG from './components/CustomSvg'
import {usePictographState} from './components/PictographUtils'
import {Button} from 'react-bootstrap'
import FeedbackSection from '../feedbackSection/FeedbackSection'

const Pictograph: React.FC = () => {
  // destructuring useReceiverFormContext hook
  const {pictoFutureValue, receiverFormDataObj, sectionIdToShowInWebViewer, showInWebViewer} =
    useReceiverFormContext()

  // destructuring usePictographState hook
  const {
    assetQty,
    assetsArray,
    futureAsset,
    findBestAsset,
    selectedRange,
    lastIndexQty,
    handleCustomAssets,
    handleRangeChange,
  } = usePictographState()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    findBestAsset()
  }, [pictoFutureValue])

  return (
    <section
      className={`text-center px-7 px-md-10 px-lg-20 pt-5 py-20 ${
        sectionIdToShowInWebViewer !== sectionIds[3] && showInWebViewer ? 'd-none' : ''
      }`}
      id={sectionIds[3]}
    >
      <div className=''>
        <h1>Some perspective:</h1>
        <p className='mt-7'>
          The{' '}
          <span className='pink-text fw-600'>{pictoFutureValue ? toUSD(pictoFutureValue) : 0}</span>{' '}
          potentially from the{' '}
          <span className='pink-text fw-600'>
            {receiverFormDataObj.selected_rate_of_return_obj.dropdownLabel}
          </span>{' '}
          account is like:
        </p>
        <p>{futureAsset.assetName}</p>

        {/* assets images */}
        <div className='d-flex flex-wrap justify-content-center align-items-center'>
          <>
            {[...Array(Math.min(Math.ceil(assetQty), PICTOGRAPH_SECTION.MAX_LIMIT))].map(
              (_, index, array) => (
                <div key={index} className='mt-7 px-3'>
                  <CustomSVG
                    assetQty={Math.ceil(assetQty)}
                    color='#9b258f'
                    src={futureAsset.assetImg ? futureAsset.assetImg : ''}
                    viewBox={futureAsset.viewBox}
                    offset={index === array.length - 1 ? `${lastIndexQty}%` : '100%'}
                    lastIndex={index === array.length - 1 ? true : false}
                  />
                </div>
              )
            )}
          </>
        </div>

        {/* assets slider */}
        <div className='mt-10 mb-12 pictograph-range'>
          <Slider
            min={0}
            max={assetsArray.length - 1}
            value={selectedRange}
            step={1}
            tooltip={false}
            className='custom-slider'
            onChange={handleRangeChange}
            progress
            handleStyle={{
              borderRadius: 2,
              color: '#fff',
              fontSize: 12,
              width: 32,
              height: 22,
            }}
            handleTitle={<Thumb />}
            graduated // Display step indicators
          />
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center'>
        <Button
          className='custom-secondary-button'
          onClick={() => setShowModal(true)}
          type='submit'
        >
          ADD CUSTOM ASSET
        </Button>
      </div>
      <CustomPictographModal
        show={showModal}
        onHide={() => setShowModal(false)}
        handleCustomAssets={handleCustomAssets}
      />

      {/* feedback section */}
      <section className='mt-7'>
        <FeedbackSection sectionName='pictograph section' />
      </section>
    </section>
  )
}

export default Pictograph
