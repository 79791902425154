import moment from 'moment'
import {TYPES_OF_ROR} from '../../../../constants/AppConstants'
import {InvestmentMyPerformanceChartProps, MyPerformanceAssetsCompoundingGrowth} from './_model'
import {
  calculateFutureValue,
  calculateARQAppreciation,
  calculateAfterManagementFeePercentages,
  calculatePropertyWorthPercentages_New,
} from './CommonCalculatorFunctions'

// calculate date
export const calculateDate = (startDate: string, endDate: string) => {
  const formattedStartDate = moment(startDate, 'ddd, YYYY-MM-DD HH:mm:ss [GMT]')
  const formattedEndDate = moment(endDate, 'ddd, YYYY-MM-DD HH:mm:ss [GMT]')

  return formattedEndDate.diff(formattedStartDate, 'years', true)
}

// helper functions
// calculate gains for myPerformance before and after exit
const calculateGains = (futureValue: number, startingAmount: number) => {
  return Math.abs(futureValue - startingAmount) // numberOfYears = years since customer start
}

// calculate total RoR
const calculateTotalROR = (gains: number, startingAmount: number) => {
  return gains / startingAmount
}

// calculate annual RoR
const calculateAnnualROR = (totalROR: number, numberOfYears: number) => {
  return totalROR / numberOfYears
}

// calculate timeItervalROR
const calculateTimeIntervalROR = (rental_income_this_quarter: number, startingAmount: number) => {
  return rental_income_this_quarter === 0
    ? null
    : (rental_income_this_quarter / startingAmount) * 100
}

// calculate annualTimeIntervalROR
const calculateAnnualTimeIntervalROR = (
  rental_income_this_quarter: number,
  startingAmount: number
) => {
  return rental_income_this_quarter === 0
    ? null
    : (rental_income_this_quarter / startingAmount) * 4 * 100
}

const getAppreciationWithPropertyWorthPercentage = (
  key: number | null,
  originalARQAppreciation: number,
  Equity: number,
  includeAppreciationToggleForLTD: boolean,
  includeAppreciationToggleForProjections: boolean
) => {
  switch (key) {
    case 2: // key = 2 => LTD(Lifetime returns) chart
      return includeAppreciationToggleForLTD ? originalARQAppreciation * Equity : 0

    case 3: // key = 3 => Projections chart
      return includeAppreciationToggleForProjections ? originalARQAppreciation * Equity : 0

    default:
      return originalARQAppreciation * Equity
  }
}

const getAppreciationAfterManagementFee = (
  key: number | null,
  calculatedARQAppreciationWithEquity: number,
  management_fee: number,
  includeAppreciationToggleForLTD: boolean,
  includeAppreciationToggleForProjections: boolean
) => {
  switch (key) {
    case 2: // key = 2 => LTD(Lifetime returns) chart
      return includeAppreciationToggleForLTD
        ? calculateAfterManagementFeePercentages(
            calculatedARQAppreciationWithEquity,
            management_fee
          )
        : 0

    case 3: // key = 3 => Projections chart
      return includeAppreciationToggleForProjections
        ? calculateAfterManagementFeePercentages(
            calculatedARQAppreciationWithEquity,
            management_fee
          )
        : 0

    default:
      return calculateAfterManagementFeePercentages(
        calculatedARQAppreciationWithEquity,
        management_fee
      )
  }
}

const getCalculateARQAppreciation = (
  key: number | null,
  ARQ_Property_End_Value: number,
  ARQ_property_worth: number,
  includeAppreciationToggleForLTD: boolean,
  includeAppreciationToggleForProjections: boolean
) => {
  switch (key) {
    case 2: // key = 2 => LTD(Lifetime returns) chart
      return includeAppreciationToggleForLTD
        ? calculateARQAppreciation(ARQ_Property_End_Value, ARQ_property_worth)
        : 0

    case 3: // key = 3 => Projections chart
      return includeAppreciationToggleForProjections
        ? calculateARQAppreciation(ARQ_Property_End_Value, ARQ_property_worth)
        : 0

    default:
      return calculateARQAppreciation(ARQ_Property_End_Value, ARQ_property_worth)
  }
}

const getInitialDepositWithoutPhase1Profit = (
  key: number | null,
  initialDeposit: number,
  ARQ_Flip_Gain: number,
  type: string,
  oppurtunityCostToggleForLTD: boolean,
  oppurtunityCostToggleForProjections: boolean
) => {
  switch (key) {
    case 2:
      return !oppurtunityCostToggleForLTD && type === TYPES_OF_ROR.TYPE_SIMPLE
        ? initialDeposit - ARQ_Flip_Gain
        : initialDeposit
    case 3:
      return !oppurtunityCostToggleForProjections && type === TYPES_OF_ROR.TYPE_SIMPLE
        ? initialDeposit - ARQ_Flip_Gain
        : initialDeposit
    default:
      return initialDeposit
  }
}

export const calculateAnnualizedExpectedROR = (expected_ROR: number, numberOfYears: number) => {
  return (expected_ROR / 100) * numberOfYears
}

// generate chart data
export const generateMyPerformanceChartData = (
  initialDeposit: number,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  compoundingGrowth: MyPerformanceAssetsCompoundingGrowth[],
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  management_fee: number,
  is_management_fee_applied: boolean = true,
  actual_equity: number,
  expected_ROR: number,
  original_ROR: number,
  rollover: boolean,
  customer_actual: boolean,
  new_starting_amount: number,
  ARQ_Property_End_Value: number,
  paid_out_on: string,
  chartKey: number | null, // it defines the time interval of chart
  includeAppreciationToggleForLTD: boolean,
  includeAppreciationToggleForProjections: boolean,
  oppurtunityCostToggleForLTD: boolean,
  oppurtunityCostToggleForProjections: boolean,
  actual_annualized_ytd_ror_phase1And2: number,
  actual_rental_income_LTD: number,
  ARQ_rental_frequency: number,
  years_to_grow: number,
  customer_start_date: string,
  quarter_end_date: string
): InvestmentMyPerformanceChartProps[] => {
  let totalROR: number | null
  let annualROR: number | null
  let timeIntervalROR: number | null
  let annualTimeIntervalROR: number | null
  let cashOnCashAnnualized: number | null
  let cashOnCash: number | null

  let originalInitialDeposit = initialDeposit
  const startDate: Date = new Date(customer_start_date)
  const endDate: Date = new Date(quarter_end_date)

  // Calculate the difference in milliseconds
  const differenceInMilliseconds: number = endDate.getTime() - startDate.getTime()

  // Convert milliseconds to months (assuming 30 days in a month)
  const differenceInMonths: number = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30)
  const chartData = compoundingGrowth.map((rate) => {
    initialDeposit = rollover ? initialDeposit : new_starting_amount
    // initial deposit without phase 1 profit
    initialDeposit = originalInitialDeposit

    initialDeposit = actual_annualized_ytd_ror_phase1And2
      ? initialDeposit + ARQ_Flip_Gain
      : rollover
      ? getInitialDepositWithoutPhase1Profit(
          chartKey,
          initialDeposit,
          ARQ_Flip_Gain,
          rate.type,
          oppurtunityCostToggleForLTD,
          oppurtunityCostToggleForProjections
        )
      : getInitialDepositWithoutPhase1Profit(
          chartKey,
          new_starting_amount,
          ARQ_Flip_Gain,
          rate.type,
          oppurtunityCostToggleForLTD,
          oppurtunityCostToggleForProjections
        )
    let _initialDepositWithoutEquity = initialDeposit - ARQ_Flip_Gain

    // storing annualized ROR in a variable
    const QSPAnnualizedROR = rate.annualizedROR

    const Equity =
      actual_equity && actual_equity !== 0
        ? actual_equity / 100
        : calculatePropertyWorthPercentages_New(
            ARQ_Flip_Gain,
            ARQ_property_worth,
            initialDeposit,
            rollover,
            new_starting_amount
          )

    // ARQ appreciation
    const originalARQAppreciation = getCalculateARQAppreciation(
      chartKey,
      ARQ_Property_End_Value,
      ARQ_property_worth,
      includeAppreciationToggleForLTD,
      includeAppreciationToggleForProjections
    )

    const calculatedARQAppreciationWithEquity = originalARQAppreciation * Equity

    // for flip
    const flipGainWithPropertyWorthPercentage = customer_actual
      ? ARQ_Flip_Gain
      : (initialDeposit / ARQ_property_worth) * ARQ_Flip_Gain
    // for rental
    const rentalIncomeWithPropertyWorthPercentage = ARQ_rental_income * Equity

    // for appreciation
    const appreciationWithPropertyWorthPercentage = getAppreciationWithPropertyWorthPercentage(
      chartKey,
      originalARQAppreciation,
      Equity,
      includeAppreciationToggleForLTD,
      includeAppreciationToggleForProjections
    )

    // Calculate values after subtracting management fees
    // for flip
    const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
      flipGainWithPropertyWorthPercentage,
      is_management_fee_applied ? management_fee : 0
    )

    // for rental
    const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncomeWithPropertyWorthPercentage,
      is_management_fee_applied ? management_fee : 0
    )

    // for appreciation
    const appreciationAfterManagementFee = getAppreciationAfterManagementFee(
      chartKey,
      calculatedARQAppreciationWithEquity,
      is_management_fee_applied ? management_fee : 0,
      includeAppreciationToggleForLTD,
      includeAppreciationToggleForProjections
    )

    let futureValue = 0
    let gains = 0
    let customROR: number | null = null
    let rentalIncome = 0
    let futureValueWithoutContributions = 0

    let isLegacy =
      rate.type !== TYPES_OF_ROR.TYPE_SIMPLE &&
      (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL || rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP)
        ? true
        : false
    if (rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
      futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
      customROR = initialDeposit > 0 ? Math.abs(initialDeposit - futureValue) / initialDeposit : 0
      futureValueWithoutContributions = futureValue
    }
    // type = ARQ Rental
    else if (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL) {
      rentalIncome =
        rentalIncomeWithPropertyWorthPercentage * ARQ_rental_contribution * numberOfYears
      futureValue =
        initialDeposit +
        (flipGainWithPropertyWorthPercentage +
          rentalIncome +
          appreciationWithPropertyWorthPercentage)
      gains =
        flipGainWithPropertyWorthPercentage + rentalIncome + appreciationWithPropertyWorthPercentage
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    }
    // type = ARQ After Exit
    else if (rate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT) {
      // if projections chart than multiply rentalIncomeWithPropertyWorthPercentage by ARQ_rental_contribution * numberOfYears
      // else keep it as rentalIncomeWithPropertyWorthPercentage

      rentalIncome =
        chartKey === 3
          ? rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
          : rentalIncomeAfterManagementFee

      // future value
      futureValue =
        _initialDepositWithoutEquity +
        (ARQ_Flip_Gain + rentalIncome + appreciationAfterManagementFee)

      // calculating gains
      gains = calculateGains(futureValue, _initialDepositWithoutEquity)

      customROR =
        _initialDepositWithoutEquity > 0
          ? gains / _initialDepositWithoutEquity / numberOfYears
          : gains / numberOfYears
      // futureValue = futureValue - ARQ_Flip_Gain

      // calculate totalROR
      totalROR = calculateTotalROR(gains, _initialDepositWithoutEquity)

      // calculate annualROR
      annualROR = calculateAnnualROR(totalROR, numberOfYears)
      annualROR *= 100
      // multiply total RoR with 100
      totalROR *= 100

      // calculate timeItervalROR
      timeIntervalROR = calculateTimeIntervalROR(ARQ_rental_income, _initialDepositWithoutEquity)
      // calculate annualTimeIntervalROR
      annualTimeIntervalROR = calculateAnnualTimeIntervalROR(
        ARQ_rental_income,
        _initialDepositWithoutEquity
      )
      // cash on cash
      cashOnCash = totalROR
      // cash on cash annualized
      cashOnCashAnnualized = annualROR
      if (actual_annualized_ytd_ror_phase1And2) {
        let appreciationAfterManagmentFee =
          ARQ_appreciation * (1 - management_fee / 100) * (actual_equity / 100)
        let rentalIncomeAfterManagment =
          chartKey === 3
            ? ARQ_rental_income *
              ARQ_rental_frequency *
              years_to_grow *
              (1 - management_fee / 100) *
              (actual_equity / 100)
            : actual_rental_income_LTD * (1 - management_fee / 100) * (actual_equity / 100)
        totalROR =
          ((appreciationAfterManagmentFee + rentalIncomeAfterManagment) / initialDeposit) * 100
        // /yr ROR value
        customROR = chartKey === 2 ? (totalROR / differenceInMonths) * 12 : totalROR / 2
        futureValue =
          chartKey === 2
            ? initialDeposit + appreciationAfterManagementFee + rentalIncomeAfterManagment
            : appreciationAfterManagementFee + rentalIncomeAfterManagment + initialDeposit
      }
    }
    // type = ARQ Before Exit
    else if (rate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
      // if projections chart than multiply rentalIncomeWithPropertyWorthPercentage by ARQ_rental_contribution * numberOfYears
      // else keep it as rentalIncomeWithPropertyWorthPercentage

      rentalIncome =
        chartKey === 3
          ? rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
          : rentalIncomeAfterManagementFee
      // future value
      futureValue = initialDeposit + rentalIncome

      // calculating gains
      gains = calculateGains(futureValue, _initialDepositWithoutEquity)
      customROR =
        _initialDepositWithoutEquity > 0
          ? gains / _initialDepositWithoutEquity / numberOfYears
          : gains / numberOfYears

      // futureValue = futureValue - ARQ_Flip_Gain

      // tooltip calculations for myPerformance charts
      // calculate totalROR

      totalROR = calculateTotalROR(gains, _initialDepositWithoutEquity)
      // calculate annualROR
      annualROR = calculateAnnualROR(totalROR, numberOfYears)
      annualROR *= 100
      // multiply total RoR with 100
      totalROR *= 100
      // multiply annual RoR with 100
      timeIntervalROR = calculateTimeIntervalROR(ARQ_rental_income, _initialDepositWithoutEquity)
      // calculate annualTimeIntervalROR
      annualTimeIntervalROR = calculateAnnualTimeIntervalROR(
        ARQ_rental_income,
        _initialDepositWithoutEquity
      )
      // cash on cash
      cashOnCash = totalROR
      // cash on cash annualized
      cashOnCashAnnualized = annualROR
      if (actual_annualized_ytd_ror_phase1And2) {
        let afterManagmentFee =
          chartKey === 3
            ? ARQ_rental_income *
              ARQ_rental_frequency *
              years_to_grow *
              (1 - management_fee / 100) *
              (actual_equity / 100)
            : actual_rental_income_LTD * (1 - management_fee / 100) * (actual_equity / 100)
        totalROR = (afterManagmentFee / initialDeposit) * 100
        // /yr ROR value
        customROR = chartKey === 2 ? (totalROR / differenceInMonths) * 12 : totalROR / 2
        futureValue =
          chartKey === 2 ? initialDeposit + afterManagmentFee : initialDeposit + afterManagmentFee
      }
    }
    // Simple Type
    else if (rate.type === TYPES_OF_ROR.TYPE_SIMPLE && rate.value !== 0) {
      futureValue = calculateFutureValue(
        initialDeposit,
        rate.value,
        contributionFrequency,
        numberOfYears,
        contributions
      )

      futureValueWithoutContributions =
        futureValue - contributions * contributionFrequency * numberOfYears - initialDeposit
    }
    let timeFrame
    // return values
    return {
      id: rate.id,
      label: rate.label,
      customLabel: rate.customLabel,
      labelColor: rate.color,
      riskVoltality: '',
      futureValue: parseFloat(futureValue.toFixed(2)),
      contributions: contributions * contributionFrequency * numberOfYears,
      initialDeposit: initialDeposit,
      // rate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT || rate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
      //   ? _initialDepositWithoutEquity
      //   : initialDeposit,
      isLoss: (rate.value < 0 && customROR === null) || futureValue < initialDeposit,
      numberOfYears: numberOfYears,
      futureValueWithoutContributions,
      ARQ_Flip_Gain: isLegacy ? flipGainWithPropertyWorthPercentage : flipGainAfterManagementFee,
      type: rate.type,
      ARQ_rental_income: rentalIncome,
      ARQ_rental_contribution,
      ARQ_appreciation: isLegacy
        ? appreciationWithPropertyWorthPercentage
        : appreciationAfterManagementFee,
      ARQ_property_worth,
      customROR: actual_annualized_ytd_ror_phase1And2 ? customROR : customROR && customROR * 100,
      originalARQValues: {
        proportionValue: Equity * 100,
        fixAndFlip: ARQ_Flip_Gain,
        rental:
          chartKey === 3
            ? ARQ_rental_income * ARQ_rental_contribution * numberOfYears
            : ARQ_rental_income,
        appreciation: originalARQAppreciation,
        management_fee: 100 - management_fee,
      },
      expected_ROR,
      original_ROR,
      paidOutOn: paid_out_on,
      totalROR,
      annualROR,
      timeIntervalROR,
      annualTimeIntervalROR,
      chartKey, // it defines the time interval of chart
      rentalIncome,
      cashOnCash,
      cashOnCashAnnualized,
      timeFrame: '',
      QSPAnnualizedROR,
      new_starting_amount: new_starting_amount,
      rollover: rollover,
      calculatedInvestment: initialDeposit,
      // rate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT || rate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
      //   ? _initialDepositWithoutEquity
      //   : initialDeposit,
      startDate: '',
      endDate: '',
    }
  })

  return chartData
}

// generate ytd chart data
export const generateDataForYearToDateChart = (
  myPerformanceDataArray: any,
  data: any,
  toggled: boolean
) => {
  const urlParams = new URLSearchParams(window.location.search)

  // Define the key pattern to search for
  const keyPatternforQuarterStartDate = /^quarter\d+_start_date$/

  // Create an array to store the objects
  const quarterDatesArray: any = []

  // Iterate over all keys and find quarter start dates
  urlParams.forEach((value, key) => {
    // Check if the key matches the pattern
    if (keyPatternforQuarterStartDate.test(key)) {
      // Extract the quarter number using a safer approach
      const matchResult = key.match(/\d+/)
      const quarterNumber = matchResult ? matchResult[0] : null

      // Check if the quarter number is not null
      if (quarterNumber !== null) {
        // Create an object with quarter start date and end date
        const quarterObject = {
          quarter_start_date: value,
          quarter_end_date: urlParams.get(`quarter${quarterNumber}_end_date`)
            ? urlParams.get(`quarter${quarterNumber}_end_date`)
            : '20-20-20',
          rent: urlParams.get(`actual_rental_income_q${quarterNumber}`) || 0, // Extract Rent value
          monthsDifference: calculateMonthDifference(
            value,
            urlParams.get(`quarter${quarterNumber}_end_date`)
          ), // Calculate months difference
          investment:
            urlParams.get('rollover') === 'true'
              ? urlParams.get('starting_amount')
              : urlParams.get('new_starting_amount'),
        }

        // Push the object to the array
        quarterDatesArray.push(quarterObject)
      }
    }
  })

  const numberOfQuarters = quarterDatesArray.length

  const keyPattern = /^actual_rental_income_q\d+$/

  // Create an array to store the objects
  const rentalIncomeArray: any = []

  // Iterate over all keys and find actual rental income keys
  urlParams.forEach((value, key) => {
    // Check if the key matches the pattern
    if (keyPattern.test(key)) {
      // Extract the quarter number
      const quarterNumberMatch = key.match(/\d+/)

      // Check if the quarter number is present
      if (quarterNumberMatch) {
        const quarterNumber = quarterNumberMatch[0]

        // Create an object with actual rental income key and value
        const rentalIncomeObject = {
          [`actual_rental_income`]: value ? value : 0,
        }

        // Push the object to the array
        rentalIncomeArray.push(rentalIncomeObject)
      }
    }
  })
  let rentalIncome = 0
  let futureValue = 0
  let initialDeposit = myPerformanceDataArray?.starting_amount
  let new_starting_amount = myPerformanceDataArray.new_starting_amount
  let rollover = myPerformanceDataArray.rollover
  let rentalIncomeAfterManagementFee = 0
  const AfterExitIndex = data?.findIndex((item: any) => item.label === 'ARQ After Exit')

  const Equity = data[AfterExitIndex].originalARQValues.proportionValue / 100

  let chartDataForYearToDateChart = []
  let flipGain = 0
  let appreciation = 0
  initialDeposit = initialDeposit - data[AfterExitIndex]?.ARQ_Flip_Gain
  let totalNumberOfQuarters = 0
  let totalYearlyROR = 0
  let totalQuarterlyRoR = 0
  for (let i = 0; i < quarterDatesArray.length; i++) {
    flipGain = 0
    appreciation = 0
    rentalIncome = rentalIncomeArray[i]?.actual_rental_income
      ? rentalIncomeArray[i]?.actual_rental_income * Equity
      : 0
    rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncome,
      myPerformanceDataArray?.is_management_fee_applied
        ? 100 - data[AfterExitIndex].originalARQValues.management_fee
        : 0
    )
    futureValue = 0

    if (!toggled) {
      futureValue = initialDeposit + rentalIncomeAfterManagementFee
    } else {
      flipGain = data[AfterExitIndex]?.ARQ_Flip_Gain
      appreciation = data[AfterExitIndex]?.ARQ_appreciation
      futureValue = initialDeposit + (flipGain + rentalIncomeAfterManagementFee + appreciation)
    }

    quarterDatesArray[i].rent = rentalIncomeAfterManagementFee

    let gains = calculateGains(futureValue, initialDeposit)

    let quarterAndTotalRORObj = calculateROR(
      quarterDatesArray[i].rent,
      quarterDatesArray[i].investment,
      quarterDatesArray[i].monthsDifference,
      flipGain
    )
    // getting how many quarters are there , to gain the average of the ror
    totalNumberOfQuarters = totalNumberOfQuarters + 1

    // adding all rors of the qyarters
    totalYearlyROR = totalYearlyROR + quarterAndTotalRORObj.totalROR
    totalQuarterlyRoR = totalQuarterlyRoR + quarterAndTotalRORObj.quarterROR

    // calcultaing average ror for each quarter

    let average = totalYearlyROR / totalNumberOfQuarters
    if (i + 1 === quarterDatesArray.length && quarterDatesArray.length !== 2) {
      average = totalQuarterlyRoR
    }

    let customROR =
      initialDeposit > 0 ? gains / initialDeposit / numberOfQuarters : gains / numberOfQuarters

    let futureValueWithoutContributions = futureValue - flipGain

    let totalROR = quarterAndTotalRORObj.quarterROR

    let annualROR = average

    // cash on cash
    let cashOnCash = totalROR
    // cash on cash annualized
    let cashOnCashAnnualized = annualROR
    let YTDCalculatedRentalIncome = rentalIncome + flipGain + appreciation

    let DataForYearToDateChart = {
      id: myPerformanceDataArray?.id,
      label: `Q${i + 1}`,
      customLabel: myPerformanceDataArray?.customLabel,
      labelColor: myPerformanceDataArray?.color,
      futureValue: parseFloat(futureValue.toFixed(2)),
      contributions: 0,
      initialDeposit,
      isLoss: false,
      numberOfYears: myPerformanceDataArray?.numberOfYears,
      futureValueWithoutContributions,
      ARQ_Flip_Gain: flipGain,
      type: toggled ? TYPES_OF_ROR.TYPE_AFTER_EXIT : TYPES_OF_ROR.TYPE_BEFORE_EXIT,
      ARQ_rental_income: rentalIncomeAfterManagementFee,
      ARQ_rental_contribution: 0,
      ARQ_appreciation: appreciation,
      ARQ_property_worth: myPerformanceDataArray?.ARQ_property_worth,
      customROR: customROR && customROR * 100,
      originalARQValues: {
        proportionValue: Equity * 100,
        fixAndFlip: data[AfterExitIndex]?.originalARQValues?.fixAndFlip,
        rental: rentalIncomeArray[i]?.actual_rental_income,
        appreciation: data[AfterExitIndex]?.originalARQValues?.appreciation,
        management_fee: data[AfterExitIndex]?.originalARQValues?.management_fee,
      },
      expected_ROR: myPerformanceDataArray?.expected_ROR,
      original_ROR: myPerformanceDataArray?.original_ROR,
      paidOutOn: '',
      totalROR,
      annualROR,
      timeIntervalROR: 0,
      annualTimeIntervalROR: 0,
      chartKey: -1, // it defines the time interval of chart
      rentalIncome,
      cashOnCash,
      cashOnCashAnnualized,
      timeFrame: `Time Frame: from ${formatDateHelper(
        quarterDatesArray[i].quarter_start_date
      )} to ${formatDateHelper(quarterDatesArray[i].quarter_end_date)}`,
      new_starting_amount: new_starting_amount,
      rollover: rollover,
      YTDRentalIncome: YTDCalculatedRentalIncome,
      calculatedInvestment: rollover
        ? Math.abs(data[AfterExitIndex]?.ARQ_Flip_Gain - initialDeposit)
        : Math.abs(data[AfterExitIndex]?.ARQ_Flip_Gain - new_starting_amount),
      is_YTD_Chart: true,
      startDate: quarterDatesArray[i].quarter_start_date,
      endDate: quarterDatesArray[i].quarter_end_date,
      riskVoltality: '',
    }
    chartDataForYearToDateChart.push(DataForYearToDateChart)
  }
  return chartDataForYearToDateChart
}

function calculateMonthDifference(startDate: any, endDate: any) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const monthsDifference =
    (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth()
  return monthsDifference
}

function calculateROR(rent: number, investment: number, monthsDifference: any, flipGain: number) {
  let rorObj = {
    totalROR: 0,
    quarterROR: 0,
  }
  rorObj.quarterROR = (rent / (investment - flipGain)) * 100
  rorObj.totalROR = (rorObj.quarterROR / monthsDifference) * 12

  return rorObj
}

export const formatDateHelper = (dateToFormat: string) => {
  return moment(dateToFormat).format('MM-DD-YYYY')
}

export const calculateRentalIncomeLastQuarter = (
  startingAmount: number,
  actualRentalIncomeLastQuarter: number,
  actualEquity: number,
  isManagmentFeeApplied: boolean,
  managementFee: number
) => {
  actualEquity = actualEquity / 100
  managementFee = managementFee / 100
  let rentalIncomeLastQuarter =
    actualRentalIncomeLastQuarter * actualEquity * (1 - (isManagmentFeeApplied ? managementFee : 0))
  let cashOnCash = (rentalIncomeLastQuarter / startingAmount) * 100

  return {cashOnCash, rentalIncomeLastQuarter}
}