import React from 'react'
import GreetingCard from './GreetingCard'
import InvestmentSummary from './InvestmentSummary'
import MetricCard from './MetricCard'
import TrackDetails from './TrackDetails'

interface ARQStatusProps {
  // showGreetings: boolean
  showDetails: boolean
  // showTrackDetails: boolean
  // showManipulations: boolean
}
const ARQStatus: React.FC<ARQStatusProps> = ({
  // showGreetings,
  showDetails,
  // showTrackDetails,
  // showManipulations,
}) => {
  return (
    <>
      <div className='d-flex justify-content-center align-items-center py-10'>
        <GreetingCard />
      </div>

      <div className='d-flex justify-content-center align-items-center py-10'>
        <InvestmentSummary />
      </div>

      {showDetails && (
        <>
          <div className='d-flex justify-content-center align-items-center my-10'>
            <MetricCard label='Total I put in' value='$30,000' subtext='Started 12/2022' />
            <MetricCard label='Rent I got' value='$8,282' subtext='36% YoY' />
            <MetricCard label='Appreciation' value='$7,000' subtext='x25' />
          </div>
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <TrackDetails />
          </div>
        </>
      )}
    </>
  )
}

export default ARQStatus
