import axios from 'axios'
const FETCH_QSP_PAST_INVESTMENTS = 'investments/get-investments'
const SEND_MY_PERFORMANCE_EMAIL = 'myPerformanceAssets/send-my-performance-email'
const UPLOAD_IMAGE = 'myPerformanceAssets/upload-image'

// fetching advisor form data
export function fetchQSPPastInvestments(customer_start_date: string, quarter_end_date: string) {
  return axios.get(FETCH_QSP_PAST_INVESTMENTS, {params: {customer_start_date, quarter_end_date}})
}

// send my performance email
export function sendMyPerformanceEmail(
  investmentName: string,
  customerName: string,
  quarterStartDate: string,
  quarterEndDate: string,
  timeInterval: string,
  YTDActualRentalIncome: string,
  YTDPreviousQuarterRentalIncome: string,
  quarterComparison: string,
  QTDAnnualizedROR: number,
  expectedROR: number,
  finalQuarterYear: string,
  LTDCashOnCash: number,
  ARQAfterExitValue: number,
  startingAmount: string,
  customInvestmentToShow: string | null | undefined,
  ARQReturnsText: string | null | undefined,
  myPerformanceImage: string,
  myPerformancePageURL: string
) {
  // target comparison identifier e.g. (above target, below target)
  const targetComparisonIdentifier =
    ARQAfterExitValue < expectedROR ? 'below target. ' : 'above target. '

  return axios.post(SEND_MY_PERFORMANCE_EMAIL, {
    investmentName,
    customerName,
    quarterStartDate,
    quarterEndDate,
    timeInterval,
    YTDActualRentalIncome,
    YTDPreviousQuarterRentalIncome,
    quarterComparison,
    QTDAnnualizedROR,
    expectedROR,
    finalQuarterYear,
    LTDCashOnCash,
    ARQAfterExitValue,
    startingAmount,
    customInvestmentToShow,
    ARQReturnsText,
    myPerformanceImage,
    targetComparisonIdentifier,
    myPerformancePageURL,
  })
}

// fetching advisor form data
export function uploadImageToServer(image: FormData) {
  return axios.post(UPLOAD_IMAGE, image)
}
