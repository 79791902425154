import axios from 'axios'
const FETCH_CLIENTLIST = '/sbloc-clients'
const ADD_SBLOC_CLIENT='/sbloc-clients/create'
const DELETE_SBLOC_CLIENT ='sbloc-clients/delete'
const UPDATE_SBLOC_CLIENT = 'sbloc-clients/update'
// fetching waitlist
export function fetchSblocClients() {
  return axios.get(FETCH_CLIENTLIST)
}
export function addSblocCleint(payload:any){
  return axios.post(ADD_SBLOC_CLIENT,payload)
}
export function deleteSblocClient (clientId:number){
return axios.delete(`${DELETE_SBLOC_CLIENT}/${clientId}`)
}
export function updateSblocClient(clientId:number,payload:number){
return axios.put(`${UPDATE_SBLOC_CLIENT}/${clientId}`,payload)
}