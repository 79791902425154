import {IDataRow} from './Components/IBLOCConfigs'
import {calculateIBLOCBalance} from './Components/IBLOCConfigs'
export const generateData = (dataArray: any[], defaultROI: number) => {
  let tempArray = []
  let givenRor = defaultROI / 100

  for (let i = 0; i < dataArray.length; i++) {
    let tempObj = {...dataArray[i]}

    tempObj['CashIn'] = dataArray[i].CashIn
    tempObj['APR'] = dataArray[i].APR
    tempObj['CashOut'] = dataArray[i].CashOut
    tempObj['IBLOCBalance'] =
      i === 0
        ? (1 + tempObj.APR / 100) * tempObj.CashOut
        : calculateIBLOCBalance(
            tempObj.CashIn,
            tempObj.APR,
            tempObj.CashOut,
            tempArray[i - 1].IBLOCBalance
          )
    tempObj['IBLOCBalanceforBar'] = tempObj.IBLOCBalance * -1
    tempObj['TotalCashValueCalculated'] = tempObj.DividendTotalCashValue
    tempObj['CashBalance'] =
      tempObj.TotalCashValueCalculated - tempObj.IBLOCBalance + tempObj.CashIn
    tempObj['CvGrowthYoY'] =
      i === 0
        ? 0
        : ((tempObj?.TotalCashValueCalculated - tempArray[i - 1]?.TotalCashValueCalculated) /
            tempArray[i - 1]?.TotalCashValueCalculated) *
          100
    tempObj['TotalCvGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[0].TotalCashValueCalculated) /
            tempArray[0].TotalCashValueCalculated) *
          100
    tempObj['CashBalanceGrowth'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[i - 1].CashBalance) / tempArray[i - 1].CashBalance) *
          100
    tempObj['CashBalanceGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[0].CashBalance) / tempArray[0].CashBalance) * 100
    tempObj['TotalCashPutInSoFar'] =
      i === 0
        ? dataArray[i]?.TotalCashPutInSoFar
        : calculateTotalPut(i, dataArray) + dataArray[0].TotalCashPutInSoFar

    tempObj['CashOnCashROI'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempObj.TotalCashPutInSoFar) / tempObj.TotalCashPutInSoFar) *
            100 || 0
    tempObj['TotalCashOpportunity'] =
      i === 0
        ? dataArray[0].CashIn * (1 + givenRor)
        : (tempObj.CashIn + tempArray[i - 1].TotalCashOpportunity) * (1 + givenRor)
    tempObj['TCOOROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashOpportunity - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100
    tempObj['TCVDelta'] = i === 0 ? 0 : tempObj.TotalCashValue - tempArray[i - 1].TotalCashValue
    tempObj['CBDelta'] = i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance
    tempArray.push(tempObj) // Add the modified object to the tempArray
  }
  return tempArray
}

function calculateTotalPut(currentIndex: number, dataArray: any[]) {
  let sum = 0
  for (let i = 0; i <= currentIndex; i++) {
    sum = sum + dataArray[i].CashIn
  }
  return sum
}
