import React from 'react'
import {TooltipProps} from 'recharts'
import moment from 'moment'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {toUSD} from '../../../helper/charts.helper'
import {usePastPerformanceState} from './PastPerformanceUtils'

const PastPerformanceChartToolTip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  // past chart data
  const {pastChartData, pastPerformanceAssetsOldState} = usePastPerformanceState()

  // get the current date
  const currentDate = moment()
  const todayDate = moment().format('DD MMMM YYYY')
  // get the 2 years old date
  const oldDate = currentDate.subtract(2, 'years').format('DD MMMM YYYY')

  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  const gain = data.futureValue - data.contributions - data.initialDeposit
  const absoluteGain = Math.abs(gain)

  const renderGain = (
    label: string,
    value: number,
    proportionValue: number,
    originalValue: number,
    isLegacy: boolean,
    management_fee: number
  ) => {
    const formattedValue = toUSD(value)
    const formattedProportionValue = proportionValue.toFixed(2)
    const formattedOriginalValue = toUSD(originalValue)

    return (
      <p className='paragraph-x-small mb-0 label-gain'>
        {label}: <span className='label-bold'>{formattedValue}</span>
        {!isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {formattedProportionValue}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
        {isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {formattedProportionValue}% of {management_fee}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
      </p>
    )
  }

  const pastFutureValue = pastChartData.find((value) => {
    return value.label === data.label
  })

  const pastAnnualizedROR = pastPerformanceAssetsOldState.find((value) => {
    return value.label === data.label
  })

  return (
    <div className='custom-tooltip'>
      <p className='paragraph-small label-main'>{data.label}</p>
      <span className='paragraph-small label-main'>Total ROI: {data.customLabel}</span>
      <br />
      <span className='paragraph-small label-main'>
        {`Annualized Rate of Return: ${pastAnnualizedROR?.annualizedROR}%`}
      </span>

      <br />
      <br />
      {data.isLoss && data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        <p className='paragraph-x-small mb-0 label-loss'>
          Loss: <span className='label-bold'>{toUSD(absoluteGain)}</span>
        </p>
      ) : data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        renderGain('Gain', absoluteGain, 0, 0, false, 0)
      ) : (
        <>
          {data.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
            <>
              {data.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
                renderGain(
                  'Gain (Appreciation)',
                  data.ARQ_appreciation,
                  data.originalARQValues.proportionValue,
                  data.originalARQValues.appreciation,
                  data.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                  data.originalARQValues.management_fee
                )}

              {renderGain(
                'Gain (Rental Income)',
                data.ARQ_rental_income,
                data.originalARQValues.proportionValue,
                data.originalARQValues.rental,
                data.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                data.originalARQValues.management_fee
              )}
            </>
          )}

          {data.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
            renderGain(
              'Gain (Flip Profit)',
              data.ARQ_Flip_Gain,
              data.originalARQValues.proportionValue,
              data.originalARQValues.fixAndFlip,
              data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT,
              data.originalARQValues.management_fee
            )}
        </>
      )}

      <p className='paragraph-x-small label-gain mb-0'>
        From:{' '}
        {`$${data.futureValue} on ${oldDate} to $${pastFutureValue?.futureValue} on ${todayDate} (2 years)`}{' '}
      </p>

      {/* {data.type === TYPES_OF_ROR.TYPE_SIMPLE && (
        <p className='paragraph-x-small mb-0 label-contributions'>
          Contributions: <span className='label-bold'>{toUSD(data.contributions)}</span>
        </p>
      )} */}

      <br />
      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        Starting amount: <span className='label-bold'>{toUSD(data.initialDeposit)}</span>
      </p>

      <br />
      <p className='paragraph-x-small label-main'>(Historic data from AlphaVantage)</p>
    </div>
  )
}

export default PastPerformanceChartToolTip
