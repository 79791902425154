import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {metaTags_data} from './constants/AppConstants'
import MetaTags from './Meta/MetaTags'
import {AuthInit} from './modules/auth'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY ? process.env.REACT_APP_GTM_KEY.toString() : '',
}
const TRACKING_ID = process.env.REACT_APP_GA4_KEY ? process.env.REACT_APP_GA4_KEY.toString() : ''

ReactGA.initialize(TRACKING_ID)
TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags
        title={metaTags_data.Vividli_title}
        siteName={metaTags_data.Vividli_siteName}
        description={metaTags_data.Vividli_description}
        logo={metaTags_data.logo}
        URL={window.location.href}
      />

      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}

export {App}
