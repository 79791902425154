import {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  assets,
  defaultAsset,
} from '../../../../app/user/modules/homeComponents/projections/components/ProjectionsConfig'
import {useReceiverFormContext} from '../../../../app/user/context/ReceiverContext'
import {CompoundingGrowth} from '../../../../app/user/modules/helper/calculator/_model'
import {useParams} from 'react-router-dom'
interface CustomBarModalProps {
  show: boolean
  onHide: () => void
  title: string
}
// form schema
const BarRoRSchema = Yup.object().shape({
  investement_ROR: Yup.number()
    .min(-25, 'Value cannot be less than -25%')
    .max(100, 'Value cannot be greater than 100%')
    .required('Rate of return value is required'),
})
const initialValues = {
  investement_ROR: 0,
}
const BarRorModal: React.FC<CustomBarModalProps> = (props) => {
  const {setReceiverFormDataObj, selectedBar, setSelectedBar} = useReceiverFormContext()
  const formik = useFormik({
    initialValues,
    validationSchema: BarRoRSchema,
    onSubmit: async (values, {setFieldValue}) => {
      const selectedBarIndex = assets.findIndex((item) => item.id === selectedBar.id)
      assets[selectedBarIndex].dropdownLabel = `(${values.investement_ROR}%) ${selectedBar.label}`
      assets[selectedBarIndex].customLabel = `${values.investement_ROR}%`
      assets[selectedBarIndex].value = values.investement_ROR
      setTimeout(() => {
        setReceiverFormDataObj((prevFormData) => ({
          ...prevFormData,
          barValue: values.investement_ROR,
          selected_rate_of_return_obj: assets[selectedBarIndex],
        }))
      }, 500)
      setSelectedBar(assets[selectedBarIndex])
    },
  })
  return (
    <>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
            {selectedBar.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* custom bar form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
            <div className='row m-0'>
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Rate of Return</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.investement_ROR && formik.errors.investement_ROR
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 10'
                    {...formik.getFieldProps('investement_ROR')}
                  />
                </div>
                {formik.touched.investement_ROR && formik.errors.investement_ROR && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.investement_ROR}</div>
                  </div>
                )}
              </div>
            </div>
            <Modal.Footer>
              {/* <Button className='custom-primary-button' onClick={props.onHide}>
          CLOSE
        </Button> */}
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={props.onHide}
                disabled={!formik.getFieldProps('investement_ROR').value}
              >
                <span className='indicator-progress' style={{display: 'block'}}>
                  Edit
                </span>
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default BarRorModal
