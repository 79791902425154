import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {AdminLayout} from '../../_metronic/layout/AdminLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../admin/pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const AdminRoutes = () => {
  const ProfilePage = lazy(() => import('../admin/modules/profile/ProfilePage'))
  const WaitlistPage = lazy(() => import('../admin/modules/Waitlist/WaitlistPage'))
   const ClientlistPage = lazy(() => import('../admin/modules/Clients/ClientlistPage'))
  const InterestListPage = lazy(() => import('../admin/modules/interestList/InterestListPage'))
  const UserInputsPage = lazy(() => import('../admin/modules/userInputs/UserInputsPage'))
  const SuggestedFeaturePage = lazy(
    () => import('../admin/modules/suggestedFeatures/SuggestedFeaturePage')
  )
const KanbanBoard = lazy(() => import('../admin/modules/kanbanBoard/KanbanBoard'))
const KanBanCardDetails = lazy(() => import('../admin/modules/kanbanBoard/KanBanCardDetails'))
const InvestorKanban = lazy(() => import('../admin/modules/kanbanBoard/InvestorKanban'))
return (
  <Routes>
    <Route element={<AdminLayout />}>
      {/* Redirect to Dashboard after success login/registartion */}
      <Route path='auth/*' element={<Navigate to='/dashboard' />} />

      {/* Pages */}
      <Route path='dashboard' element={<DashboardWrapper />} />

      {/* Lazy Modules */}
      <Route
        path='profile/*'
        element={
          <SuspensedView>
            <ProfilePage />
          </SuspensedView>
        }
      />
      <Route
        path='waitlist'
        element={
          <SuspensedView>
            <WaitlistPage />
          </SuspensedView>
        }
      />
      <Route
        path='interestlist'
        element={
          <SuspensedView>
            <InterestListPage />
          </SuspensedView>
        }
      />
      <Route
        path='user-inputs'
        element={
          <SuspensedView>
            <UserInputsPage />
          </SuspensedView>
        }
      />
      <Route
        path='suggested-features'
        element={
          <SuspensedView>
            <SuggestedFeaturePage />
          </SuspensedView>
        }
      />
      <Route
        path='Properties'
        element={
          <SuspensedView>
            <KanbanBoard />
          </SuspensedView>
        }
      />
      <Route
        path='Kanban-Board/details/:cardId'
        element={
          <SuspensedView>
            <KanBanCardDetails />
          </SuspensedView>
        }
      />
      <Route
        path='Investors'
        element={
          <SuspensedView>
            <InvestorKanban />
          </SuspensedView>
        }
      />
      <Route
        path='Clients'
        element={
          <SuspensedView>
            <ClientlistPage />
          </SuspensedView>
        }
      />
      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {AdminRoutes}
