import {useLocation} from 'react-router-dom'
import {IBLOC_SECTION, sectionIds} from '../../../../constants/AppConstants'
import IBLOCChart from './Components/IBLOCChart'
import {memo, useEffect, useState} from 'react'
import {SBLOCDataModel, SBLOCObjModel} from '../../helper/SBLOC/_model'
import FeedbackSection from '../feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import CopyToClipboard from 'react-copy-to-clipboard'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {cloneDeep} from 'lodash'
import {useSBLOCProjections} from '../../utils/sblocUtils'
import {useAdvisorsState} from '../../advisor/components/AdvisorsUtils'
import {
  addSharedSbloc,
  fetchSharedSBlocDataArray,
  handleSBLOCActions,
} from '../../advisor/core/_request'
import {useSBLOCContext} from '../../../context/SBLOCContext'
import {IBLOCDefaultData, IDataRow} from './Components/IBLOCConfigs'
import {generateData} from './IBLOCCalculator'
import _ from 'lodash'
const IBLOCSection: React.FC = () => {
  let slug = ''
  const {handleCopy, copied} = useAdvisorsState()
  const {
    parentUserId,
    setIBLOCArray,
    setParentUserId,
    IBLOCArray,
    compareScenarioTitle,
    compareScenarioChartData,
    setCompareScenarioTitle,
    setCompareScenarioChartData,
    compareScenarioTableData,
    setCompareScenarioTableData,
    selectedChartIndex,
    setSelectedChartIndex,
    defaultOpportunityCostROI,
  } = useSBLOCContext()
  const {sectionIdToShowInWebViewer, showInWebViewer} = useReceiverFormContext()
  const {generateDataforEachBar} = useSBLOCProjections()
  const location = useLocation()
  const [data, setData] = useState(cloneDeep(IBLOCDefaultData))
  const [tableData, setTableData] = useState(cloneDeep(IBLOCDefaultData))
  const [inputData, setInputData] = useState<SBLOCObjModel>()
  const [isUndo, setIsUndo] = useState(false)
  // const [slug, setSlug] = useState('')
  const [firstRowOfTableChanged, setFirstRowOfTableChanged] = useState(false)
  const [valuesOfTableFirstRow, setvaluesOfTableFirstRow] = useState({})
  const [isShareLink, setIsShareLink] = useState('')
  const pathsToCheck = ['iblocPage']
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  const {isFirstRender} = useAdvisorsState()

  const pathsToCheckForSlug = ['slug']
  function getQueryParam(param: any) {
    return new URLSearchParams(window.location.search).get(param)
  }

  const userId = getQueryParam('userId')
  const numericUserId = userId ? Number(userId) : null

  if (numericUserId) {
    setParentUserId(numericUserId)
    console.log('numericUserId::::', numericUserId)
  } else {
    console.error('Invalid or missing User ID')
    // Handle error case here, such as setting a default value or showing an error message
  }
  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      // Ensure that event.data.data is an array and has at least one element
      // console.log(event.data)
      if (
        Array.isArray(event.data) &&
        event.data.length > 0 &&
        event.data[0].hasOwnProperty('Year') &&
        !_.isEqual(IBLOCArray, event.data)
      ) {
        console.log(event.data)
        setIBLOCArray(event.data)
        setTableData(event.data)
        setCompareScenarioChartData([
          {
            id: 0,
            value: event.data,
            label: compareScenarioTitle ? compareScenarioTitle : 'Comparison ' + 0,
          },
        ])
        setData(event.data)
      }
    }

    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [IBLOCArray])
  const [isFaded, setIsFaded] = useState(false)
  const isIBLOCinUrl = pathsToCheck.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  const isSlugInUrl = pathsToCheckForSlug.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  const isIblocPage = location.pathname.slice(1) === 'iblocPage'
  function parseCurrentUrlParameters() {
    const currentUrl = window.location.href // Get the current URL
    const urlParts = currentUrl.split('?') // Split the URL into base and query string parts

    if (urlParts.length > 1) {
      const slug = urlParts[1].split('=')[1]
      fetchSharedSBlocDataArray(slug).then((data) => {
        if (data.data.length) {
          setData(cloneDeep(data.data))
          setTableData(cloneDeep(data.data)) // Now data should be the actual value, not a Promise
        }
      })
    }
  }
  const handleBarsArrayUpdate = (barsArray: any) => {
    if (isFirstRender.current && isSlugInUrl && isIblocPage) {
      parseCurrentUrlParameters()
      isFirstRender.current = false
    } else {
      setTableData(cloneDeep(barsArray)) // Deep copy for initialData
      setData(cloneDeep(barsArray)) // Deep copy for data
    }
  }
  const handleFirstRowValues = (newValue: any, keyId: any) => {
    setFirstRowOfTableChanged(true)
    setvaluesOfTableFirstRow((prevState) => ({
      [keyId]: newValue,
    }))
    setTimeout(() => {
      setFirstRowOfTableChanged(false)
    }, 2000)
  }
  // unique slug generation
  function generateSlug() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const length = 6 // adjust the length of the slug as needed
    let slug = ''
    for (let i = 0; i < length; i++) {
      slug += characters[Math.floor(Math.random() * characters.length)]
    }
    return slug
  }
  // save sharedsbloc array to the database
  const saveArrayToDbAndShareRecord = async () => {
    slug = generateSlug()
    addSharedSbloc(JSON.stringify(data), slug)
    setIsShareLink(`${process.env.REACT_APP_URL}/iblocPage?slug=${slug}`)
  }
  const editYear = (values: any, dataArray: any) => {
    dataArray.forEach((item: any) => {
      if (
        (item.year.toString() === values.year.toString() && !values.subsequentYears) ||
        (values.subsequentYears && item.year >= values.year)
      ) {
        // Update the item properties based on values provided.
        item.starting_amount = values.startingAmount
        item.borrowed_amount = values.borrowedAmount
        item.reInvested_asset = values.reInvestedAmount
        item.APR = values.APR
        item.ROR = values.ROR
        item.reInvested_ror = values.reInvestedRor
        item.cash_flow_that_year = values.cash_flow_that_year
        item.cash_flow_frequency = values.cash_flow_frequency
      }
    })
    dataArray = generateDataforEachBar(dataArray)
    setTableData(cloneDeep(dataArray))
    setData(cloneDeep(dataArray))
  }

  const updateScenarioValue = (updatedValue: any) => {
    // Deep clone the updated value
    const clonedUpdatedValue = cloneDeep(updatedValue)

    // Update table data
    const updatedCompareScenarioTableData = compareScenarioTableData.map((item: any) =>
      item.id === selectedChartIndex
        ? {...item, value: clonedUpdatedValue} // Update the value field with cloned data
        : item
    )
    setCompareScenarioTableData(updatedCompareScenarioTableData)

    // Update chart data
    const updatedCompareScenarioChartData = compareScenarioChartData.map((item: any) =>
      item.id === selectedChartIndex
        ? {...item, value: clonedUpdatedValue} // Update the value field with cloned data
        : item
    )
    setCompareScenarioChartData(updatedCompareScenarioChartData)

    // Update chart data
    const existingChartData = localStorage.getItem(comparisonChartDataLocalStorageKey)
    if (existingChartData) {
      const parsedExistingChartData = JSON.parse(existingChartData)

      const updatedStorageChartData = parsedExistingChartData.map((item: any) =>
        item.id === selectedChartIndex
          ? {...item, value: clonedUpdatedValue} // Update the value field with cloned data
          : item
      )

      localStorage.setItem(
        comparisonChartDataLocalStorageKey,
        JSON.stringify(updatedStorageChartData)
      )
    }

    // Update table data
    const existingTableData = localStorage.getItem(comparisonTableDataLocalStorageKey)
    if (existingTableData) {
      const parsedExistingTableData = JSON.parse(existingTableData)

      const updatedStorageTableData = parsedExistingTableData.map((item: any) =>
        item.id === selectedChartIndex
          ? {...item, value: clonedUpdatedValue} // Update the value field with cloned data
          : item
      )

      localStorage.setItem(
        comparisonTableDataLocalStorageKey,
        JSON.stringify(updatedStorageTableData)
      )
    }
  }

  const handleUpdateChartData = (updatedData: any) => {
    setTableData(updatedData)
  }

  const editYearForIBLOCTable = (dataArray: IDataRow[]) => {
    if (dataArray) {
      setIBLOCArray(dataArray)
      let newDataArray = generateData(cloneDeep(dataArray), defaultOpportunityCostROI)
      setData(newDataArray)
      window.parent.postMessage({type: 'IBLOCArray', data: newDataArray}, '*')
      setTableData(newDataArray)
      handleSBLOCActions(parentUserId)
      updateScenarioValue(newDataArray)
    } else {
      setCompareScenarioChartData([
        {
          id: 0,
          value: IBLOCDefaultData,
          label: 'Default',
        },
      ])
      // compareScenarioTableData[0].value= IBLOCDefaultData
      setTableData(cloneDeep(IBLOCDefaultData))
    }
  }

  const handleUndo = () => {
    setData(cloneDeep(IBLOCDefaultData))
    setTableData(cloneDeep(IBLOCDefaultData))
  }

  // const handleFormikValues = (formikValues: any) => {
  //   setInputData(formikValues)
  //   setIsShareLink('')
  // }
  const handleKeyDown = (event: any) => {
    // Check if Ctrl+Z was pressed
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault() // Prevent the default action of Ctrl+Z
      handleUndo() // Call the undo handler
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [data])
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
  }, [])
  const handleClick = () => {
    saveArrayToDbAndShareRecord()
    setIsFaded(true)
    setTimeout(() => {
      setIsFaded(false)
    }, 5000) // 5000ms = 5 seconds
  }

  // xxxxxxxxxxxxxxx comparison work xxxxxxxxxxxxxxx
  const comparisonChartDataLocalStorageKey = 'IBLOC_COMPARISON_CHART_DATA'
  const comparisonTableDataLocalStorageKey = 'IBLOC_COMPARISON_TABLE_DATA'

  useEffect(() => {
    // table data
    // Check for persona data in local storage
    const existingTableData = localStorage.getItem(comparisonTableDataLocalStorageKey)
    if (existingTableData) {
      // If found, parse and set it to state
      setCompareScenarioTableData([...compareScenarioTableData, JSON.parse(existingTableData)])
    }

    //  chart data
    // Check for persona data in local storage
    const existingChartData = localStorage.getItem(comparisonChartDataLocalStorageKey)
    if (existingChartData) {
      // If found, parse and set it to state
      setCompareScenarioChartData([...compareScenarioChartData, ...JSON.parse(existingChartData)])
    }
  }, [])

  const handleStoreComparisonData = (
    uniqueTableId: number,
    uniqueChartId: number,
    existingTableData: any,
    existingChartData: any
  ) => {
    // table data
    const tableDataToStore = {
      id: uniqueTableId,
      value: tableData,
      label: compareScenarioTitle ? compareScenarioTitle : 'Comparison ' + uniqueTableId,
    }
    existingTableData.push(tableDataToStore)
    setCompareScenarioTableData([...compareScenarioTableData, tableDataToStore])
    localStorage.setItem(comparisonTableDataLocalStorageKey, JSON.stringify(existingTableData))

    // chart data
    const chartDataToStore = {
      id: uniqueChartId,
      value: data,
      label: compareScenarioTitle ? compareScenarioTitle : 'Comparison ' + uniqueChartId,
    }
    existingChartData.push(chartDataToStore)
    setCompareScenarioChartData([...compareScenarioChartData, chartDataToStore])
    setSelectedChartIndex(uniqueChartId)
    localStorage.setItem(comparisonChartDataLocalStorageKey, JSON.stringify(existingChartData))
  }

  const handleAddComparisonChart = () => {
    // table data
    // setCompareScenarioTableData([...compareScenarioTableData, tableData])
    const existingTableDataString = localStorage.getItem(comparisonTableDataLocalStorageKey)
    const existingTableData = existingTableDataString ? JSON.parse(existingTableDataString) : []
    // const uniqueTableId = existingTableData.length + 1
    const uniqueTableId = compareScenarioTableData[compareScenarioTableData.length - 1]?.id + 1

    // chart data
    // setCompareScenarioChartData([...compareScenarioChartData, data])
    const existingChartDataString = localStorage.getItem(comparisonChartDataLocalStorageKey)
    const existingChartData = existingChartDataString ? JSON.parse(existingChartDataString) : []
    // const uniqueChartId = existingChartData.length + 1
    const uniqueChartId = compareScenarioChartData[compareScenarioChartData.length - 1]?.id + 1

    handleStoreComparisonData(uniqueTableId, uniqueChartId, existingTableData, existingChartData)
  }

  useEffect(() => {}, [compareScenarioChartData])
  return (
    <>
      {isIBLOCinUrl || isIblocPage ? (
        <div
          id={sectionIds[6]}
          className={`px-7 px-lg-20 ${
            sectionIdToShowInWebViewer !== sectionIds[6] && showInWebViewer ? 'd-none' : ''
          }`}
        >
          <h1
            className={`mt-10 ${
              isWayStoneStyledInUrl ? '' : 'mb-8'
            } text-center position-relative z-index-1 ${
              isWayStoneStyledInUrl
                ? 'heading-for-waystone font-family-montserrat '
                : 'font-family-helvetica'
            }`}
          >
            {IBLOC_SECTION.HEADING}
          </h1>
          {!isWayStoneStyledInUrl && (
            <>
              <h4 className='my-2 text-center pl-6 font-family-helvetica'>
                {IBLOC_SECTION.SUB_HEADING}
              </h4>
              <p className='mx-lg-18 mx-md-0 mt-10 mb-20 text-center font-family-helvetica'>
                {`${IBLOC_SECTION.DESCRIPTION} `}
                <a href={IBLOC_SECTION.Link} target='_blank'>
                  Investopedia.
                </a>
              </p>
            </>
          )}
        </div>
      ) : null}
      {data.length && (
        <IBLOCChart
          data={compareScenarioChartData[selectedChartIndex]?.value}
          editYear={editYear}
          undo={handleUndo}
          editYearForTable={editYearForIBLOCTable}
          // tableData={compareScenarioTableData[selectedChartIndex]?.value}
          tableData={tableData}
          handleUpdateChartData={handleUpdateChartData}
          handleChangeFirstRowInputs={handleFirstRowValues}
          isWayStoneStyled={isWayStoneStyledInUrl}
          handleAddComparisonChart={handleAddComparisonChart}
        />
      )}
    </>
  )
}

export default memo(IBLOCSection)
