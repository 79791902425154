import {useState, useEffect, useRef} from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'

interface DropdownProps {
  keyId: string
  optionsArray: any[]
  action: (label: string, keyToUpdate: string, value: string) => void
}

const CustomDropDown: React.FC<DropdownProps> = ({keyId, optionsArray, action}) => {
  const [showOptions, setShowOptions] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const screenWidth = useScreenWidth()
  const dropdownRef = useRef<HTMLDivElement>(null) // Create a reference for the dropdown element

  const handlePencilClick = (optionLabel: string) => {
    setSelectedValue(optionLabel)
  }

  const handleSelectChange = (label: string, keyToUpdate: string, value: string) => {
    setSelectedValue(label)
    action(label, keyToUpdate, value)
    setShowOptions(false)
  }

  const toggleOptions = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    setShowOptions(!showOptions)
  }

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchend', handleClickOutside)
    }
  }, [dropdownRef])

  const title = `${keyId === 'reInvested_ror' ? 'ReInvested ROR' : keyId} (${
    selectedValue || 'Given'
  })`

  return (
    <div
      ref={dropdownRef} // Attach the ref to the dropdown container
      className={`form-select dropdown my-3 ${screenWidth < 768 ? 'ms-n2' : ''}`}
      style={{
        cursor: 'pointer',
        position: 'static', // Enable z-index
        color: 'black',
        border: 'solid black 1px',
      }}
      onMouseDown={toggleOptions}
      onTouchEnd={toggleOptions}
      title={title}
    >
      <div
        className='selected-value'
        style={{
          width: '100%',
          backgroundColor: 'transparent',
          color: 'black',
          borderRadius: '0.25rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '12px',
        }}
      >
        {title}
      </div>
      {showOptions && (
        <div
          className='custom-options'
          style={{
            position: 'absolute',
            width: '250%',
            backgroundColor: '#fff',
            zIndex: '2000',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            marginTop: '5px',
            marginRight: '20px',
            overflow: 'auto',
            maxHeight: '200px',
          }}
        >
          {optionsArray.map((option: any) => (
            <div
              key={option.label}
              onMouseDown={(e) => {
                e.stopPropagation()
                handleSelectChange(
                  option.label,
                  keyId,
                  option.toParse ? JSON.parse(option.value) : option.value
                )
              }}
              onTouchEnd={(e) => {
                e.stopPropagation()
                handleSelectChange(
                  option.label,
                  keyId,
                  option.toParse ? JSON.parse(option.value) : option.value
                )
              }}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.375rem 0.75rem',
                cursor: 'pointer',
                backgroundColor: selectedValue === option.label ? '#e9ecef' : '#fff',
                color: 'black',
              }}
            >
              {option.label}
              {option.editable && (
                <span
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    handlePencilClick(option.value)
                  }}
                  onTouchEnd={(e) => {
                    e.stopPropagation()
                    handlePencilClick(option.value)
                  }}
                  style={{cursor: 'pointer', marginLeft: '5px', color: 'black'}}
                >
                  ✏️
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropDown
