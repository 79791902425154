import {useEffect, memo, useState} from 'react'
import {SBLOC_INPUT_BOX_TOOLTIPS} from '../../../../../constants/AppConstants'
import CustomOverlayTooltip from '../../projections/components/CustomOverlayTooltip'
import {useSBLOCProjections} from '../../../utils/sblocUtils'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../../components/projectionSlider/ProjectionSlider'
import {SBLOCDataModel, SBLOCObjModel, SBLOCInputsProps} from '../../../helper/SBLOC/_model'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {contributionAndDividendsFrequency} from '../../projections/components/ProjectionsConfig'
let barsDataArrayToShowOnChart: any[] = []

const SBLOCInputs: React.FC<SBLOCInputsProps> = (props) => {
  let dataObj: SBLOCObjModel
  // Destructuring custom(useProjectionsState) state
  const {isFirstRender, initialValues, handleKeyPress, formik, generateData} = useSBLOCProjections()
  const screenWidth = useScreenWidth()
  const [maxLimit, setMaxLimit] = useState<number>(10000000)
  useEffect(() => {
    if (isFirstRender.current) {
      props.onChangeValues(formik.values)
      barsDataArrayToShowOnChart = generateData(initialValues)
      isFirstRender.current = false
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      return
    }
    if (!props.isFirstRowOfTableChanged) {
      let maxLimit = functionToSetMaxLimit(formik.values.starting_amount)
      setMaxLimit(maxLimit)
      dataObj = formik.values
      barsDataArrayToShowOnChart = []
      barsDataArrayToShowOnChart = generateData(dataObj)
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      props.onChangeValues(formik.values)
    }
  }, [formik.values])
  useEffect(() => {
    if (props.isUndo) {
      formik.setValues(initialValues)
      props.resetUndo()
    }
  }, [props.isUndo])
  useEffect(() => {
    if (props.sharedSbLocInputs && Object.keys(props.sharedSbLocInputs).length > 1) {
      formik.setFieldValue('starting_amount', props.sharedSbLocInputs.starting_amount)
      formik.setFieldValue('asset_rate_of_return', props.sharedSbLocInputs.ROR)
      formik.setFieldValue('years_to_grow', props.sharedSbLocInputs.years_to_grow)
      formik.setFieldValue('borrowed_amount', props.sharedSbLocInputs.borrowed_amount)
      formik.setFieldValue('annualized_interest_rate', props.sharedSbLocInputs.APR)
      formik.setFieldValue('re_invested_amount', props.sharedSbLocInputs.reInvested_asset)
      formik.setFieldValue('re_invest_rate_of_return', props.sharedSbLocInputs.reInvested_ror)
      formik.setFieldValue('cash_flow_that_year', props.sharedSbLocInputs.cash_flow_that_year)
      formik.setFieldValue('cash_flow_frequency', props.sharedSbLocInputs.cash_flow_frequency)
    }
  }, [props.sharedSbLocInputs])
  useEffect(() => {
    if (props.isFirstRowOfTableChanged && props.firstTableRowValue) {
      Object.entries(props.firstTableRowValue).forEach(([key, value]) => {
        const numValue = parseFloat(value as string)
        if (!isNaN(numValue)) {
          formik.setFieldValue(key, numValue)
        } else {
          console.warn(`Invalid number conversion for field '${key}' with value '${value}'`)
        }
      })
    }
  }, [props.isFirstRowOfTableChanged]) // Added props.firstTableRowValue to dependencies

  function functionToSetMaxLimit(input: number): number {
    if (input >= 0 && input < 100000) {
      return 100000 // Max limit set to 100K when input is below 100K
    } else if (input >= 100000 && input < 300000) {
      return 300000 // Max limit set to 300K when input is between 100K and 300K
    } else if (input >= 300000 && input < 1000000) {
      return 1000000 // Max limit set to 1M when input is between 300K and 1M
    } else if (input >= 1000000 && input < 10000000) {
      return 10000000 // Max limit set to 10M when input is between 1M and 10M
    } else if (input >= 10000000 && input < 30000000) {
      return 30000000 // Max limit set to 30M when input is between 10M and 30M
    } else if (input >= 30000000 && input < 100000000) {
      return 100000000 // Max limit set to 100M when input is between 30M and 100M
    } else {
      return 100000000 // Default return value for inputs outside the defined ranges
    }
  }

  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('cash_flow_frequency', value)
  }
  return (
    <>
      <div>
        <div>
          {/* Projections form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='projection-form'>
            <div
              className={`row m-0 ${
                screenWidth > 746 ? 'justify-content-center' : 'justify-content-start mr-10'
              }`}
            >
              {/* Starting Amount and Asset Rate Of Return fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.STARTING_AMOUNT}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.starting_amount && formik.errors.starting_amount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='starting_amount'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.starting_amount}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('starting_amount', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.starting_amount}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.starting_amount}
                  maxValue={maxLimit}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('starting_amount', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              <div className='col-6 col-md-1 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>ROR</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.Asset_rate_of_return}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.asset_rate_of_return && formik.errors.asset_rate_of_return
                      ? 'error-border'
                      : ''
                  } ${props.IsWayStoneSlider ? 'waystone-slider-color' : ''}`}
                  style={{width: screenWidth > 746 ? '6rem' : '100%'}}
                >
                  <CustomInputField
                    id='asset_rate_of_return'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    maxLength={8}
                    prefix='%'
                    allowDecimals={true}
                    decimalsLimit={2}
                    defaultValue={formik.values.asset_rate_of_return}
                    onValueChange={(value: any) => {
                      formik.setFieldValue('asset_rate_of_return', value || '')
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.asset_rate_of_return}
                  />
                  {/* <input
                    id='asset_rate_of_return'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('asset_rate_of_return')}
                    onKeyDown={handleKeyPress}
                  /> */}
                </div>
                <ProjectionSlider
                  value={formik.values.asset_rate_of_return}
                  minValue={1}
                  maxValue={25}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('asset_rate_of_return', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>

              {/* Years to Grow */}
              <div className='col-6 col-md-1 fv-row mb-3 mb-lg-0 ps-0 ps-md-3'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Years to Grow</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.years_to_grow && formik.errors.years_to_grow
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='years_to_grow'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={0}
                    maxLength={8}
                    defaultValue={formik.values.years_to_grow}
                    prefix=''
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('years_to_grow', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.years_to_grow}
                  />
                  {/* <input
                    id='years_to_grow'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('years_to_grow')}
                    onKeyDown={handleKeyPress}
                  /> */}
                </div>
                <ProjectionSlider
                  value={formik.values.years_to_grow}
                  minValue={1}
                  maxValue={100}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('years_to_grow', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              <div className='col-6 d-block d-md-none'></div>
            
              {/* Borrowed Amount and Annualized Interest Rate fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-3 mb-lg-0 ps-0 ml-2'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Borrowed Amount</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.BORROWED_AMOUNT}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.borrowed_amount && formik.errors.borrowed_amount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='borrowed_amount'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.borrowed_amount}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('borrowed_amount', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.borrowed_amount}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.borrowed_amount}
                  minValue={100}
                  maxValue={maxLimit / 2}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('borrowed_amount', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              <div className='col-6 col-md-1 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  {/* changed Annualized ROR to APR  */}
                  <p className='ps-1 m-0 fields-label'>APR</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.ANNUALIZED_INTEREST_RATE}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.annualized_interest_rate &&
                    formik.errors.annualized_interest_rate
                      ? 'error-border'
                      : ''
                  }`}
                  style={{width: screenWidth > 746 ? '6rem' : '100%'}}
                >
                  <CustomInputField
                    id='annualized_interest_rate'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    prefix='%'
                    defaultValue={formik.values.annualized_interest_rate}
                    onValueChange={(value: any) => {
                      formik.setFieldValue('annualized_interest_rate', value || '')
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.annualized_interest_rate}
                  />
                  {/* <input
                    id='annualized_interest_rate'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('annualized_interest_rate')}
                    onKeyDown={handleKeyPress}
                  /> */}
                </div>
                <ProjectionSlider
                  value={formik.values.annualized_interest_rate}
                  minValue={1}
                  maxValue={25}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('annualized_interest_rate', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>

              {/* Re-Invested Amount and Re-Invest Rate Of Return fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0 ml-2'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re-invested Amount</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.REINVESTED_AMOUNT}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.re_invested_amount && formik.errors.re_invested_amount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol d-none'>$</p>
                  <CustomInputField
                    id='re_invested_amount'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.re_invested_amount}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('re_invested_amount', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.re_invested_amount}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.re_invested_amount}
                  maxValue={maxLimit / 2}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('re_invested_amount', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              <div className='col-6 col-md-1 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re-Invest ROR</p>
                  <CustomOverlayTooltip
                    content={SBLOC_INPUT_BOX_TOOLTIPS.REINVESTING_RATE_OF_RETURN}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.re_invest_rate_of_return &&
                    formik.errors.re_invest_rate_of_return
                      ? 'error-border'
                      : ''
                  }`}
                  style={{width: screenWidth > 746 ? '6rem' : '100%'}}
                >
                  <CustomInputField
                    prefix='%'
                    id='re_invest_rate_of_return'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.re_invest_rate_of_return}
                    onValueChange={(value: any) => {
                      formik.setFieldValue('re_invest_rate_of_return', value || '')
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.re_invest_rate_of_return}
                  />
                  {/* <input
                    id='re_invest_rate_of_return'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('re_invest_rate_of_return')}
                    onKeyDown={handleKeyPress}
                  /> */}
                </div>
                <ProjectionSlider
                  value={formik.values.re_invest_rate_of_return}
                  minValue={1}
                  maxValue={25}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('re_invest_rate_of_return', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              <div className={`d-flex p-0 px-md-3 ${screenWidth > 746 ? 'justify-content-end' : ''}`}>
                {/* cash flow field */}
                <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 p-0 ps-md-2'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Cash Flow</p>
                    <CustomOverlayTooltip
                      content={SBLOC_INPUT_BOX_TOOLTIPS.CASH_FLOW}
                      className='tooltip-logo'
                    />
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='cash_flow_that_year'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 10.00'
                      decimalsLimit={2}
                      maxLength={6}
                      defaultValue={formik.values.cash_flow_that_year}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('cash_flow_that_year', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.cash_flow_that_year}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.cash_flow_that_year}
                    maxValue={10000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('cash_flow_that_year', parseInt(value!))
                    }
                    sliderColor={props?.sliderColor}
                  />
                </div>
                {/* cash flow frequency dropdown */}
                <div className='col-5 col-md-2 fv-row projections-select mb-0 mb-lg-0 pe-0 pe-md-2 mx-5'>
                  <p className='ps-1 m-0 fields-label time-frame'>Time Frame</p>
                  <select
                    id='compounding-frequency'
                    className={`form-select dropdown ${screenWidth < 768 ? 'ms-n2' : ''}`}
                    {...formik.getFieldProps('cash_flow_frequency')}
                    onChange={handleCompoundingFreqChange}
                  >
                    {contributionAndDividendsFrequency.map((option) => {
                      return (
                        <option className='px-2' key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>

            {/* Error messages */}
            <div className='text-center mt-2'>
              {formik.touched.starting_amount && formik.errors.starting_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.starting_amount}</div>
                </div>
              ) : null}
              {formik.touched.asset_rate_of_return && formik.errors.asset_rate_of_return ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.asset_rate_of_return}</div>
                </div>
              ) : null}
              {formik.touched.years_to_grow && formik.errors.years_to_grow ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.years_to_grow}</div>
                </div>
              ) : null}
              {formik.touched.borrowed_amount && formik.errors.borrowed_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.borrowed_amount}</div>
                </div>
              ) : null}
              {formik.touched.re_invested_amount && formik.errors.re_invested_amount ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.re_invested_amount}</div>
                </div>
              ) : null}
              {formik.touched.annualized_interest_rate && formik.errors.annualized_interest_rate ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.annualized_interest_rate}</div>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(SBLOCInputs)
