import axios from 'axios'
import {useReducer, useCallback} from 'react'

interface FeedbackState {
  likeSelected: boolean
  dislikeSelected: boolean
  likeHovered: boolean
  dislikeHovered: boolean
  feedbackInputText: string
  showCommentsText: boolean
  showThanksText: boolean
  showFeedbackField: boolean
}

type FeedbackAction =
  | {type: 'TOGGLE_LIKE'; sectionName: string}
  | {type: 'TOGGLE_DISLIKE'; sectionName: string}
  | {type: 'TOGGLE_LIKE_HOVER'}
  | {type: 'TOGGLE_DISLIKE_HOVER'}
  | {type: 'SET_INPUT_TEXT'; text: string}
  | {type: 'TOGGLE_FEEDBACK_FIELD'}
  | {type: 'TOGGLE_THANKS_TEXT'}

const feedbackReducer = (state: FeedbackState, action: FeedbackAction): FeedbackState => {
  switch (action.type) {
    case 'TOGGLE_LIKE':
      if (!state.likeSelected) {
        return {
          ...state,
          likeSelected: !state.likeSelected,
          dislikeSelected: false,
          showThanksText: false,
          showCommentsText: true,
        }
      } else {
        return {
          ...state,
          likeSelected: false,
          dislikeSelected: false,
          showCommentsText: false,
          showFeedbackField: false,
          showThanksText: false,
          feedbackInputText: '',
        }
      }
    case 'TOGGLE_DISLIKE':
      if (!state.dislikeSelected) {
        return {
          ...state,
          dislikeSelected: !state.dislikeSelected,
          likeSelected: false,
          showThanksText: false,
          showCommentsText: true,
        }
      } else {
        return {
          ...state,
          dislikeSelected: false,
          likeSelected: false,
          showCommentsText: false,
          showFeedbackField: false,
          showThanksText: false,
          feedbackInputText: '',
        }
      }

    case 'TOGGLE_LIKE_HOVER':
      return {...state, likeHovered: !state.likeHovered}
    case 'TOGGLE_DISLIKE_HOVER':
      return {...state, dislikeHovered: !state.dislikeHovered}
    case 'SET_INPUT_TEXT':
      return {...state, feedbackInputText: action.text}
    case 'TOGGLE_FEEDBACK_FIELD':
      return {...state, showFeedbackField: !state.showFeedbackField, showCommentsText: false}
    case 'TOGGLE_THANKS_TEXT':
      return {...state, showThanksText: !state.showThanksText, showFeedbackField: false}
    default:
      return state
  }
}

export const useFeedbackDataState = () => {
  const initialState: FeedbackState = {
    likeSelected: false,
    dislikeSelected: false,
    likeHovered: false,
    dislikeHovered: false,
    feedbackInputText: '',
    showCommentsText: false,
    showThanksText: false,
    showFeedbackField: false,
  }

  const [state, dispatch] = useReducer(feedbackReducer, initialState)

  const handleLikeClick = useCallback(
    (sectionName: string) => {
      if (!state.likeSelected) sendToSheets(sectionName, 'thumbs up', 'message')
      dispatch({type: 'TOGGLE_LIKE', sectionName})
    },
    [state.likeSelected]
  )

  const handleDislikeClick = useCallback(
    (sectionName: string) => {
      if (!state.dislikeSelected) sendToSheets(sectionName, 'thumbs down', 'message')
      dispatch({type: 'TOGGLE_DISLIKE', sectionName})
    },
    [state.dislikeSelected]
  )

  const handleLikeMouseOver = () => dispatch({type: 'TOGGLE_LIKE_HOVER'})
  const handleLikeMouseOut = () => dispatch({type: 'TOGGLE_LIKE_HOVER'})
  const handleDislikeMouseOver = () => dispatch({type: 'TOGGLE_DISLIKE_HOVER'})
  const handleDislikeMouseOut = () => dispatch({type: 'TOGGLE_DISLIKE_HOVER'})
  const handleInputChange = (text: string) => dispatch({type: 'SET_INPUT_TEXT', text})
  const handleShowFeedbackField = () => dispatch({type: 'TOGGLE_FEEDBACK_FIELD'})

  const handleShowThanksText = useCallback(
    (sectionName: string) => {
      sendToSheets(
        sectionName,
        state.likeSelected ? 'thumbs up' : 'thumbs down',
        state.feedbackInputText
      )
      console.log('state.feedbackInputText: ', state.feedbackInputText)
      dispatch({type: 'TOGGLE_THANKS_TEXT'})
    },
    [state, state.feedbackInputText]
  )

  // sending data to spreadsheet
  const sendToSheets = async (
    sectionName: string,
    feedbackStatus: string,
    feedbackMessage: string
  ) => {
    const encodedSectionName = encodeURIComponent(sectionName)
    const encodedFeedbackStatus = encodeURIComponent(feedbackStatus)
    const encodedFeedbackMessage = encodeURIComponent(feedbackMessage)
    const scriptUrl = `https://script.google.com/macros/s/AKfycbzw5Q3OboyJ1NArFrQNTGR84cS2hOHBrfOtRqJvk27ntaGKV-lsw6kjnnnHLJuBa4sG/exec?sectionName=${encodedSectionName}&feedbackStatus=${encodedFeedbackStatus}&feedbackMessage=${encodedFeedbackMessage}`
    try {
      const response = await axios.get(scriptUrl)
      if (response.status === 200) {
        console.log('response added in sheet.')
        return
      } else {
        console.error('Error sending message:', response.statusText)
      }
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  return {
    // States
    ...state,
    // Functions
    handleLikeClick,
    handleDislikeClick,
    handleLikeMouseOver,
    handleLikeMouseOut,
    handleDislikeMouseOver,
    handleDislikeMouseOut,
    handleInputChange,
    handleShowFeedbackField,
    handleShowThanksText,
  }
}
