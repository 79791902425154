import {PICTOGRAPH_SECTION} from '../../../../../constants/AppConstants'
import {PICTOGRAPH_SVG_IMAGES} from './SVGImages'

// assets array
export const initialAssetsArray = [
  {id: 1, price: 1, name: 'Candybars', img: PICTOGRAPH_SVG_IMAGES.CANDY_BAR, viewBox: '0 0 24 24'},
  {id: 2, price: 3, name: 'Samosas', img: PICTOGRAPH_SVG_IMAGES.SAMOSA, viewBox: '0 0 100 125'},
  {
    id: 2,
    price: 5,
    name: 'Potted Plant',
    img: PICTOGRAPH_SVG_IMAGES.PLANT,
    viewBox: '0 0 24 24',
  },
  {id: 3, price: 10, name: 'Books', img: PICTOGRAPH_SVG_IMAGES.BOOK, viewBox: '0 0 24 24'},
  {id: 4, price: 20, name: 'T-shirts', img: PICTOGRAPH_SVG_IMAGES.TSHIRT, viewBox: '0 0 24 24'},
  {
    id: 5,
    price: 50,
    name: 'Tank of Gas (Petrol) or Restaurant Dinner for Two',
    img: PICTOGRAPH_SVG_IMAGES.GAS_TANK,
    viewBox: '0 0 24 24',
  },
  {
    id: 6,
    price: 100,
    name: 'Groceries for Average Size Family or Tickets to the Zoo',
    img: PICTOGRAPH_SVG_IMAGES.ZOO,
    viewBox: '0 0 512 512',
  },
  {
    id: 7,
    price: 200,
    name: 'Sound Bars',
    img: PICTOGRAPH_SVG_IMAGES.SOUND_BAR,
    viewBox: '0 0 24 24',
  },
  {
    id: 8,
    price: 500,
    name: 'Budget Laptops',
    img: PICTOGRAPH_SVG_IMAGES.LAPTOP,
    viewBox: '0 0 24 24',
  },
  {
    id: 9,
    price: 1000,
    name: 'iPhones',
    img: PICTOGRAPH_SVG_IMAGES.IPHONE,
    viewBox: '0 0 24 24',
  },
  {
    id: 10,
    price: 2500,
    name: 'Budget Vacation for 2',
    img: PICTOGRAPH_SVG_IMAGES.PLANE,
    viewBox: '0 0 24 24',
  },
  {
    id: 11,
    price: 5000,
    name: 'Trip to Disney Land for a Family of 4',
    img: PICTOGRAPH_SVG_IMAGES.DISNEY,
    viewBox: '0 0  15 15',
  },
  {
    id: 12,
    price: 20000,
    name: 'Small Cars',
    img: PICTOGRAPH_SVG_IMAGES.SMALL_CAR,
    viewBox: '0 0 24 24',
  },
  {id: 13, price: 40000, name: 'SUVs', img: PICTOGRAPH_SVG_IMAGES.SUV, viewBox: '0 0 50 50'},
  {
    id: 14,
    price: 100000,
    name: 'Downpayment for a House',
    img: PICTOGRAPH_SVG_IMAGES.HOUSE,
    viewBox: '0 0 24 24',
  },
]

// custom assets
export const customAssets = [
  {
    id: 1,
    image: PICTOGRAPH_SECTION.IMAGES.TRIANGLE,
    svg: PICTOGRAPH_SVG_IMAGES.TRIANGLE,
    viewBox: '0 0 24 24',
  },
  {
    id: 2,
    image: PICTOGRAPH_SECTION.IMAGES.CIRCLE,
    svg: PICTOGRAPH_SVG_IMAGES.CIRCLE,
    viewBox: '0 0 24 24',
  },
  {
    id: 3,
    image: PICTOGRAPH_SECTION.IMAGES.PEN,
    svg: PICTOGRAPH_SVG_IMAGES.PEN,
    viewBox: '0 0 24 24',
  },
]
