import React from 'react'

const TrackDetails = () => {
  return (
    <div className='summary-card my-5'>
      <p className='amount'>You’re on track.</p>
      <div className='values'>
        <span>Next rent: est. $250-$500,</span>
      </div>
      <p>ETA June 2024</p>
    </div>
  )
}

export default TrackDetails
