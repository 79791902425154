import {useState, useEffect, useRef} from 'react'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {IFutureAssets} from './models'
import TagManager from 'react-gtm-module'
import {initialAssetsArray} from './Pictograph.config'

export function usePictographState() {
  const pictographValue = useRef<string | null>(null)

  // destructuring useReceiverFormContext hook
  const {pictoFutureValue} = useReceiverFormContext()

  // sorting assetsArray based on price
  const sortedInitialAssetsArray = [...initialAssetsArray].sort((a, b) => a.price - b.price)
  // assets array
  const [assetsArray, setAssetsArray] = useState(sortedInitialAssetsArray)
  // future asset state
  const [futureAsset, setfutureAsset] = useState<IFutureAssets>({
    assetName: null,
    assetImg: undefined,
    viewBox: '',
  })
  // assets qty
  const [assetQty, setAssetQty] = useState<number>(0)
  // selected range
  const [selectedRange, setSelectedRange] = useState(0)
  // last index qty
  const [lastIndexQty, setlastIndexQty] = useState(0)

  const findLastIndexQty = () => {
    let qty = (+assetQty.toFixed(2) % 1) * 100
    setlastIndexQty(+qty.toFixed(2))
  }

  // setting selected asset on range change
  useEffect(() => {
    // Find the asset that corresponds to the selected range
    const selectedAsset = assetsArray[selectedRange]

    if (selectedAsset && pictoFutureValue) {
      const quantity = pictoFutureValue / selectedAsset.price
      setfutureAsset({
        assetName: `Getting ${quantity.toFixed(2)} ${
          quantity <= 1 && selectedAsset.name.endsWith('s')
            ? selectedAsset.name.slice(0, -1)
            : selectedAsset.name
        }`,
        assetImg: selectedAsset.img,
        viewBox: selectedAsset.viewBox,
      })
      setAssetQty(pictoFutureValue / selectedAsset.price)
      // findLastIndexQty()
      let qty = (+quantity.toFixed(2) % 1) * 100
      setlastIndexQty(+qty.toFixed(2))
    }
  }, [selectedRange])

  // sending analytics
  useEffect(() => {
    if (futureAsset.assetImg !== undefined) {
      sendAnalytics(futureAsset.assetName)
    }
  }, [futureAsset])

  // Function to update the selected range value
  const handleRangeChange = (value: number) => {
    setSelectedRange(value)
  }

  // algo for finding least Qty asset
  const findBestAsset = () => {
    let bestMatch = null
    let minFractionalUnits = Number.MAX_SAFE_INTEGER
    let bestFractionalQuantity = 0
    let bestMatchIndex = -1

    if (pictoFutureValue) {
      for (const option of assetsArray) {
        const quantity = pictoFutureValue / option.price
        if (quantity >= 1 && quantity < minFractionalUnits) {
          minFractionalUnits = quantity
          bestMatch = option
          bestFractionalQuantity = quantity
          bestMatchIndex++
        }
      }
    } else {
      setfutureAsset({
        assetName: 'Please enter a valid number for futureValue.',
        assetImg: undefined,
        viewBox: '',
      })
      return
    }

    if (bestMatch) {
      const formattedValue =
        bestFractionalQuantity === 1
          ? `${bestFractionalQuantity} ${bestMatch.name}`
          : `${bestFractionalQuantity.toFixed(2)} ${bestMatch.name}`

      setSelectedRange(bestMatchIndex)
      setfutureAsset({
        assetName: `Getting ${formattedValue}`,
        assetImg: bestMatch.img,
        viewBox: bestMatch.viewBox,
      })
      setAssetQty(bestFractionalQuantity)
      findLastIndexQty()
    } else {
      setfutureAsset({
        assetName: 'No matching option found for the given amount.',
        assetImg: undefined,
        viewBox: '',
      })
    }
  }

  // handle custom assets
  const handleCustomAssets = (assetName: string, assetPrice: number, assetImage: string) => {
    const newCustomAsset = {
      id: assetsArray.length + 1,
      name: assetName,
      price: assetPrice,
      img: assetImage,
      viewBox: '0 0 24 24',
    }

    const updatedAssetsArray = [...assetsArray, newCustomAsset]
    // sorting assets array
    updatedAssetsArray.sort((a, b) => a.price - b.price)
    setAssetsArray(updatedAssetsArray)

    // setting selected range index of custom asset
    const customIndex = updatedAssetsArray.findIndex((asset) => asset.id === newCustomAsset.id)
    setSelectedRange(customIndex)
    setfutureAsset({
      assetName: `Getting ${newCustomAsset.name}`,
      assetImg: newCustomAsset.img,
      viewBox: newCustomAsset.viewBox,
    })
  }

  // google analytics
  const sendAnalytics = (asset: string | null) => {
    if (pictographValue?.current !== asset) {
      const dataLayer: {[key: string]: unknown} = {
        event: 'pictorgraph',
      }
      dataLayer['pictograph_value'] = asset
      TagManager.dataLayer({
        dataLayer: dataLayer,
      })
    }
    pictographValue.current = asset
  }

  return {
    assetQty,
    assetsArray,
    futureAsset,
    findBestAsset,
    selectedRange,
    lastIndexQty,
    handleCustomAssets,
    handleRangeChange,
  }
}
