import {memo} from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'
// import SBLOCTooltip from './SBLOCTooltip'
import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, LabelList, Text, YAxis} from 'recharts'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import {projectionsColors} from '../../PastPerformance/components/PastPerformanceConfig'
import {DataArrayForCreditCardDeptChartData} from './_model'
import CreditCardDeptToolTip from './CreditCardDeptToolTip'

const CreditCardDeptChart: React.FC<DataArrayForCreditCardDeptChartData> = (props) => {
  const {data} = props
  // screen width custom hook
  const screenWidth = useScreenWidth()

  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 15, right: 30, left: -30}
      : {top: 80, bottom: 70, right: 40, left: -30}

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 20
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width} = props
    const futureValue =
      data[index].futureValue > 0 ? data[index]?.futureValue.toFixed(2) : (0.0).toFixed(2)
    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))
    return (
      <Text
        x={x + width / 2}
        y={screenWidth > 767 ? y - 23 : y - 15}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={data.length <= 12 ? 0 : 270}
      >
        {gain}
      </Text>
    )
  }

  // custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, index} = props
    let date = data[index].date

    return (
      <>
        <Text
          x={x}
          y={y + labelPadding}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-tick-label'
        >
          {date}
        </Text>
        <Text
          x={x}
          y={y + 50 + labelPadding}
          width={90}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-label'
          fill={projectionsColors.borrow}
        >
          {`${data[index].totalDept.toFixed(1)}%`}
        </Text>
      </>
    )
  }

  return (
    <>
      <div className='pt-4 px-md-10 px-lg-20'>
        <ResponsiveContainer width='100%' height={_chartHeight} id='chart-container'>
          <BarChart margin={chartPadding} data={data} style={{backgroundColor: 'white'}}>
            <XAxis
              dataKey='date'
              axisLine={false}
              tickLine={false}
              interval={data.length <= 10 ? 0 : Math.ceil(data.length / 12)}
              width={100}
              minTickGap={5}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              yAxisId='left'
              allowDataOverflow={true}
              tick={false}
              axisLine={false}
              tickLine={false}
              domain={[
                (dataMin: number) => 0 - Math.abs(dataMin),
                (dataMax: number) => dataMax * 1.2, // Increase dataMax by 10%
              ]}
            />

            {/* tooltip */}
            <Tooltip
              contentStyle={{backgroundColor: 'white'}}
              content={<CreditCardDeptToolTip />}
            />
            {/* bars */}

            <Bar
              barSize={70}
              dataKey='cardBalance'
              stackId='assets'
              fill={projectionsColors.initialDeposit}
              yAxisId='left'
            ></Bar>
            <Bar
              barSize={70}
              dataKey='futureValueWithoutCardBalance'
              stackId='assets'
              fill={projectionsColors.borrow}
              yAxisId='left'
            >
              {data.length <= 36 ? (
                <LabelList content={<CustomizedBarTopLabel />} position='top' />
              ) : null}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default memo(CreditCardDeptChart)
