import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'
// import RecordRTC from 'recordrtc'
import RecordRTC, {RecordRTCPromisesHandler, invokeSaveAsDialog} from 'recordrtc'

export const roundToLowest = (value: number) => {
  return Math.floor(value)
}
export const toAbbreviatedNumber = (value: number): string => {
  const absValue = Math.abs(value);
  let result = '';

  if (absValue < 1e3) result = absValue.toString();
  else if (absValue >= 1e3 && absValue < 1e6) result = (absValue / 1e3).toFixed(1) + 'K';
  else if (absValue >= 1e6 && absValue < 1e9) result = (absValue / 1e6).toFixed(1) + 'M';
  else if (absValue >= 1e9 && absValue < 1e12) result = (absValue / 1e9).toFixed(1) + 'B';
  else if (absValue >= 1e12) result = (absValue / 1e12).toFixed(1) + 'T';

  // Reapply the negative sign if the original value was negative
  if (value < 0 && result) {
    result = '-' + result;
  }

  return result || ''; // Fallback to an empty string if an unexpected case occurs
}


export const toUSD = (value: number) => {
  const formattedValue = Number(roundToLowest(value)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formattedValue
}

export const toUSDWithoutRoundOff = (value: number) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formattedValue.format(value)
}

export const downloadChart = (chartId: string, imageName: string) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById(chartId)

  if (chartContainer) {
    toPng(chartContainer, {cacheBust: false})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = imageName
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}

export const startRecording = async (
  chartId: string,
  functionToReAnimate: () => void,
  functionToStopAnimation: () => void,
  showRisk: boolean
) => {
  try {
    const mediaStream = await navigator.mediaDevices.getDisplayMedia({video: true})
    const video = document.createElement('video')
    video.srcObject = mediaStream
    video.play()

    const chartContainer = document.getElementById(chartId)
    if (!chartContainer) {
      throw new Error('Chart container not found')
    }
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      throw new Error('Unable to obtain 2D context from canvas')
    }

    const width = chartContainer.clientWidth
    const height = chartContainer.clientHeight
    const rect = chartContainer.getBoundingClientRect()

    // Adjusted position and size for mobile screens
    const x = rect.left // Adjust as needed
    const y = rect.top + 130 // Use your logic for calculating top position

    canvas.width = width
    canvas.height = height

    const updateCanvas = () => {
      if (context) {
        context.drawImage(video, x, y, width, height, 0, 0, width, height)
      }
      requestAnimationFrame(updateCanvas)
    }

    updateCanvas()

    const stream = canvas.captureStream()
    const recorder = new MediaRecorder(stream, {
      mimeType: 'video/webm;codecs=vp9',
      videoBitsPerSecond: 80000000, // Adjust the bitrate as needed
    })

    const chunks: any = []

    recorder.ondataavailable = (event) => chunks.push(event.data)

    recorder.onstop = () => {
      const blob = new Blob(chunks, {type: 'video/webm'})
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = 'recorded-video.webm'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      mediaStream.getTracks().forEach((track) => track.stop())
    }

    // Delay the start of recording by 1 second
    setTimeout(() => {
      recorder.start()
      // Record for the next 5 seconds
      setTimeout(
        () => {
          recorder.stop()
          functionToStopAnimation()
        },
        showRisk ? 10000 : 5000
      )
      functionToReAnimate()
    }, 1000)
  } catch (error) {
    console.error('Error in recording:', error)
  }
}

export const startRecordingForMobile = async (
  chartId: string,
  functionToReAnimate: () => void,
  functionToStopAnimation: () => void
) => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({video: true, audio: true})

    const mediaRecorder = new MediaRecorder(stream, {mimeType: 'video/webm'})

    const recordedChunks: Blob[] = []

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data)
      }
    }

    mediaRecorder.onstop = async () => {
      const blob = new Blob(recordedChunks, {type: 'video/webm'})
      invokeSaveAsDialog(blob, 'screen-recording.webm')
    }

    mediaRecorder.start()
    functionToReAnimate()

    await new Promise((resolve) => setTimeout(resolve, 3000))

    mediaRecorder.stop()
  } catch (error) {
    console.error('Error recording screen:', error)
  }
}

// ***TSBG chart helper functions***
// formatting future value
export const FVFormatter = (value: any) => {
  const suffixes = ['', 'K', 'M', 'B', 'T']

  // Find the appropriate suffix based on the length of the value
  const suffixIndex = Math.floor(String(value).length / 3)

  // Calculate the value with the appropriate suffix, rounded up to the nearest integer
  const formattedValue = Math.ceil(value / Math.pow(1000, suffixIndex)) + suffixes[suffixIndex]

  return formattedValue
}
