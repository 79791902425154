import {Button} from 'react-bootstrap'

interface IFeedbackInputFieldProps {
  onChange: (value: string) => void
  onSubmit: () => void
  placeHolder?: string
}

const FeedbackInputField: React.FC<IFeedbackInputFieldProps> = ({
  onChange,
  onSubmit,
  placeHolder = 'thoughts?',
}) => {
  return (
    <>
      <div className='input-container d-flex justify-content-center align-items-center'>
        <p className='m-0 currency-symbol d-none'>$</p>
        <input
          id='number-of-years'
          type='text'
          className='form-control form-control-lg form-control-solid'
          placeholder={placeHolder}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault() // Prevent the default action to avoid form submission if inside form
              onSubmit()
            }
          }}
        />
        <Button className='input-field-button me-1' onClick={onSubmit}>
          ✓
        </Button>
      </div>
    </>
  )
}

export default FeedbackInputField
