/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../../helpers'
import pubSub from '../../../../../app/user/modules/profile/EventEmitter/PubSub'
import {getUserByEmail} from '../../../../../app/user/modules/profile/components/settings/_request'
const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [localUser, setLocalUser] = useState<any>()
  // ;(async () => {
  //  if (currentUser) {
  //    const {data} = await getUserByEmail(currentUser?.email || '')
  //    setLocalUser(data)
  //  }
  // })()
  useEffect(() => {
    const unsubscribe = pubSub.subscribe('profileUpdated', (data: any) => {
      console.log('Profile updated!', data.user)
      setLocalUser(data.user)
    })

    return () => unsubscribe() // Cleanup subscription on unmount
  }, [])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              src={`${process.env.REACT_APP_ADVISOR_LOGO_PATH || 'default/path/to/logo/'}${
                currentUser?.profile_image
              }`}
              alt='Metornic'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.name}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      {currentUser?.user_role === 2 ? (
        <div className='menu-item px-5'>
          <Link to={'/user-inputs'} className='menu-link px-5'>
            Saved Searches
          </Link>
        </div>
      ) : null}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/home' onClick={logout} className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
