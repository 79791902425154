import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {INPUT_BOX_TOOLTIPS} from '../../../../../constants/AppConstants'

interface TooltipProps {
  content: string
  className?: string
}

const CustomOverlayTooltip: React.FC<TooltipProps> = ({content, className}) => {
  const imgClassName = `ms-sm-2 pb-5 ${className}`
  return (
    <OverlayTrigger
      placement='bottom'
      overlay={<Tooltip style={{whiteSpace: 'pre-line'}}>{content}</Tooltip>}
    >
      <span>
        <img className={imgClassName} src={INPUT_BOX_TOOLTIPS.IMAGES.TOOLTIP_ICON} alt='tooltip' />
      </span>
    </OverlayTrigger>
  )
}
export default CustomOverlayTooltip
