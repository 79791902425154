import {useLocation} from 'react-router-dom'
import {memo, useState} from 'react'
import FeedbackSection from '../feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {sectionIds} from '../../../../constants/AppConstants'
import CreditCardInputs from './components/CreditCardInputs'
import {CreditCardDeptChartData} from './components/_model'
import CreditCardDeptChart from './components/CreditCardDeptChart'
import {CCDSection} from '../../../../constants/AppConstants'

const CreditCardDeptSection: React.FC = () => {
  const {sectionIdToShowInWebViewer, showInWebViewer} = useReceiverFormContext()
  const [creditCardDeptChartData, setCreditCardDeptChartData] = useState<CreditCardDeptChartData[]>(
    []
  )
  // extracting useparams
  const location = useLocation()

  // Check if "creditcard" is in the URL
  const pathsToCheck = ['creditcard=true']
  const isCCinUrl = pathsToCheck.some((path) => location.search.includes(path))
  const handleCreditCardFormSubmit = (creditCardDeptChartData: CreditCardDeptChartData[]) => {
    setCreditCardDeptChartData(creditCardDeptChartData)
  }

  return (
    <>
      {isCCinUrl ? (
        <div
          id={sectionIds[15]}
          className={`px-7 px-lg-20 ${
            sectionIdToShowInWebViewer !== sectionIds[15] && showInWebViewer ? 'd-none' : ''
          }`}
        >
          <h1 className='mt-10 mb-8 mb-md-14 text-center position-relative z-index-1'>
            {CCDSection.HEADING}
          </h1>
          <h4 className='my-2 text-center pl-6 '>{CCDSection.SUB_HEADING}</h4>
          <p className='mx-lg-18 mx-md-0 mt-10 mb-5 text-center'>
            {`${CCDSection.DESCRIPTION} `}
            <a href={CCDSection.Link} target='_blank'>
              NerdWallet.
            </a>
          </p>

          {/* inputs */}
          <div className=' pt-20 pt-8 pt-7 px-md-10 px-lg-20'>
            <CreditCardInputs onCreditCardDeptFomSubmit={handleCreditCardFormSubmit} />
          </div>

          {/* chart */}
          <CreditCardDeptChart data={creditCardDeptChartData} />

          {/* post description */}
          <p className='mx-lg-18 mx-md-0 mt-10 mb-20 text-center italic-text'>{`${CCDSection.POST_CHART_DESCRIPTION} `}</p>

          {/* feedback section */}
          <div className={showInWebViewer ? 'd-none' : ''}>
            <section className='mt-7 pe-20 pb-20 pb-8 pb-7'>
              <FeedbackSection sectionName='Credit Card Dept section' />
            </section>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default memo(CreditCardDeptSection)
