import React from 'react'
import {TooltipProps} from 'recharts'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {toUSD, toUSDWithoutRoundOff} from '../../../helper/charts.helper'

const ComparePerformanceTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload

  const gain = data.initialDeposit * (data.customROR / 100)
  const absoluteGain = Math.abs(gain)

  const renderGain = (
    label: string,
    value: number,

    colorName: string
  ) => {
    const formattedValue = toUSDWithoutRoundOff(value)
    return (
      <p className={`qsp-label-font paragraph-x-small mb-0 label-bold ${colorName}`}>
        {label}: <span className='label-bold'>{formattedValue}</span>
      </p>
    )
  }

  return (
    <div className='custom-tooltip'>
      {/* tooltip label */}
      <p className='paragraph-small label-main'>{data.label}</p>

      {/* general tooltip data */}
      {data.isLoss && data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        <p className='qsp-label-font paragraph-x-small mb-0 label-loss'>
          Loss:{' '}
          <span className='label-bold qsp-label-font'>{toUSDWithoutRoundOff(absoluteGain)}</span>
        </p>
      ) : data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        renderGain('Gain', absoluteGain, 'label-gain')
      ) : null}

      {/* contributions */}
      <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
        Contributions: <span className='label-bold'>{toUSD(data?.contributions)}</span>
      </p>

      {/* starting amount or your investment */}
      <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
        Starting amount: <span className='label-bold'>{toUSD(data?.initialDeposit)}</span>
      </p>

      {/*future value */}
      <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
        Total: <span className='label-bold'>{toUSDWithoutRoundOff(data?.futureValue)}</span>
      </p>

      {/* past date values */}

      {data?.oldDate !== 0 && data?.startValue !== 0 ? (
        <>
          <br />
          <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
            Old Date : <span className='label-bold'>{data?.startDate}</span>
          </p>
          <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
            Old Value :{' '}
            <span className='label-bold'>
              {data?.startValue ? Number(data.startValue).toFixed(2) : 'N/A'}
            </span>
          </p>
          <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
            End Date : <span className='label-bold'>{data?.oldDate}</span>
          </p>
          <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
            End Value :{' '}
            <span className='label-bold'>
              {data?.startValue ? Number(data.oldValue).toFixed(2) : 'N/A'}
            </span>
          </p>
        </>
      ) : null}
    </div>
  )
}

export default ComparePerformanceTooltip
