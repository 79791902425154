import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'

type Scenario = {
  id: number
  value: any
  label: string
}
interface IBLOCDropDownProps {
  data: Scenario[]
  onValueChange: (value: any[]) => void // Function to call on value change
  localStorageKey: string
  setScenarioData: (data: any) => void
  isScenarioModal: () => void
  isEditableModal: () => void
  setScenarioIdToEdit: (id: number) => void
}
const IBLOCDropDown: React.FC<IBLOCDropDownProps> = ({
  data,
  onValueChange,
  localStorageKey,
  setScenarioData,
  isScenarioModal,
  isEditableModal,
  setScenarioIdToEdit,
}) => {
  const location = useLocation()
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<string>('Select Persona')
  const toggleDropdown = () => setIsOpen(!isOpen)
  const handleSelect = (label: string, value: any[]) => {
    setSelectedLabel(label)
    onValueChange(value)
    setIsOpen(false)
  }

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, idToEdit: number) => {
    isScenarioModal()
    isEditableModal()
    setScenarioIdToEdit(idToEdit)
    setIsOpen(false)
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, idToDelete: number) => {
    const newData = data.filter((item: any) => item.id !== idToDelete)
    localStorage.setItem(localStorageKey, JSON.stringify(newData))
    setScenarioData(localStorage.getItem(localStorageKey))
  }

  return (
    <>
      <div className='persona-custom-dropdown'>
        <div
          onClick={toggleDropdown}
          className={`persona-dropdown-button form-select dropdown ${
            isWayStoneStyledInUrl
              ? 'scenario-button-color-secondary'
              : 'scenario-button-color-primary'
          }`}
        >
          {selectedLabel}
        </div>
        {isOpen && (
          <ul className='persona-dropdown-list'>
            {data.map((scenario, _index) => (
              <li key={_index} className='persona-dropdown-item'>
                <span
                  onClick={() => handleSelect(scenario?.label, scenario?.value)}
                  className='d-flex w-auto'
                >
                  {scenario.label}
                </span>
                {_index > 2 && (
                  <button onClick={(e) => handleEdit(e, scenario?.id)} className='bg-transparent'>
                    ✏️
                  </button>
                )}
                {_index > 2 && (
                  <button onClick={(e) => handleDelete(e, scenario?.id)} className='bg-transparent'>
                    ❌
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
export default IBLOCDropDown
