export const ChartExperiment = {
  name: 'AB_Projection_Charts',
  variantA: 'version_a_join_waitlist_submitted',
  variantB: 'version_b_join_waitlist_submitted',
  probabilityA: 100,
  probabilityB: 0,
  // expiration timer constants
  expirationKey: 'expiration_version',
  expirationDuration: 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
  chartVersionKey: 'PUSHTELL-AB_Projection_Charts',
}
