import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'

// comapre performance context props
type ComparePerformanceContextProps = {
  chartData: any
  setChartData: Dispatch<SetStateAction<any[]>>
  isCustomAssetUpdated: boolean
  setIsCustomAssetUpdated: Dispatch<SetStateAction<boolean>>
  isFormSubmitted: boolean
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>
}

// default values
const initComparePerformanceContextPropsState = {
  chartData: [],
  setChartData: () => {},
  isCustomAssetUpdated: false,
  setIsCustomAssetUpdated: () => {},
  isFormSubmitted: false,
  setIsFormSubmitted: () => {},
}

const ComparePerformanceContext = createContext<ComparePerformanceContextProps>(
  initComparePerformanceContextPropsState
)

const ComparePerformanceProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [chartData, setChartData] = useState<any>([])
  const [isCustomAssetUpdated, setIsCustomAssetUpdated] = useState<boolean>(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      chartData,
      setChartData,
      isCustomAssetUpdated,
      setIsCustomAssetUpdated,
      isFormSubmitted,
      setIsFormSubmitted,
    }),
    [chartData, isCustomAssetUpdated, isFormSubmitted]
  )
  return (
    <ComparePerformanceContext.Provider value={contextValue}>
      {children}
    </ComparePerformanceContext.Provider>
  )
})

const useComparePerformanceContext = () => {
  const context = useContext(ComparePerformanceContext)
  if (!context) {
    throw new Error('useComparePerformanceContext must be used within a ComparePerformanceProvider')
  }
  return context
}

export {ComparePerformanceProvider, useComparePerformanceContext}
