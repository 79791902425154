import {memo, useEffect, useRef, useState} from 'react'
import SBLOCSection from '../../modules/homeComponents/SBLOC/SBLOCSection'
import {useSBLOCContext} from '../../context/SBLOCContext'
import {useLocation} from 'react-router-dom'
import {useSBLOCProjections} from '../../modules/utils/sblocUtils'
import {fetchSharedSBlocDataArray} from '../../modules/advisor/core/_request'
import {fetchSblocClients} from '../../../admin/modules/Clients/core/_request'
const SBLOCPage: React.FC = (props) => {
  const isFirstRender = useRef(true)
  const location = useLocation()
  const {SBLOCObj, setSBLOCObj, setParentUserId} = useSBLOCContext()
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]

  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )

  function getQueryParam(param: any) {
    return new URLSearchParams(window.location.search).get(param)
  }

  const userId = getQueryParam('userId')
  const numericUserId = userId ? Number(userId) : null

  if (numericUserId) {
    setParentUserId(numericUserId)
  } else {
    console.error('Invalid or missing User ID')
    // Handle error case here, such as setting a default value or showing an error message
  }

  const [clientConfigurations, setClientConfigurations] = useState<any>([])

  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      console.log('iframe use effect')
      // Verify the message's origin for security
      // Adjust the origin according to your application's needs
      if (event.origin === process.env.REACT_APP_CHLOEE_URL) {
        console.log('incoming data from parent', event.data)
        setSBLOCObj((prevState: any) => ({
          ...prevState,
          annualized_interest_rate: event.data?.annualized_interest_rate,
          asset_rate_of_return: event.data?.asset_rate_of_return,
          borrowed_amount: event.data?.borrowed_amount,
          re_invest_rate_of_return: event.data?.re_invest_rate_of_return,
          re_invested_amount: event.data?.re_invested_amount,
          starting_amount: event.data?.starting_amount,
          years_to_grow: event.data?.years_to_grow,
        }))
      }
    }

    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false
  //     return
  //   }

  //   console.log('Sending data to parent')

  //   const timer = setTimeout(() => {
  //     sendDataToParent()
  //   }, 3000) // 3000 milliseconds = 3 seconds

  //   // Cleanup function to clear the timeout if the component unmounts
  //   return () => clearTimeout(timer)
  // }, [SBLOCObj])

  // // Example function to send data back to parent
  // const sendDataToParent = () => {
  //   window.parent.postMessage(SBLOCObj, '*') // Replace '*' with your parent's origin for security
  // }

  const defaultClientConfiguration: any = {
    default: {
      clientName: 'default',
      heading: 'SBLOC Visualization',
      subHeading: 'Heard how some people "bank on themselves" indefinitely? See how.',
      description:
        'One way is with a Securities-Backed Line of Credit (SBLOC). SBLOCs let you borrow against your investments. You pay a daily interest rate on the outstanding amount borrowed. You can often then re-invest this money to other (dissimilar) assets, generating even more returns. Over time, so long as your rate of returns out-pace your borrowing costs, you can end out ahead. But, with high interest rates nowadays, borrowing can eat into your earnings – and assets. Read more about SBLOCs at',
      showSlider: false,
      font: 'helvetica',
      backGroundColor: '#ffffff',
      headingAndSliderColor: '#367045',
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchSblocClients()
        setClientConfigurations(res.data)
      } catch (error) {
        console.error('Error fetching client configurations:', error)
      }
    }

    fetchData()
  }, [])

  const clientIdentifier = location.search.split('style=')[1] || 'default'
  const clientConfig =
    clientConfigurations.find((config: any) => config.clientName === clientIdentifier) ||
    defaultClientConfiguration.default

  return (
    <section
      className={`container-custom container ${
        isWayStoneStyledInUrl ? 'container-waystone hide-scroll-bar' : ''
      } content`}
      // style={{backgroundColor: `${clientConfig?.backGroundColor} !important`}}
      style={{backgroundColor: clientConfig?.backGroundColor}}
    >
      <SBLOCSection />
    </section>
  )
}

export default memo(SBLOCPage)
